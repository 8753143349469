import React from 'react';

import { BranchData, DifficultyData } from './TagData'
import { Inline, Block } from "../elements/MyMath/MyMath"
import WikiInfo from '../elements/WikiInfo/WikiInfo'
import { PdfLink, Comment } from "../elements/SpecialText/SpecialText"
import PdfData from "./PdfData"

import "./data.css"
import GuardRelease from '../elements/GuardRelease/GuardRelease';

const WikiData = {
    DEFAULT: function (depth = 0) {
        return {
            id: 0,
            type: "wikiEntry",
            title: "Wiki Homepage",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        This Wiki contains a list of all results and definitions cited in any solution on calimath.org. In addition to the title and statement, a wiki entry may contain proofs or notes. Furthermore, each Wiki page contains a list of all the solutions on our website that reference this Wiki page's content.<p />
                        Please consider solving all linked problems related to a particular topic to understand its applications better.
                    </>
            },
            proofs: [],
            notes: [],
            source: undefined,
            examples: [],
            branch: undefined,
        }
    },
    AM_GM_INEQUALITY: function (depth = 0) {
        return {
            id: 1,
            type: "wikiEntry",
            title: "AM-GM inequality",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For all <Inline tex="n \in \mathbb N" /> and <Inline tex="x_1, x_2, \dots, x_n \in \mathbb R_{\geq 0}" />, we have
                        <Block tex="\frac{x_1 + x_2 + \dots + x_n}{n} \geq \sqrt[n]{x_1x_2 \dots x_n}." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            For <Inline tex="n = 2" />, we obtain: For all <Inline tex="a, b \in \mathbb R_{\geq 0}," />
                            <Block tex="\frac{a+b}{2} \geq \sqrt{ab}." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            Equality holds if and only if <Block tex="x_1 = x_2 = \dots = x_n." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            The <WikiInfo data={WikiData.WEIGHTED_AM_GM} depth={depth}>weighted AM-GM inequality</WikiInfo> is a generalization of this inequality.
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Inequality_of_arithmetic_and_geometric_means",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.A,
        }
    },
    MULTIPLICATIVE_ORDER: function (depth = 0) {
        return {
            id: 3,
            type: "wikiEntry",
            title: "Multiplicative order",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p \in \mathbb N" /> be a prime and let <Inline tex="a \in \mathbb Z, p \nmid a" />. The multiplicative order of <Inline tex="a" /> modulo <Inline tex="p" />, which we write as <Inline tex="\mathrm{ord}_p(a)" />, is the smallest positive integer, such that
                        <Block tex="a^{\mathrm{ord}_p(a)} \equiv 1 \mod p" />
                        holds.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            We have, <Block tex="a^r \equiv 1 \mod p \iff \mathrm{ord}_p(a) \mid r." />
                            To prove that, let's write <Block tex="r = s \cdot \mathrm{ord}_p(a) + t," /> where <Inline tex="s, t \in \mathbb N, t < \mathrm{ord}_p(a)" />. We get
                            <Block tex="a^r \equiv \left(a^{\mathrm{ord}_p(a)}\right)^s \cdot a^t \equiv a^t \mod p." />
                            By definition of the order and because <Inline tex="t < \mathrm{ord}_p(a)" />, we get
                            <Block tex="1 \equiv a^r \equiv a^t \mod p \iff t = 0 \iff \mathrm{ord}_p(a) \mid r." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            That together with <WikiInfo data={WikiData.FERMATS_LITTLE_THEOREM} depth={depth}>Fermat's little theorem</WikiInfo> implies
                            <Block tex="\mathrm{ord}_p(a) \mid p-1." />
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Multiplicative_order",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    FERMATS_LITTLE_THEOREM: function (depth = 0) {
        return {
            id: 4,
            type: "wikiEntry",
            title: "Fermat's little theorem",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be a prime and <Inline tex="a" /> be an integer not divisible by <Inline tex="p" />. Then, the <WikiInfo depth={depth} data={WikiData.MODULAR_CONGRUENCE}>congruence</WikiInfo>
                        <Block tex="a^{p-1} \equiv 1 \mod p" />
                        holds.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            This implies for all integers <Inline tex="a" />, that
                            <Block tex="a^p \equiv a \mod p." />
                        </>
                },
                {
                    subtitle: undefined,
                    content: <>
                        More generally, for <Inline tex="n \in \mathbb Z^+" /> and <Inline tex="a \in \mathbb Z" /> coprime to <Inline tex="n" />, we have
                        <Block tex="a^{\varphi(n)} \equiv 1 \mod n." />
                        Here, <Inline tex="\varphi" /> is <WikiInfo depth={depth} data={WikiData.EULERS_TOTIENT_FUNCTION}>Euler's totient function</WikiInfo>.
                    </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Fermat%27s_little_theorem",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    LTE_LEMMA: function (depth = 0) {
        return {
            id: 5,
            type: "wikiEntry",
            title: "Lifting-the-exponent lemma",
            subtitle: "LTE lemma",
            altTitles: ["LTE lemma"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be a prime and <Inline tex="n" /> be a positive integer. Let <Inline tex="x" /> and <Inline tex="y" /> be integers such that
                        <Block tex="p \nmid x \quad \textnormal p \nmid y." />
                        Let <Inline tex="\nu_p(n)" /> be the <WikiInfo depth={depth} data={WikiData.P_ADIC_VALUATION}>p-adic valuation</WikiInfo> of <Inline tex="n" />.<p />
                        When <Inline tex="p" /> is odd:
                        <Block tex="p \mid x - y \implies \nu_p(x^n - y^n) = \nu_p(x - y) + \nu_p(n)," />
                        and
                        <Block tex="2 \nmid n, p \mid x + y \implies \nu_p(x^n + y^n) = \nu_p(x + y) + \nu_p(n)," />
                        where the second statement follows from the first with <Inline tex="y \mapsto -y" />.<br /><br />
                        When <Inline tex="p = 2" />:
                        <Block tex="2 \mid n, 2 \mid x - y \implies \nu_2(x^n - y^n) = \nu_2(x - y) + \nu_2(x + y) + \nu_2(n) - 1," />
                        and
                        <Block tex="2 \nmid n, 2 \mid x - y \implies \nu_2(x^n - y^n) = \nu_2(x - y)." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            We can use this lemma for bounding <Inline tex="\nu_p(x^n \pm y^n)" /> and thus <Inline tex="x^n \pm y^n" /> from above.
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Lifting-the-exponent_lemma",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    EUCLIDS_THEOREM: function (depth = 0) {
        return {
            id: 6,
            type: "wikiEntry",
            title: "Euclid's theorem",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Euclid's theorem states that there are infinitely many prime numbers.
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Assume, that there are finitely many prime numbers <Inline tex="p_1, p_2, \dots, p_n" />. Clearly,
                            <Block tex="P = p_1 \cdot p_2 \cdot \dots \cdot p_n + 1 > 1." />
                            But this is a contradiction because no prime <Inline tex="p_i" /> divides <Inline tex="P" />.
                        </>
                }
            ],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Euclid%27s_theorem",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    EULER_CHARACTERISTIC: function (depth = 0) {
        return {
            id: 7,
            type: "wikiEntry",
            title: "Euler characteristic",
            altTitles: ["Euler's polyhedron formula"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For any <WikiInfo depth={depth} data={WikiData.PLANAR_GRAPH}>planar graph</WikiInfo> with <Inline tex="v" /> vertices, <Inline tex="e" /> edges, <Inline tex="f" /> faces, and <Inline tex="c" /> <WikiInfo depth={depth} data={WikiData.COMPONENT_GRAPH}>components</WikiInfo>, the relationship
                        <Block tex="v-e+f = c + 1" />
                        is true.
                    </>
            },
            proofs: [],
            notes: [
                {
                    content: <>
                        The definitions of <Inline tex="v, e" /> are clear for graphs. However, <Inline tex="f" /> is the number of regions (including the outer region) the plane is divided into if we embed the planar graph in the plane. We can deduce that <Inline tex="f" /> is independent of how we draw it in the plane.
                    </>
                },
                {
                    subtitle: "Euler's polyhedron formula",
                    content:
                        <>
                            In a <WikiInfo depth={depth} data={WikiData.CONNECTED_GRAPH}>connected graph</WikiInfo>, we have <Inline tex="c = 1" /> and thus
                            <Block tex="v-e+f = 2." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            A <WikiInfo depth={depth} data={WikiData.TREE_GRAPH}>tree</WikiInfo> is connected and planar with <Inline tex="f = 1" /> and thus
                            <Block tex="e = v - 1." />
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Euler_characteristic",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    HANDSHAKING_LEMMA: function (depth = 0) {
        return {
            id: 8,
            type: "wikiEntry",
            title: "Handshaking lemma",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        In an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> <Inline tex="G = (V, E)" />
                        <Block tex="2|E| = \sum_{v \in V} \deg_G(v)" />
                        holds, where <Inline tex="\deg_G(v)" /> is the vertex degree of <Inline tex="v" /> [see <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo>].
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            By <WikiInfo depth={depth} data={WikiData.DOUBLE_COUNTING}>double counting</WikiInfo> the number of pairs <Inline tex="(v, e)" />, where <Inline tex="v" /> is a vertex incident on an edge <Inline tex="e \in E" />, we get that
                            <Block tex="\sum_{v \in V} \deg_G(v) = |\{(v, e) : e \in E, v \in e\}| = \sum_{e \in E} 2 = 2 |E|." />
                        </>
                }
            ],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Handshaking_lemma",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    TREE_GRAPH: function (depth = 0) {
        return {
            id: 9,
            type: "wikiEntry",
            title: "Tree (graph theory)",
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="G = (V, E)" /> be an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo>.
                        The following are equivalent:
                        <ol className="lower-list">
                            <li>
                                <Inline tex="G" /> is a <WikiInfo depth={depth} data={WikiData.CONNECTED_GRAPH}>connected</WikiInfo> <WikiInfo depth={depth} data={WikiData.CYCLE_FREE_GRAPH}>forest</WikiInfo>.
                            </li>
                            <li>
                                There exists a unique path between any two vertices in <Inline tex="G" />.
                            </li>
                            <li>
                                <Inline tex="G" /> is a minimal connected graph with vertices <Inline tex="V" />.
                            </li>
                            <li>
                                <Inline tex="G" /> is a maximal cycle-free graph.
                            </li>
                            <li>
                                <Inline tex="|E| = |V| - 1" /> and <Inline tex="G" /> is cycle-free.
                            </li>
                            <li>
                                <Inline tex="|E| = |V| - 1" /> and <Inline tex="G" /> is connected.
                            </li>
                        </ol>
                        We call such a graph <Inline tex="G" /> a tree.
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            (a) <Inline tex="\implies" /> (b): <Inline tex="G" /> is connected. If there are two distinct <Inline tex="u" />-<Inline tex="v" />-paths, then their union contains a cycle.<br />
                            (b) <Inline tex="\implies" /> (c): <Inline tex="G" /> is connected. If <Inline tex="\exists e=\{u, v\} \in E: G-e" /> is connected. Then, there exists a <Inline tex="u" />-<Inline tex="v" />-path <Inline tex="p \in E \backslash\{e\}" />. Hence, <Inline tex="p" /> and <Inline tex="e" /> are two distinct <Inline tex="u" />-<Inline tex="v" />-paths in <Inline tex="G" />.<br />
                            (c) <Inline tex="\implies" /> (d): Every edge in <Inline tex="G" /> is a <WikiInfo depth={depth} data={WikiData.BRIDGE_GRAPH}>bridge</WikiInfo>. Hence, <Inline tex="G" /> is cycle-free. <Inline tex="G" /> is connected. Hence, the addition of an edge creates a cycle.<br />
                            (d) <Inline tex="\implies" /> (e): Induction over <Inline tex="|V|" />.<br />
                            (e) <Inline tex="\implies" /> (f): Induction over <Inline tex="|V|" />.<br />
                            (f) <Inline tex="\implies" /> (a): Induction over <Inline tex="|V|" />.
                        </>
                }
            ],
            notes: [{
                content: <>
                    The <WikiInfo depth={depth} data={WikiData.COMPONENT_GRAPH}>connected components</WikiInfo> of <WikiInfo depth={depth} data={WikiData.CYCLE_FREE_GRAPH}>forests</WikiInfo> are trees.
                </>
            }, {
                content: <>
                    We call a <WikiInfo depth={depth} data={WikiData.SUBGRAPH}>spanning subgraph</WikiInfo> of <Inline tex="G" /> a spanning tree or spanning forest if it is a tree or a forest. <br />
                    Every graph <Inline tex="G" /> has a spanning forest with the same number of components as <Inline tex="G" />. So, a spanning tree exists if and only if <Inline tex="G" /> is connected.
                </>
            }, {
                content: <>
                    We can deduce that <Inline tex="G = (V, E)" />
                    <ul>
                        <li>connected <Inline tex="\implies |E| \geq |V| - 1," /></li>
                        <li>has at least <Inline tex="|V| - |E|" /> components.</li>
                    </ul>
                </>
            }],
            source: {
                url: "https://en.wikipedia.org/wiki/Tree_(graph_theory)",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    PRIME_FIELD: function (depth = 0) {
        return {
            id: 10,
            type: "wikiEntry",
            title: "Prime field",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be a prime. Because there exists a multiplicative inverse for every non-zero residue class <Inline tex="\mod p," /> the set of those residue classes form a field together with <Inline tex="\mod p" />-addition and -multiplication. We call this field <Inline tex="\mathbb F_p." /><p />
                        In our notation, we associate an integer <Inline tex="n" /> with its residue class in <Inline tex="\mathbb F_p." /> So, we write <Inline tex="p = 0 \in \mathbb F_p." /> More generally, we write <Inline tex="a = b \in \mathbb F_p" /> instead of <Inline tex="a \equiv b \mod p." /> For <Inline tex="a \neq 0 \in \mathbb F_p," /> we write <Inline tex="a^{-1} = 1 / a \in \mathbb F_p" /> for its multiplicative inverse.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Finite_field",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    LAW_OF_QUADRATIC_RECIPROCITY: function (depth = 0) {
        return {
            id: 12,
            type: "wikiEntry",
            title: "Law of quadratic reciprocity",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        The law of quadratic reciprocity states, that for two distinct odd primes <Inline tex="p" /> and <Inline tex="q" />, the relationship
                        <Block tex="\left(\frac{p}{q}\right)\left(\frac{q}{p}\right) = (-1)^{\frac{p-1}{2}\frac{q-1}{2}
        }" />
                        holds, where <Inline tex="\left(\frac{a}{p}\right)" /> is the <WikiInfo depth={depth} data={WikiData.LEGENDRE_SYMBOL}>Legendre symbol</WikiInfo>.
                        <div className="proof-title">
                            1st supplement.
                        </div>
                        <Block tex="\left(\frac{-1}{p}\right) = (-1)^{\frac{p-1}{2}
        }=\begin{cases}1 & \textnormal{for $p \equiv 1 \mod 4$,}\\ -1 & \textnormal{for $p \equiv 3 \mod 4$.}\end{cases}" />
                        <div className="proof-title">
                            2nd supplement.
                        </div>
                        <Block tex="\left(\frac{2}{p}\right) = (-1)^{\frac{p^2-1}{8}
        }=\begin{cases}1 & \textnormal{for $p \equiv \pm 1 \mod 8$,}\\ -1 & \textnormal{for $p \equiv \pm 3 \mod 8$.}\end{cases}" />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            For <WikiInfo depth={depth} data={WikiData.SQUARE_FREE_INTEGER}>square-free</WikiInfo> <Inline tex="a" />, we can express
                            <Block tex="\left(\frac{\pm a}{p}\right)" />
                            in terms of
                            <Block tex="\left(\frac{q}{p}\right), \quad q \in \{q \, \textnormal{prime} : q \mid a\} \cup \{-1\}" />
                            because the <WikiInfo depth={depth} data={WikiData.LEGENDRE_SYMBOL}>Legendre symbol</WikiInfo> is multiplicative in its top argument.<p />
                            For fixed <Inline tex="a" /> and arbitrary large <Inline tex="p" />, these terms can be computed quickly by quadratic reciprocity.<p />
                            For instance, we have
                            <Block tex="\left(\frac{6}{97}\right) = \left(\frac{2}{97}\right)\left(\frac{3}{97}\right) = \underbrace{(-1)^{\frac{97^2-1}{8}}}_{=1} \left(\frac{97}{3}\right) \underbrace{(-1)^{\frac{3-1}{2}\frac{97-1}{2}}}_{=1} = \left(\frac{1}{3}\right) = 1." />
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Quadratic_reciprocity",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    DIRICHLETS_THEOREM_PRIMES: function (depth = 0) {
        return {
            id: 13,
            type: "wikiEntry",
            title: "Dirichlet's theorem (primes)",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For any two coprime positive integers <Inline tex="a" /> and <Inline tex="d" />, there exist infinitely many primes of the form <Block tex="a + d \cdot n, \quad n \in \mathbb N." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            So there are also infinitely many primes in each residue class <Inline tex="a \mod d" /> for coprime <Inline tex="a" /> and <Inline tex="d" />.
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Dirichlet%27s_theorem_on_arithmetic_progressions",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    UNDIRECTED_GRAPH: function (depth = 0) {
        return {
            id: 14,
            type: "wikiEntry",
            title: "Undirected graph",
            subtitle: "We also cover directed graphs and multigraphs",
            altTitles: ["multigraph", "directed graph", "path", "cycle", "contraction"],
            statement:
            {
                content:
                    <>
                        An undirected graph <Inline tex="G = (V, E)" /> is a tuple of a finite set of vertices <Inline tex="V" /> and a set of undirected edges <Block tex="E \subseteq \{\{u, v\} \mid u, v \in V, u \neq v\} = \{X \subseteq V \mid |X| = 2\} =: \binom{V}{2}" /> between them.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: "Directed graph",
                    content:
                        <>
                            In a directed graph <Inline tex="G" />, <Inline tex="E(G) \subseteq V(G)^2" /> is a set of ordered pairs of vertices. We interpret <Inline tex="(v, w) \in E(G)" /> as an edge going from <Inline tex="v" /> to <Inline tex="w" />. There are analogons for <Inline tex="f \in \{\delta, \mathrm{deg}, N\}" />. Namely, we define <Inline tex="f^+" /> and <Inline tex="f^-" /> analogously but only for outgoing or ingoing edges, respectively. We also define <Inline tex="f := f^+ \cup f^-" /> or <Inline tex="f := f^+ + f^-" />.
                        </>
                },
                {
                    subtitle: "Multigraph",
                    content:
                        <>
                            The above definition is of simple undirected graphs. <p />
                            More generally, we sometimes allow loops from a vertex to itself and parallel edges in a graph. We call such objects multigraphs. But if not stated otherwise, we consider simple graphs.
                        </>
                },
            ],
            definitions: [
                {
                    content: <>
                        We write <Block tex="V(G) := V" /> and <Block tex="E(G) := E." />
                    </>
                },
                {
                    subtitle: "Paths and cycles",
                    content: <>
                        A path is a graph <Inline tex="P" /> with <Inline tex="V(P) = \{v_1, \dots, v_n\}" /> pairwise distinct and <Block tex="E(P) = \{\{v_1, v_2\}, \{v_2, v_3\}, \dots, \{v_{n-1}, v_n\}\}." /> <br />
                        A cycle is a graph <Inline tex="C" /> with <Inline tex="V(C) = \{v_1, \dots, v_n\}" /> pairwise distinct and <Block tex="E(C) = \{\{v_1, v_2\}, \{v_2, v_3\}, \dots, \{v_{n-1}, v_n\}, \{v_n, v_1\}\}." />
                    </>
                },
                {
                    subtitle: "Degree and neighborhood",
                    content: <>
                        For <Inline tex="X \subseteq V(G)" />, we define <Block tex="\delta(X) := \{e \in E(G) \mid |e \cap X| = 1\}" /> and abbreviate <Inline tex="\delta(v) := \delta(\{v\})" />. The degree of <Inline tex="v \in V(G)" /> is <Block tex="\mathrm{deg}(v) := |\delta(v)|." />
                        We define the neighborhood <Block tex="N(X) := \{w \in V(G) \mid X \cap \delta(w) \neq \varnothing\}" />
                        and abbreviate <Inline tex="N(v) := N(\{v\})." />
                    </>
                },
                {
                    subtitle: <><Inline tex="+" />- and <Inline tex="-" />-operation</>,
                    content: <>
                        Let <Inline tex="v" /> be a vertex and let <Inline tex="e" /> be an edge (this should always be clear from context, although of course any object - like a two element set - can be a vertex).<br />
                        We define
                        <Block tex="G + v := (V(G) \cup \{v\}, E(G))," />
                        and
                        <Block tex="G + e := (V(G) \cup e, E(G) \cup \{e\})." /> <br />
                        We define
                        <Block tex="G - v := \left(V(G) \backslash \{v\}, E(G) \cap \binom{V(G) \backslash \{v\}}{2}\right) = G[V(G) \backslash \{v\}]," />
                        and
                        <Block tex="G - e := (V(G), E(G) \backslash \{e\})." />
                        For a set <Inline tex="S" /> of vertices or of edges, we define <Inline tex="G \pm S" /> by applying these operations iteratively.
                    </>
                },
                {
                    subtitle: "Induced subgraph",
                    content: <>
                        The <WikiInfo depth={depth} data={WikiData.INDUCED_SUBGRAPH}>induced subgraph</WikiInfo>-operation is another important operation.
                    </>
                },
                {
                    subtitle: <>Vertex- and edge-contraction</>,
                    content: <>
                        Let <Inline tex="X \subseteq V(G)" />. Contracting <Inline tex="X" /> means adding a new vertex <Inline tex="x" /> and edges <Block tex="\{x, w\} \quad \forall e = \{v, w\} \in \delta(X), v \in X" /> and then removing <Inline tex="X" /> plus all incident edges. Under this operation, parallel edges may arise. We write <Inline tex="G / X" /> for the resulting graph.<br />
                        So, we have already defined <Inline tex="G / e" /> for <Inline tex="e \in E(G)" />. More generally, let <Inline tex="F = \{e_1, \dots, e_k\} \subseteq \binom{V(G)}{2}" />. We define
                        <Block tex="G / F := G / e_1 / \dots / e_k." />
                        We can think of this in another way: Let <Inline tex="C_1, \dots, C_\ell" /> be the <WikiInfo depth={depth} data={WikiData.COMPONENT_GRAPH}>connected components</WikiInfo> of <Inline tex="(V(G), F)" />. We have
                        <Block tex="G / F = G / C_1 / \dots / C_\ell." />
                    </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Graph_(discrete_mathematics)",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    PLANAR_GRAPH: function (depth = 0) {
        return {
            id: 15,
            type: "wikiEntry",
            title: "Planar graph",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        A planar graph is an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> <Inline tex="G = (V, E)" /> that can be embedded in the plane. I.e., it can be drawn on the plane, such that its edges intersect only at their endpoints.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Planar_graph",
                webTitle: "Wikipedia"
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    COMPONENT_GRAPH: function (depth = 0) {
        return {
            id: 16,
            type: "wikiEntry",
            title: "Component (graph theory)",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        A component of an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> <Inline tex="G = (V, E)" /> is a maximal (with respect to inclusion) subset <Inline tex="V'" /> of <Inline tex="V" /> such that <WikiInfo data={WikiData.INDUCED_SUBGRAPH} depth={depth}><Inline tex="G[V']" /></WikiInfo> is <WikiInfo data={WikiData.CONNECTED_GRAPH} depth={depth}>connected</WikiInfo>.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Component_(graph_theory)",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    CONNECTED_GRAPH: function (depth = 0) {
        return {
            id: 17,
            type: "wikiEntry",
            title: "Connected graph",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        An <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> is connected if for any <Inline tex="v, w \in V(G)" /> there exists a <Inline tex="v" />-<Inline tex="w" />-path in <Inline tex="G" />.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Connectivity_(graph_theory)",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    INDUCED_SUBGRAPH: function (depth = 0) {
        return {
            id: 18,
            type: "wikiEntry",
            title: "Induced subgraph",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="G = (V, E)" /> be an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo>. A subgraph <Inline tex="G' = (V', E')" /> of <Inline tex="G" /> is an induced subgraph if <Block tex="E' = \{\{v, w\} \in E : v, w \in V'\}." /><p />
                        We say that <Inline tex="V'" /> induces the subgraph <Inline tex="G'" /> of <Inline tex="G" /> or that <Inline tex="G'" /> is the induced subgraph by <Inline tex="V'" /> of <Inline tex="G" /> and write <Inline tex="G' = G[V']" />.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Cycle_(graph_theory)",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    BRIDGE_GRAPH: function (depth = 0) {
        return {
            id: 19,
            type: "wikiEntry",
            title: "Bridge (graph theory)",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        A bridge is an edge <Inline tex="e = \{u, v\}" /> of an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> <Inline tex="G" />, such that <Inline tex="G - e" /> has more components than <Inline tex="G" />. I.e, its deletion increases the number of components.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            In the new graph <Inline tex="u" /> and <Inline tex="v" /> lie in different components <Inline tex="C_u" /> and <Inline tex="C_v" />, respectively. Any path in <Inline tex="G" /> between <Inline tex="x \in C_u" /> and <Inline tex="y \in C_v" /> contains <Inline tex="e" />.
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Bridge_(graph_theory)",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.C,
        }
    },
    CYCLE_FREE_GRAPH: function (depth = 0) {
        return {
            id: 20,
            type: "wikiEntry",
            title: "Forest",
            subtitle: "cycle-free graph",
            altTitles: ["cycle-free graph"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        An <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> is a forest (or cycle-free) if and only if it has no <WikiInfo depth={depth} data={WikiData.SUBGRAPH}>subgraph</WikiInfo> that is a cycle.
                    </>
            },
            proofs: [],
            notes: [],
            source: undefined,
            examples: [],
            branch: BranchData.C,
        }
    },
    MODULAR_CONGRUENCE: function (depth = 0) {
        return {
            id: 23,
            type: "wikiEntry",
            title: "Modular congruence",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be a prime. We call two integers <Inline tex="a" /> and <Inline tex="b" /> congruent or equivalent modulo <Inline tex="p" /> if <Inline tex="p \mid a - b." /> Then, we write
                        <Block tex="a \equiv b \mod p." />
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Congruence_relation",
                webTitle: "Wikipedia"
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    P_ADIC_VALUATION: function (depth = 0) {
        return {
            id: 24,
            type: "wikiEntry",
            title: "p-adic valuation",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be a prime. We define the p-adic valuation
                        <Block tex="\nu_p: \mathbb Z \backslash \{0\} \to \mathbb N," />
                        such that
                        <Block tex="p^{\nu_p(n)} \mid n \quad \textnormal{and} \quad p^{\nu_p(n) + 1} \nmid n." />
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/P-adic_valuation",
                webTitle: "Wikipedia"
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    LEGENDRE_SYMBOL: function (depth = 0) {
        return {
            id: 25,
            type: "wikiEntry",
            title: "Legendre symbol",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be prime and <Inline tex="a" /> an integer. We define the Legendre symbol of <Inline tex="a" /> and <Inline tex="p" /> as
                        <Block tex="\left(\frac{a}{p}\right) = \begin{cases}0 & \textnormal{for $a \equiv 0 \mod p$,}\\ 1 & \textnormal{for $\exists x \in \mathbb Z: a \equiv x^2 \not \equiv 0 \mod p$},\\ -1 & \textnormal{otherwise.}\end{cases}" />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: "Multiplicativity",
                    content:
                        <>
                            We have
                            <Block tex="\left(\frac{ab}{p}\right) = \left(\frac{a}{p}\right)\left(\frac{b}{p}\right)." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            Combined with the <WikiInfo depth={depth} data={WikiData.LAW_OF_QUADRATIC_RECIPROCITY}>Law of quadratic reciprocity</WikiInfo> and <WikiInfo depth={depth} data={WikiData.EULERS_CRITERION}>Euler's criterion</WikiInfo>, we can do some calculations involving the Legendre symbol.
                        </>
                },
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Legendre_symbol",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    SQUARE_FREE_INTEGER: function (depth = 0) {
        return {
            id: 26,
            type: "wikiEntry",
            title: "Square-free integer",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        An integer <Inline tex="n" /> is square-free if for any prime <Inline tex="p" />, the condition
                        <Block tex="p^2 \nmid n" />
                        holds.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Multiplicative_function",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    EULERS_CRITERION: function (depth = 0) {
        return {
            id: 27,
            type: "wikiEntry",
            title: "Euler's criterion",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p" /> be an odd prime and let <Inline tex="a" /> be a residue class <Inline tex="\mod p." /> We consider the <WikiInfo depth={depth} data={WikiData.LEGENDRE_SYMBOL}>Legendre symbol</WikiInfo> <Inline tex="\left(\frac{a}{p}\right)" />. Euler's criterion states that
                        <Block tex="\left(\frac{a}{p}\right) \equiv a^{\frac{p-1}{2}} \mod p." />
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Hint: Represent <Inline tex="a" /> in terms of a <WikiInfo depth={depth} data={WikiData.PRIMITIVE_ROOT_MODULO_N}>primitive root</WikiInfo>.
                        </>
                }
            ],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Euler%27s_criterion",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    BERNOULLIS_INEQUALITY: function (depth = 0) {
        return {
            id: 28,
            type: "wikiEntry",
            title: "Bernoulli's inequality",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="x > -1" /> be a real number and let <Inline tex="r" /> be a non-negative real number.<p />
                        For <Inline tex="0 \leq r < 1" />:
                        <Block tex="(1+x)^r \leq 1 + rx," />
                        and for <Inline tex="1 \leq r" />:
                        <Block tex="(1+x)^r \geq 1 + rx." />
                        Equality holds at <Inline tex="r \in \{0, 1\}" /> or <Inline tex="x = 0" />.
                    </>
            },
            proofs: [],
            notes: [],
            source: {
                url: "https://en.wikipedia.org/wiki/Bernoulli%27s_inequality",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    WEIGHTED_AM_GM: function (depth = 0) {
        return {
            id: 29,
            type: "wikiEntry",
            title: "Weighted AM-GM inequality",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For all <Inline tex="n \in \mathbb N" /> and <Inline tex="x_1, x_2, \dots, x_n \in \mathbb R_{\geq 0}" /> and weights <Inline tex="w_1, w_2, \dots, w_n \in \mathbb R_{> 0}" /> with sum <Block tex="w := w_1 + w_2 + \dots + w_n," /> we have
                        <Block tex="\frac{w_1x_1 + w_2x_2 + \dots + w_n x_n}{w} \geq \sqrt[w]{x_1^{w_1}x_2^{w_2} \dots x_n^{w_n}}." />
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            For rational weights, this follows from the <WikiInfo depth={depth} data={WikiData.AM_GM_INEQUALITY}>AM-GM inequality</WikiInfo>: We can multiply the <Inline tex="w_i" /> by the lcm of their denominators without changing the statement. Thus, we may WLOG assume <Inline tex="w_i \in \mathbb N" />. Then, plugging
                            <Block tex="(\underbrace{x_1, \dots, x_1}_{w_1 \, \textnormal{times}}, \dots, \underbrace{x_i, \dots, x_i}_{w_i \, \textnormal{times}}, \dots, \underbrace{x_n, \dots, x_n}_{w_n \, \textnormal{times}})" />
                            into the AM-GM inequality gives the desired result. Thus, if we let rational weights converge to any real weights, the general result follows.
                        </>
                }
            ],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Equality holds if and only if <Inline tex="x_1 = x_2 = \dots = x_n" />.
                        </>
                }
            ],
            source: {
                url: "https://en.wikipedia.org/wiki/Bernoulli%27s_inequality",
                webTitle: "Wikipedia",
            },
            examples: [],
            branch: BranchData.N,
        }
    },
    SUBGRAPH: function (depth = 0) {
        return {
            id: 30,
            type: "wikiEntry",
            title: "Subgraph",
            altTitles: ["spanning subgraph"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="G = (V, E)" /> be an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo>. A graph <Inline tex="G' = (V', E')" /> is a subgraph of <Inline tex="G" /> if <Inline tex="V' \subseteq V" /> and <Inline tex="E' \subseteq E" />. <p />
                        We also call a graph subgraph of <Inline tex="G" /> if it is isomorphic to a subgraph of <Inline tex="G" />.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Of course, this definition implicitly requires <Inline tex="E' \subseteq \binom{V'}{2}" />.
                        </>
                },
                {
                    subtitle: "Spanning subgraph",
                    content: <>We call a subgraph <Inline tex="(V', E')" /> of <Inline tex="G" /> spanning if <Inline tex="V' = V." /></>
                }],
            examples: [],
            branch: BranchData.C,
            source: {
                url: "https://en.wikipedia.org/wiki/Subgraph",
                webTitle: "Wikipedia",
            },
        }
    },
    DOUBLE_COUNTING: function (depth = 0) {
        return {
            id: 31,
            type: "wikiEntry",
            title: "Double counting",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Double counting is a general proof technique for proving that two expressions are of equal size by showing that they can both be interpreted as the sizes of the same set.
                    </>
            },
            proofs: [],
            notes: [],
            examples: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            By definition, the number of ways for choosing <Inline tex="n" /> persons from <Inline tex="2n" /> persons is <Block tex="\binom{2n}{n}." />
                            On the other hand, the number of ways for choosing <Inline tex="n" /> persons from <Inline tex="n" /> men and <Inline tex="n" /> women is
                            <Block tex="\sum_{k=0}^n \binom{n}{k} \binom{n}{n-k}," />
                            where each summand corresponds to the number of ways for choosing <Inline tex="k" /> men and <Inline tex="n-k" /> women.<p />
                            So, we conclude that
                            <Block tex="\binom{2n}{n} = \sum_{k=0}^n \binom{n}{k} \binom{n}{n-k} = \sum_{k=0}^n \binom{n}{k}^2." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            The proof of the <WikiInfo depth={depth} data={WikiData.HANDSHAKING_LEMMA}>handshaking lemma</WikiInfo>.
                        </>
                }
            ],
            branch: BranchData.C,
            source: {
                url: "https://en.wikipedia.org/wiki/Double_counting_(proof_technique)",
                webTitle: "Wikipedia",
            },
        }
    },
    DIRECTED_ANGLES: function (depth = 0) {
        return {
            id: 32,
            type: "wikiEntry",
            title: "Directed angles",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We define the directed angle <Inline tex="\sphericalangle XYZ" /> modulo <Inline tex="180^\circ" /> to be the counterclockwise angle required to rotate line <Inline tex="XY" /> onto line <Inline tex="ZY" /> around <Inline tex="Y" /> with respect to <Inline tex="\mod 180^\circ." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Taking the angle modulo <Inline tex="180^\circ" /> makes sense because there are many angles around which <Inline tex="XY" /> could be rotated counterclockwise such that it coincides with <Inline tex="ZY" />. The set of such angles is exactly one residue class modulo <Inline tex="180^\circ" />.
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            When using <Inline tex="\sphericalangle" />, we just take everything <Inline tex="\mod 180^\circ" />. So, for a triangle <Inline tex="ABC" />, we would write <Block tex="\sphericalangle BAC + \sphericalangle CBA + \sphericalangle ACB = 180^\circ = 0^\circ." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            The motivation for using <Inline tex="\sphericalangle" />, is that you can often avoid configuration issues with them.
                        </>
                }
            ],
            examples: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/directed-angle.png")} id="spherical-angle-example" />
                            </div>
                            We have
                            <Block tex="\sphericalangle XYZ = -\sphericalangle ZYX" />
                            and
                            <Block tex="\sphericalangle XYZ = X'YZ." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/directed-angle-2.png")} id="spherical-angle-example-2" />
                            </div>
                            Angles inside an inscribed quadrilateral can be treated without cases for different configurations using directed angles modulo <Inline tex="180^\circ" />. As usual, we have
                            <Block tex="\sphericalangle DBA = \sphericalangle DCA." />
                            But
                            <Block tex="\sphericalangle CBA = \sphericalangle CDA" />
                            also holds.
                        </>
                }
            ],
            branch: BranchData.G,
            source: {
                url: "https://brilliant.org/wiki/directed-angles/",
                webTitle: "Brilliant",
            },
        }
    },
    INCENTER_EXCENTER_LEMMA: function (depth = 0) {
        return {
            id: 33,
            type: "wikiEntry",
            title: "Incenter/Excenter Lemma",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        <div className="wiki-img-div">
                            <img src={require("../img/wiki-images/incenter-excenter-lemma.png")} id="incenter-excenter-lemma" />
                        </div>
                        Let <Inline tex="I" /> be the <WikiInfo depth={depth} data={WikiData.INCENTER}>incenter</WikiInfo> and let <Inline tex="I_A" /> be the <WikiInfo depth={depth} data={WikiData.EXCENTER}><Inline tex="A" />-excenter</WikiInfo> of <Inline tex="\triangle ABC" />. Then <Inline tex="I, B, I_A, C" /> lie on a circle, whose midpoint <Inline tex="S" /> is the midpoint of arc <Inline tex="\overset{\huge\frown}{BC}" /> (which <Inline tex="A" /> does not lie in).
                    </>
            },
            proofs: [],
            notes: [],
            examples: [],
            branch: BranchData.G,
            source: {
                url: "https://artofproblemsolving.com/wiki/index.php/Incenter/excenter_lemma",
                webTitle: "Aops",
            },
        }
    },
    INCENTER: function (depth = 0) {
        return {
            id: 34,
            type: "wikiEntry",
            title: "Incircle/incenter",
            altTitles: ["Incircle", "Incenter", "Incircle tangents"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For any triangle <Inline tex="ABC" />, there exists a unique circle that is tangent to the segments <Inline tex="AB" />, <Inline tex="BC" /> and <Inline tex="CA" />. We call this circle the incircle of <Inline tex="\triangle ABC" /> and its center the incenter of <Inline tex="\triangle ABC" />.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/incenter.png")} id="incenter" />
                            </div>
                            If <Inline tex="I" /> is the incenter of <Inline tex="\triangle ABC" />, then it has equal signed distance to the lines <Inline tex="AB" />, <Inline tex="BC" /> and <Inline tex="CA" />. So it must lie on the interior angle bisectors of <Inline tex="\angle BAC" />, <Inline tex="\angle CBA" /> and <Inline tex="\angle ACB" />. So, <Inline tex="I" /> is the interesection of the interior angle bisectors of <Inline tex="\triangle ABC" /> (if <Inline tex="\triangle ABC" /> is non-degenerate).
                        </>
                },
                {
                    subtitle: "Tangency points",
                    content:
                        <>
                            Let <Inline tex="k" /> be the incircle of <Inline tex="\triangle ABC" /> with sidelengths <Inline tex="a, b" /> and <Inline tex="c." /> Let <Inline tex="D, E," /> and <Inline tex="F" /> be the touching points of <Inline tex="k" /> with <Inline tex="BC, CA," /> and <Inline tex="AB." /> Moreover, we let
                            <Block tex="s_a := \frac{-a+b+c}{2}," />
                            <Block tex="s_b := \frac{a-b+c}{2}," />
                            <Block tex="s_c := \frac{a+b-c}{2}." />
                            Then, we have
                            <Block tex="AE = AF = s_a, " />
                            <Block tex="BF = BD = s_b, " />
                            <Block tex="CD = CE = s_c. " />
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/incircle-tangents.png")} id="incircle-tangents" />
                            </div>
                        </>,
                    proof:
                        <>
                            We have
                            <Block tex="2s_a = AE + AF = b + c \underbrace{- s_b - s_c}_{= - BC} = b + c - a." />
                        </>
                },
            ],
            examples: [],
            branch: BranchData.G,
        }
    },
    EXCENTER: function (depth = 0) {
        return {
            id: 35,
            type: "wikiEntry",
            title: "Excircle/excenter",
            altTitles: ["Excircle", "Excenter", "Excircle tangents"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For any triangle <Inline tex="ABC" />, there exists a unique circle that is tangent to the segment <Inline tex="BC" /> and to the extensions of the segments <Inline tex="CA" /> and <Inline tex="AB" />. We call this circle the <Inline tex="A" />-excircle of <Inline tex="\triangle ABC" /> and its center the <Inline tex="A" />-excenter of <Inline tex="\triangle ABC" />. <br />
                        We define the <Inline tex="B" />-excircle, <Inline tex="B" />-excenter, <Inline tex="C" />-excircle and <Inline tex="C" />-excenter similarly.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/excenter.png")} id="excenter" />
                            </div>
                            If <Inline tex="I_A" /> is the <Inline tex="A" />-excenter of <Inline tex="\triangle ABC" />, then it has equal signed distance to the lines <Inline tex="AB" />, <Inline tex="CB" /> and <Inline tex="CA" />. So its signed distance to <Inline tex="BC" /> is the negative of its signed distance to <Inline tex="AB" /> and its signed distance to <Inline tex="CA" />. Therefore, it must lie on the interior angle bisector of <Inline tex="\angle BAC" /> and the exterior angle bisectors of <Inline tex="\angle CBA" /> and <Inline tex="\angle ACB" />. So, <Inline tex="I_A" /> is the interesection of these lines (if <Inline tex="\triangle ABC" /> is non-degenerate).
                        </>
                },
                {
                    subtitle: "Tangency points",
                    content:
                        <>
                            Let <Inline tex="k" /> be the <Inline tex="A" />-excircle of <Inline tex="\triangle ABC" /> with sidelengths <Inline tex="a, b" /> and <Inline tex="c." /> Let <Inline tex="D, E," /> and <Inline tex="F" /> be the touching points of <Inline tex="k" /> with <Inline tex="BC, CA," /> and <Inline tex="AB." /> Moreover, we let
                            <Block tex="s := \frac{a+b+c}{2}," />
                            <Block tex="s_b := \frac{a-b+c}{2}," />
                            <Block tex="s_c := \frac{a+b-c}{2}." />
                            Then, we have
                            <Block tex="AE = AF = s, " />
                            <Block tex="BF = BD = s_c, " />
                            <Block tex="CD = CE = s_b. " />
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/excircle-tangents.png")} id="incircle-tangents" />
                            </div>
                        </>,
                    proof:
                        <>
                            The tangent segment equalities give us a linear system of equations. This is similar to the proof of the <WikiInfo depth={depth} data={WikiData.INCENTER}>analogous result for incircles</WikiInfo>.
                        </>
                },
            ],
            examples: [],
            branch: BranchData.G,
        }
    },
    TANGENT_CHORD_THEOREM: function (depth = 0) {
        return {
            id: 36,
            type: "wikiEntry",
            title: "Tangent-chord theorem",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We use <WikiInfo depth={depth} data={WikiData.DIRECTED_ANGLES}>directed angles</WikiInfo>.
                        <div className="wiki-img-div">
                            <img src={require("../img/wiki-images/tangent-chord-theorem.png")} id="tangent-chord-theorem" />
                        </div>
                        Let <Inline tex="k" /> be a circle and consider <Inline tex="A, B, C \in k" />. Let <Inline tex="PA" /> be a tangent to <Inline tex="k" />. Then,
                        <Block tex="\sphericalangle PAB = \sphericalangle ACB." />
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Let <Inline tex="O" /> be the center of <Inline tex="k" /> and <Inline tex="P\neq A" /> be the second intersection of <Inline tex="AO" /> with <Inline tex="k" />. Then, we have
                            <Block tex="\sphericalangle PAB = 90^\circ - \sphericalangle BAO = 90^\circ - \sphericalangle BAP = \sphericalangle APB = \sphericalangle ACB" />
                        </>
                }
            ],
            notes: [],
            examples: [],
            branch: BranchData.G,
            source: {
                url: "https://brilliant.org/wiki/alternate-segment-theorem-2/",
                webTitle: "Brilliant",
            },
        }
    },
    INSCRIBED_ANGLE_THEOREM: function (depth = 0) {
        return {
            id: 37,
            type: "wikiEntry",
            title: "Inscribed angle theorem",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We use <WikiInfo depth={depth} data={WikiData.DIRECTED_ANGLES}>directed angles</WikiInfo>.
                        <div className="wiki-img-div">
                            <img src={require("../img/wiki-images/inscribed-angle.png")} id="inscribed-angle" />
                        </div>
                        Let <Inline tex="k" /> be a circle with center <Inline tex="O" />. Let <Inline tex="A, B \in k" />. For any <Inline tex="C \in k \backslash \{A, B\}" />, we have
                        <Block tex="2\sphericalangle ACB = \sphericalangle AOB." />
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Any triangle with a vertex <Inline tex="O" /> and two other vertices on <Inline tex="k" /> is isosceles with peak <Inline tex="O" />. Therefore, by symmetry, we get that
                            <Block tex="2\sphericalangle ACB = (\sphericalangle OAC + \sphericalangle ACO) + (\sphericalangle OCB + \sphericalangle CBO) = - \sphericalangle COA - \sphericalangle BOC = \sphericalangle AOB." />
                        </>
                }
            ],
            notes: [],
            examples: [],
            branch: BranchData.G,
            source: {
                url: "https://brilliant.org/wiki/alternate-segment-theorem-2/",
                webTitle: "Brilliant",
            },
        }
    },
    PRIMITIVE_ROOT_MODULO_N: function (depth = 0) {
        return {
            id: 38,
            type: "wikiEntry",
            title: "Primitive root (modulo n)",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="p \in \mathbb N" /> be a prime. We call <Inline tex="g \in \mathbb Z" /> a primitive root modulo <Inline tex="p" /> if <Inline tex="p \nmid g" /> and the <WikiInfo depth={depth} data={WikiData.MULTIPLICATIVE_ORDER}>order</WikiInfo> of <Inline tex="g" /> modulo <Inline tex="p" /> is <Inline tex="\mathrm{ord}_p(g) = p - 1" />. Equivalently, <Inline tex="g^1, g^2, \dots, g^{p-1}" /> contains every non-zero residue class <Inline tex="\mod p" /> exactly once. <p />
                        For any prime <Inline tex="p" />, there exists a primitive root modulo <Inline tex="p" />.
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            We already know that for <Inline tex="a \in \{1, 2, \dots, p-1\}" />, we have <Inline tex="\mathrm{ord}_p(a) \mid p - 1" /> [see <WikiInfo depth={depth} data={WikiData.MULTIPLICATIVE_ORDER}>order</WikiInfo>]. So let us define <Block tex="r(d) = |\{a \in \{1, 2, \dots, p - 1\} : \mathrm{ord}_p(a) = d\}|" /> for <Inline tex="d \mid p - 1" />. Thus, we get
                            <Block tex="\sum_{d \mid p - 1} r(d) = |\{1, 2, \dots, p - 1\}| = p - 1 = \sum_{d \mid p - 1} \varphi(d)" />
                            [For the last equality, see <WikiInfo depth={depth} data={WikiData.EULERS_TOTIENT_FUNCTION}>Euler's totient function</WikiInfo>].<br />
                            Let us now consider <Inline tex="a, b" /> with <Inline tex="\mathrm{ord}_p(a) = \mathrm{ord}_p(b) = d" /> for some <Inline tex="d \mid p - 1" />. We note that <Inline tex="a^1, a^2, \dots a^d" /> are in pairwise distinct residue classes modulo <Inline tex="p" /> because <Block tex="a^i \equiv a^j \mod p, 0 < i < j \implies a^{j-i} \equiv 1 \mod p, j-i > 0, i > 0 \implies j - i \geq d, i > 0 \implies j \geq d + i > d." />
                            Also note that <Block tex="a^i, i \in \{1, \dots, d\}" /> are roots of the polynomial <Inline tex="x^d - 1 \mod. p" />. Therefore, we conclude <Block tex="x^d - 1 \equiv \prod_{i=1}^d \left(x - a^i\right) \mod p." />
                            In particular, it follows that <Block tex="0 \equiv b^d - 1 \equiv \prod_{i=1}^d \left(b - a^i\right) \mod p." />
                            Therefore, we have <Inline tex="b \equiv a^i \mod p" /> for some <Inline tex="1 \leq i \leq d" />. We can also see that
                            <Block tex="b^{d / \gcd(d, i)} \equiv \left(a^d\right)^{i / \gcd(d, i)} \equiv 1 \mod p" />
                            and thus <Inline tex="d = \mathrm{ord}_p(b) \leq d / \gcd(d, i)" />. So, <Inline tex="d" /> and <Inline tex="i" /> are coprime. We conclude that there are at most <Inline tex="\varphi(d)" /> possible residue classes for <Inline tex="b \mod p" />. Hence, <Inline tex="r(d) \leq \varphi(d) \quad \forall d \mid p - 1" />. Thus, we get
                            <Block tex="\sum_{d \mid p - 1} \varphi(d) = \sum_{d \mid p - 1} r(d) \leq \sum_{d \mid p - 1} \varphi(d)." />
                            So, we must have equality everywhere. In particular,
                            <Block tex="r(p - 1) = \varphi(p - 1) > 0," />
                            which is what we needed to show.
                        </>
                }
            ],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Let <Inline tex="n \in \mathbb N" />. We call <Inline tex="g \in \mathbb Z" /> a primitive root modulo <Inline tex="n" /> if <Inline tex="\gcd(g, n) = 1" /> and <Inline tex="\mathrm{ord}_n(g) = \varphi(n)" />, where <Inline tex="\varphi" /> is <WikiInfo depth={depth} data={WikiData.EULERS_TOTIENT_FUNCTION}>Euler's totient function</WikiInfo>. There exists a primitive root modulo <Inline tex="n" /> if and only if <Block tex="n \in \{1, 2, 4\} \cup \{p^k, 2p^k : p \,\, \textnormal{prime}, 2 \nmid p, k \in \mathbb N\}." />

                        </>
                },
                {
                    subtitle: "Powers modulo p",
                    content: <>
                        For <Inline tex="k \geq 2," /> what is the distribution of <Inline tex="k" />-th powers <Inline tex="\mod p" />? To answer this question, we consider a primitive root <Inline tex="g \mod p." /> For <Inline tex="1 \leq a, b \leq p - 1," /> we have <Inline tex="(g^a)^5 \equiv (g^b)^5 \mod p \iff p - 1 \mid 5(b - a)." /> If <Inline tex="5 \nmid p - 1" /> this is only the case for <Inline tex="a = b." /> Otherwise, this is the case, when <Inline tex="\frac{p-1}{5} \mid b - a." /> So, every non-zero fifth power has five distinct fifth roots. Since <Inline tex="0^5 = 0" />, we conclude that the number of fifth powers <Inline tex="\mod p" /> is <Inline tex="\frac{p-1}{5} + 1." />
                    </>,
                }
            ],
            examples: [],
            branch: BranchData.N,
            source: {
                url: "https://brilliant.org/wiki/primitive-roots/",
                webTitle: "Brilliant",
            },
        }
    },
    EULERS_TOTIENT_FUNCTION: function (depth = 0) {
        return {
            id: 39,
            type: "wikiEntry",
            title: "Euler's totient function",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Euler's totient function is <Block tex="\varphi: \mathbb Z^+ \to \mathbb Z^+, n \mapsto |\{1 \leq a \leq n : \gcd(n, a) = 1\}|." />
                        So, <Inline tex="\varphi(n)" /> is the number of integers between <Inline tex="1" /> and <Inline tex="n" /> that are coprime to <Inline tex="n" />. <p />
                        Let <Inline tex="n = p_1^{x_1} p_2^{x_2} \dots p_k^{x_k}" />, where <Inline tex="p_i" /> are pairwise distinct primes and <Inline tex="x_i \in \mathbb Z^+" />. We have
                        <Block tex="\varphi(n) = (p_1 - 1)p_1^{x_1 - 1} (p_2 - 1)p_2^{x_2 - 1} \dots (p_k - 1)p_k^{x_k - 1} =  n\prod_{\substack{p \,\, \textnormal{prime}\\ p \mid n}} \frac{p-1}{p}." />
                    </>
            },
            proofs: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            First, consider <Inline tex="n = p^x" />. Consider <Inline tex="1 \leq a \leq p^x" />. We can rewrite <Block tex="a = s\cdot p + t,\, s, t \in \mathbb N, t < p" /> and obtain
                            <Block tex="\gcd(p^x, a) = 1 \iff 1 = \gcd(p, a) = \gcd(p, t)." />
                            Thus, <Block tex="\varphi(p^x) = |\{1 \leq a \leq p^x : \gcd(p^x, a) = 1\}| = |\{ps + t : 0 \leq s < p^{x - 1}, 1 \leq t < p, \gcd(p, t) = 1\}| = p^{x-1} \varphi(p) = (p-1) p^{x-1}." />
                            We now prove that <Inline tex="\varphi" /> is <WikiInfo data={WikiData.MULTIPLICATIVE_FUNCTION_NT} depth={depth}>multiplicative</WikiInfo>. So, we consider <Inline tex="m, n" /> coprime. By the <WikiInfo depth={depth} data={WikiData.CHINESE_REMAINDER_THEOREM}>chinese remainder theorem</WikiInfo> there exists a bijection <Block tex="\gamma : \{1, \dots, m\} \times \{1, \dots, n\} \to \{1, \dots, mn\}" />
                            such that <Block tex="\gamma(a, b) \equiv a \mod m \quad \textnormal{and} \quad \gamma(a, b) \equiv b \mod n." />
                            Therefore,
                            <Block tex="\gcd(m, a) = \mathrm {gcd}(n, b) = 1 \iff \gcd(mn, \gamma(a, b)) = 1." />
                            In other words, <Inline tex="\gamma" /> induces a bijection
                            <Block tex="\{1 \leq a \leq m : \gcd(m, a) = 1\} \times \{1 \leq a \leq n : \gcd(n, a) = 1\} \to \{1 \leq a \leq mn : \gcd(mn, a) = 1\}." />
                            Therefore,
                            <Block tex="\varphi(m) \cdot \varphi(n) = \varphi(mn) \quad \forall m, n, \gcd(m, n) = 1." /> <p />
                            Combining these results, we get that
                            <Block tex="\varphi\left(\prod_{i=1}^k p_i^{x_i}\right) = \prod_{i=1}^k \varphi\left(p_i^{x_i}\right) = \prod_{i=1}^k \left((p_i - 1)p_i^{x_i - 1}\right)." />
                        </>
                }
            ],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            We have
                            <Block tex="\varphi(n) = \sum_{d \mid n} \mu(d) \frac{n}{d}," />
                            where <Inline tex="\mu" /> is the <WikiInfo data={WikiData.MOEBIUS_FUNCTION} depth={depth}>Möbius function</WikiInfo>.
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            We have
                            <Block tex="\sum_{d \mid n} \varphi(d) = n." />
                            This can be proven by the <WikiInfo depth={depth} data={WikiData.MOEBIUS_FUNCTION}>Möbius inversion formula</WikiInfo>, which we use in the last equality. We have
                            <Block tex="\sum_{d \mid n} \varphi(d) = \sum_{d \mid n} \sum_{e \mid d} \mu(e) \frac{d}{e} = \sum_{e \mid n} \mu(e) \sum_{e \mid d \mid n} \frac{d}{e} = \sum_{e \mid n} \mu(e) \sum_{f \mid n / e} f = n," />
                            where we substitute <Inline tex="f = d / e" />. <br />
                            You can prove this result without the Möbius inversion formula, for example using the Inclusion-exclusion principle (PIE). We can also prove the Möbius inversion formula using PIE and so, it is kind of an encoding for PIE. Note: A neat proof for PIE can be written down in terms of <WikiInfo depth={depth} data={WikiData.PROBABILITY_SPACE}>probability spaces</WikiInfo>.
                        </>
                }
            ],
            examples: [],
            branch: BranchData.N,
            source: {
                url: "https://brilliant.org/wiki/primitive-roots/",
                webTitle: "Brilliant",
            },
        }
    },
    DIVISOR_SUM_FUNCTION: function (depth = 0) {
        return {
            id: 40,
            type: "wikiEntry",
            title: "Divisor sum function",
            altTitles: ["Divisor sum", "Number of divisors"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="n \in \mathbb Z^+" />. We define
                        <Block tex="\sigma_t(n) := \sum_{\substack{d \in \mathbb Z^+\\ d \mid n}} d^t." />
                        Let <Inline tex="n =: p_1^{x_1} \cdot p_2^{x_2} \cdot \dots \cdot p_k^{x_k}" />, where <Inline tex="p_i" /> are distinct primes and <Inline tex="x_i \in \mathbb Z^+" />.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            We have
                            <Block tex="\sigma_t(n) = \sum_{\substack{0 \leq y_i \leq x_i\\ \forall 1 \leq i \leq k}} \prod_{i=1}^k \left(p_i^{y_i}\right)^t = \prod_{i=1}^k \sum_{0 \leq y_i \leq x_i} \left(p_i^t\right)^{y_i}." />
                            For <Inline tex="t \neq 0," /> this yields
                            <Block tex="\sigma_t(n) = \prod_{i=1}^k \frac{p_i^{t(x_i+1)} - 1}{p_i^t - 1}," />
                            which we often use to compute <Inline tex="\sigma_t(n)." />
                        </>
                },
                {
                    subtitle: "Number of divisors",
                    content:
                        <>
                            We define <Inline tex="d(n) := \sigma_0(n)" /> to be the number of divisors of <Inline tex="n." /> Thus, we get
                            <Block tex="d(n) = \prod_{i=1}^k \sum_{0 \leq y_i \leq x_i} 1 = \prod_{i=1}^k (x_i + 1)." />
                        </>
                },
                {
                    subtitle: "Sum of divisors",
                    content:
                        <>
                            We define <Inline tex="\sigma(n) := \sigma_1(n)" /> to be the sum of divisors of <Inline tex="n" />. Thus, we get
                            <Block tex="\sigma(n) = \prod_{i=1}^k \frac{p_i^{x_i+1} - 1}{p_i - 1}." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            We have <Block tex="\frac{\sigma_t(n)}{\sigma_{-t}(n)} = n^t." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            <Inline tex="\sigma_t" /> is <WikiInfo data={WikiData.MULTIPLICATIVE_FUNCTION_NT} depth={depth}>multiplicative</WikiInfo>.
                        </>
                }
            ],
            examples: [],
            branch: BranchData.N,
            source: {
                url: "https://brilliant.org/wiki/primitive-roots/",
                webTitle: "Brilliant",
            },
        }
    },
    CONVEX_AND_CONCAVE_FUNCTIONS: function (depth = 0) {
        return {
            id: 41,
            type: "wikiEntry",
            title: "Convex and concave functions",
            altTitles: ["Concave functions", "Convex functions"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="f : \mathbb R \to \mathbb R" />. We call <Inline tex="f" /> convex if
                        <Block tex="\forall x, y \in \mathbb R, \lambda \in [0, 1] : f(\lambda x + (1 - \lambda)y) \leq \lambda f(x) + (1 - \lambda) f(y)." />
                        This means, <Inline tex="f" /> is convex if any segment, connecting two points on the graph of <Inline tex="f" />, contains no points below the graph of <Inline tex="f" />.<br />
                        We call <Inline tex="f" /> concave if <Inline tex="-f" /> is convex.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            If <Inline tex="f" /> is twice differentiable, then it is convex if and only if <Block tex="f''(x) \geq 0 \quad \forall x \in \mathbb R." /> Convexity is often defined like this and this is a practical method for checking for convexity.<p />
                        </>
                },
                {
                    content: <>Our definition for convexity is Jensen's inequality for two variables from which the general case of Jensen's inequality immediately follows.
                    </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            We can extend this definition for convexity on intervalls <Inline tex="I \subseteq \mathbb R" />.
                        </>
                }
            ],
            examples: [],
            branch: BranchData.A,
            source: {
                url: "https://en.wikipedia.org/wiki/Convex_function",
                webTitle: "Wikipedia",
            },
        }
    },
    MINIMUM_MAXIMUM_INFIMUM_SUPREMUM: function (depth = 0) {
        return {
            id: 41,
            type: "wikiEntry",
            title: "Minimum and maximum; Infimum and supremum",
            altTitles: ["Minimum", "Maximum", "Supremum", "Infimum"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="X \subseteq \mathbb R" />. If <Inline tex="x^* \in X" /> satisfies <Inline tex="x^* \leq x \quad \forall x \in X" />, we say that <Inline tex="x^*" /> is the minimum of <Inline tex="X" /> and write <Inline tex="\min X := x^*" />. We define the maximum <Inline tex="\max X" /> analogously. <p />
                        Let <Inline tex="X \subseteq \mathbb R" />. We define the infimum <Block tex="\inf X := \max\{a \in \mathbb R \mid \forall x \in X : a \leq x\}" /> as the maximal lower bound of <Inline tex="X" /> if <Inline tex="X \neq \varnothing" /> is bounded from below. We define <Inline tex="\inf \varnothing := + \infty" /> and <Inline tex="\inf X = - \infty" /> if <Inline tex="X" /> is not bounded from below. We define the supremum <Inline tex="\sup X" /> analogously for upper bounds. <p />
                        Let <Inline tex="M" /> be a set and <Inline tex="f : M \to \mathbb R." /> We write <Block tex="\inf f := \inf \{f(x) \mid x \in M\}" /> and <Block tex="\sup f := \sup \{f(x) \mid x \in M\}." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Not every set <Inline tex="X \subseteq \mathbb R" /> has a minimum. If a minimum exists, then it is unique because <Block tex="x_1, x_2 \in X, \forall x \in X: x_1 \leq x, x_2 \leq x \implies x_1 \leq x_2 \leq x_1 \implies x_1 = x_2." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            The infimum and supremum always exist because <Inline tex="\mathbb R" /> is complete.
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            <Block tex="X \quad \textnormal{has a minimum}" />
                            <Block tex="\iff \inf X \in X" />
                            <Block tex="\implies \inf X = \min X." />
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            Let <Inline tex="a, b \in \mathbb R, a \leq b" /> and <Inline tex="f : [a, b] \to \mathbb R" />. If <Inline tex="f" /> is continuous, <Inline tex="f" /> attains its maximum, namely <Block tex="\exists x^* \in [a, b] : f(x^*) = \sup f = \max f." />
                        </>
                }
            ],
            examples: [],
            branch: BranchData.A,
            source: {
                url: "https://en.wikipedia.org/wiki/Infimum_and_supremum",
                webTitle: "Wikipedia",
            },
        }
    },
    MULTIPLICATIVE_FUNCTION_NT: function (depth = 0) {
        return {
            id: 42,
            type: "wikiEntry",
            title: "Multiplicative function (number theory)",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We call a function <Inline tex="f : \mathbb N \to \mathbb Z" /> (number theoretically) multiplicative if
                        <Block tex="\forall a, b \in \mathbb N, \gcd(a, b) = 1 : f(ab) = f(a)f(b)." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            Many interesting functions (<WikiInfo data={WikiData.DIVISOR_SUM_FUNCTION} depth={depth}>Divisor sum function</WikiInfo>, <WikiInfo data={WikiData.EULERS_TOTIENT_FUNCTION} depth={depth}>Euler's totient function</WikiInfo>, <WikiInfo data={WikiData.MOEBIUS_FUNCTION} depth={depth}>Möbius function</WikiInfo> and also the exponentials of <WikiInfo depth={depth} data={WikiData.P_ADIC_VALUATION}><Inline tex="\nu_p" /></WikiInfo> and of the <WikiInfo depth={depth} data={WikiData.PRIME_OMEGA_FUNCTION}>prime omega functions</WikiInfo>)
                            are multiplicative.
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            Let <Inline tex="f" /> be a multiplicative function. Then,
                            <Block tex="F(n) := \sum_{d \mid n} f(d)" />
                            is also multiplicative.
                        </>
                }
            ],
            examples: [],
            branch: BranchData.N,
        }
    },
    MOEBIUS_FUNCTION: function (depth = 0) {
        return {
            id: 43,
            type: "wikiEntry",
            title: "Möbius function",
            altTitles: ["Moebius function, Moebius inversion"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="n \in \mathbb Z^+" />. We define
                        <Block tex="\mu(n) := \begin{cases} 0  & \textnormal{if there is a prime $p$ with $p^2 \mid n$,}\\ (-1)^k & \textnormal{if $n = p_1 \dots p_k$, where $p_1, \dots, p_k$ are distinct primes.}\end{cases}" />
                        <Inline tex="\mu" /> is the Möbius function.
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content:
                        <>
                            <Inline tex="\mu" /> is <WikiInfo data={WikiData.MULTIPLICATIVE_FUNCTION_NT} depth={depth}>multiplicative</WikiInfo>.
                        </>
                },
                {
                    subtitle: undefined,
                    content:
                        <>
                            We have
                            <Block tex="\sum_{d \mid n} \mu(d) = \delta_{n1} = \begin{cases} 1 & \textnormal{if $n = 1$,}\\ 0 & \textnormal{otherwise.} \end{cases}" />
                            This is a special case of the following identity with <Inline tex="f(n) = \delta_{n1}" />.
                        </>
                },
                {
                    subtitle: "Möbius inversion formula",
                    content:
                        <>
                            For <Inline tex="f" /> multiplicative and <Inline tex="F(n) := \sum_{d \mid n} f(d)" />, we have
                            <Block tex="f(n) = \sum_{d \mid n} \mu(d) F\left(\frac{n}{d}\right)." />
                        </>
                },
            ],
            examples: [],
            branch: BranchData.N,
        }
    },
    PRIME_OMEGA_FUNCTION: function (depth = 0) {
        return {
            id: 44,
            type: "wikiEntry",
            title: "Prime omega function",
            altTitles: ["Number of prime divisors"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="n \in \mathbb Z^+" /> and let <Inline tex="n = p_1^{x_1} p_2^{x_2} \dots p_k^{x_k}" />, where <Inline tex="p_1 < p_2 < \dots < p_k" /> are primes and <Inline tex="x_i \in \mathbb Z^+" />. We define
                        <Block tex="\omega(n) := k" />
                        and
                        <Block tex="\Omega(n) := x_1 + x_2 + \dots + x_k." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content: <>
                        For coprime <Inline tex="m, n \in \mathbb Z^+" />, we have <Block tex="\omega(mn) = \omega(m) + \omega(n)." /> Furthermore, <Block tex="\Omega(mn) = \Omega(m) + \Omega(n)" /> always holds. Thus, <Inline tex="2^\omega" /> and <Inline tex="2^\Omega" /> are <WikiInfo depth={depth} data={WikiData.MULTIPLICATIVE_FUNCTION_NT}>multiplicative</WikiInfo>.
                    </>
                }
            ],
            examples: [],
            branch: BranchData.N,
            source: {
                url: "https://en.wikipedia.org/wiki/Prime_omega_function",
                webTitle: "Wikipedia",
            },
        }
    },
    NUMBER_OF_PRIMES: function (depth = 0) {
        return {
            id: 45,
            type: "wikiEntry",
            title: "Number of primes up to n",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We define
                        <Block tex="\pi(n) := |\{p \mid p \in \mathbb Z^+, p \,\,\textnormal{prime}, p \leq n\}|" />
                        for <Inline tex="n \in \mathbb Z^+" />. <br />
                        <WikiInfo depth={depth} data={WikiData.EUCLIDS_THEOREM}>Euclid's theorem</WikiInfo> states that <Inline tex="\pi" /> is unbounded. Moreover, we know that for large <Inline tex="n" />, we have
                        <Block tex="\pi(n) \approx \frac{n}{\log n}." />
                        More precisely,
                        <Block tex="\lim_{n \to \infty} \frac{\pi(n)}{\frac{n}{\log n}} = 1." />
                    </>
            },
            proofs: [],
            notes: [],
            examples: [],
            branch: BranchData.N,
            source: {
                url: "https://en.wikipedia.org/wiki/Prime-counting_function",
                webTitle: "Wikipedia",
            },
        }
    },
    GCD_AND_LCM: function (depth = 0) {
        return {
            id: 46,
            type: "wikiEntry",
            title: "The gcd and the lcm",
            altTitles: ["gcd", "lcm"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="m, n \in \mathbb Z \backslash \{0\}" />. We define the greatest common divisor
                        <Block tex="\gcd(m, n) = \max\{d \mid d \in \mathbb Z^+, d \mid m, d \mid n\}." />
                        We define the least common multiple
                        <Block tex="\mathrm{lcm}(m, n) = \min\{k \mid k \in \mathbb Z^+, m \mid k, n \mid k\}." />
                        Naturally, we also define
                        <Block tex="\gcd(m, 0) = m" />
                        and
                        <Block tex="\mathrm{lcm}(m, 0) = 0." />
                    </>
            },
            proofs: [],
            notes: [
                {
                    subtitle: undefined,
                    content: <>
                        We have <Block tex="\gcd(m, n) \cdot \mathrm{lcm}(m, n) = m \cdot n." />
                    </>
                },
                {
                    subtitle: undefined,
                    content: <>
                        A short proof for the previous note follows from this useful representation of the <Inline tex="\gcd" /> and the <Inline tex="\mathrm{lcm}" /> using <WikiInfo depth={depth} data={WikiData.P_ADIC_VALUATION}><Inline tex="\nu_p" /></WikiInfo>: We have
                        <Block tex="\gcd(a, b) = \prod_{p \,\textnormal{prime}} p^{\min\{\nu_p(a), \nu_p(b)\}}" />
                        and
                        <Block tex="\mathrm{lcm}(a, b) = \prod_{p \,\textnormal{prime}} p^{\max\{\nu_p(a), \nu_p(b)\}}." />
                    </>
                },
                {
                    subtitle: "Euklid's algorithm and Bézout's identity",
                    content: <>
                        We can use <WikiInfo depth={depth} data={WikiData.EUCLIDS_ALGORITHM}>euclid's algorithm</WikiInfo> to compute the <Inline tex="\gcd" /> and thus the <Inline tex="\mathrm{lcm}." />
                    </>
                }
            ],
            examples: [],
            branch: BranchData.N,
            source: {
                url: "https://en.wikipedia.org/wiki/Prime-counting_function",
                webTitle: "Wikipedia",
            },
        }
    },
    CAUCHY_FES: function (depth = 0) {
        return {
            id: 47,
            type: "wikiEntry",
            title: "The cauchy equations",
            statement: {
                content: <>
                    The Cauchy equation is
                    <Block tex="f(x + y) = f(x) + f(y)." />
                    For <Inline tex="f : \mathbb N \to \mathbb N" /> or <Inline tex="f : \mathbb Q \to \mathbb Q" /> this directly implies <Block tex="f(x) = x \cdot f(1) \quad \forall x" /> by induction.
                </>
            },
            notes: [{
                subtitle: <><Inline tex="f : \mathbb R \to \mathbb R" /></>,
                content: <>
                    In this case, there exists solutions other than <Block tex="f(x) = x \cdot f(1) \quad \forall x \in \mathbb R." /> But their graphs are all dense in the <Inline tex="x" />-<Inline tex="y" />-plane, i.e. for such a solution <Inline tex="f" />, we have <Block tex="\forall x_0, y_0 \in \mathbb R \forall \varepsilon > 0 \exists x \in \mathbb R : \sqrt{(x_0 - x)^2 + (y_0 - f(x))^2} < \varepsilon." /> Therefore, to prove <Block tex="f(x) = x \cdot f(1) \quad \forall x \in \mathbb R," /> we only have to show that there is a disc in the <Inline tex="x" />-<Inline tex="y" />-plane with which the graph of <Inline tex="f" /> has an empty intersection. This property is implied by many conditions like
                    <ul>
                        <li>
                            continuity,
                        </li>
                        <li>
                            monotony,
                        </li>
                        <li>
                            boundedness,
                        </li>
                        <li>
                            positivity.
                        </li>
                    </ul>
                    Hence, <Inline tex="f : \mathbb R \to \mathbb R" /> satisfying the Cauchy equation and one of the above conditions has to be of the form <Block tex="f(x) = x \cdot f(1) \quad \forall x \in \mathbb R." />
                </>
            },
            {
                subtitle: "Cauchy-like equations",
                content: <>
                    We note that the following equations reduce to the Cauchy equation by suitable substitutions:
                    <ul>
                        <li>
                            Let <Inline tex="f : \mathbb R \to (0, \infty)" /> satisfy <Inline tex="f(x + y) = f(x)f(y)" />. Then, <Inline tex="g(x) := \mathrm{log} f(x)" /> satisfies the Cauchy equation.
                        </li>
                        <li>
                            Let <Inline tex="f : (0, \infty) \to \mathbb R" /> satisfy <Inline tex="f(xy) = f(x) + f(y)" />. Then, <Inline tex="g(x) := f(a^x)" /> satisfies the Cauchy equation.
                        </li>
                        <li>
                            Let <Inline tex="f : (0, \infty) \to (0, \infty)" /> satisfy <Inline tex="f(xy) = f(x)f(y)" />. Then, <Inline tex="g(x) := \mathrm{log} f(a^x)" /> satisfies the Cauchy equation.
                        </li>
                    </ul>
                </>
            }],
            branch: BranchData.A,
            source: {
                url: "https://imomath.com/index.cgi?page=cauchyFunctionalEquations",
                webTitle: "IMO Compendium",
            },
        }
    },
    BIPARTITE_GRAPH: function (depth = 0) {
        return {
            id: 48,
            type: "wikiEntry",
            title: "Bipartite graph",
            statement: {
                content: <>
                    We call an <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>undirected graph</WikiInfo> <Inline tex="G = (V, E)" /> bipartite if there exists a bipartition <Inline tex="A \cup B = V, A \cap B = \varnothing" /> such that any edge <Inline tex="e \in E" /> has exactly one endpoint in <Inline tex="A" /> and one endpoint in <Inline tex="B" />.
                </>
            },
            notes: [{
                content: <>
                    <Inline tex="G" /> is bipartite if and only if <Inline tex="G" /> contains no cycle of odd length. <p />
                    <Comment><PdfLink data={PdfData.THEOREM_1} /> is a full video dedicated to this lemma. </Comment>
                </>
            }],
            branch: BranchData.C,
        }
    },
    BINOMIAL_THEOREM: function (depth = 0) {
        return {
            id: 49,
            type: "wikiEntry",
            title: "Binomial theorem",
            statement: {
                content: <>
                    Let <Inline tex="n \in \mathbb N, x, y \in \mathbb R" />. We have
                    <Block tex="(x + y)^n = \sum_{k=0}^n \binom{n}{k} x^k y^{n-k}," />
                    where <Inline tex="\binom{n}{k}" /> is a <WikiInfo depth={depth} data={WikiData.BINOMIAL_COEFFICIENT}>binomial coefficient</WikiInfo>.
                </>
            },
            proofs: [{
                content: <>
                    We prove this by induction. For <Inline tex="n = 0" />, the statement is clear. Now, let <Inline tex="n \geq 1" /> and assume the statement holds for <Inline tex="n - 1" />. Therefore,
                    <Block tex="(x + y)^n = (x + y) (x + y)^{n-1} = (x + y) \sum_{k=0}^{n-1} \binom{n-1}{k} x^k y^{n-1-k} = \sum_{k=0}^n \underbrace{\left(\binom{n-1}{k-1} + \binom{n-1}{k}\right)}_{=\binom{n}{k}} x^ky^{n-k}," />
                    which is what we wanted to show.
                </>
            }],
            notes: [{
                content: <>
                    An important special case is <Inline tex="x = y = 1" />:
                    <Block tex="\sum_{k=0}^n \binom{n}{k} = 2^n." />
                </>
            },
            {
                content: <>
                    Setting <Inline tex="y = 1" /> and differentiating with respect to <Inline tex="x" /> yields
                    <Block tex="n (1 + x)^{n-1} = \sum_{k=1}^n k \binom{n}{k} x^{k-1}." />
                    Setting <Inline tex="x = 1" /> yields a new identity:
                    <Block tex="\sum_{k=1}^n k\binom{n}{k} = n 2^{n-1}." />
                </>
            }],
            branch: BranchData.C,
        }
    },
    HOCKEY_STICK: function (depth = 0) {
        return {
            id: 50,
            type: "wikiEntry",
            title: "Hockey-stick identity",
            statement: {
                content: <>
                    Let <Inline tex="n, k \in \mathbb N, n \geq k" />. The hockey-stick identity is
                    <Block tex="\sum_{m = k}^n \binom{m}{k} = \binom{n + 1}{k + 1}." />
                </>
            },
            proofs: [{
                content: <>
                    Induction on <Inline tex="n" />.
                </>
            }],
            notes: [],
            branch: BranchData.C,
        }
    },
    BINOMIAL_COEFFICIENT: function (depth = 0) {
        return {
            id: 51,
            type: "wikiEntry",
            title: "Binomial coefficient",
            statement: {
                content: <>
                    Let <Inline tex="n \in \mathbb N" />. We define the binomial coefficient
                    <Block tex="\binom{n}{k} := \begin{cases} 0 & \textnormal{for $k > n$ or $k < 0$,}\\ \frac{n!}{k!(n-k)!} \quad & \textnormal{otherwise.}\end{cases}" />
                </>
            },
            notes: [{
                content: <>
                    <Inline tex="\binom{n}{k}" /> is the number of ways to choose a set of <Inline tex="k" /> objects from an <Inline tex="n" />-element set. Importantly, this implies <Inline tex="\binom{n}{k} \in \mathbb Z_{\geq 0}." />
                </>
            },
            {
                content: <>
                    Using the combinatoric interpretation or the definition, we can prove the following identities:
                    <Block tex="\binom{n}{k} = \binom{n}{n-k}," />
                    <Block tex="\binom{n-1}{k-1} + \binom{n-1}{k} = \binom{n}{k}," />
                    <Block tex="\binom{n}{k} = \frac{n}{k} \binom{n-1}{k-1}." />
                    The second identity together with <Inline tex="\binom{0}{k} = \begin{cases} 1 \quad & \textnormal{for $k = 0$,}\\ 0 \quad & \textnormal{otherwise,} \end{cases}" /> defines the binomial coefficients uniquely.
                </>
            },
            {
                content: <>
                    Many combinatorial identities include binomial coefficients. Some elementary ones are the <WikiInfo depth={depth} data={WikiData.BINOMIAL_THEOREM}>binomial theorem</WikiInfo> and the <WikiInfo depth={depth} data={WikiData.HOCKEY_STICK}>hockey-stick identity</WikiInfo>. Another example is
                    <Block tex="\sum_{k=0}^n \binom{n}{k}^2 = \binom{2n}{n}" />
                    [see <WikiInfo depth={depth} data={WikiData.DOUBLE_COUNTING}>double counting</WikiInfo>].
                </>
            },
            {
                content: <>
                    More generally, we can define
                    <Block tex="\binom{n}{k} := \prod_{i = 1}^k \frac{n + 1 - i}{i} \quad \forall n \in \mathbb R, k \in \mathbb Z_{\geq 0}." />
                    For <Inline tex="k > n" /> and <Inline tex="n \not \in \mathbb Z," /> <Inline tex="\binom{n}{k}" /> is not <Inline tex="0" /> and may even attain negative values.
                </>
            }],
            branch: BranchData.C,
        }
    },
    CHINESE_REMAINDER_THEOREM: function (depth = 0) {
        return {
            id: 52,
            type: "wikiEntry",
            title: "Chinese remainder theorem",
            statement: {
                content: <>
                    Let <Block tex="m, n \in \mathbb Z_{\geq 0}, \gcd(m, n) = 1." /> The chinese remainder theorem states that there exists a bijection
                    <Block tex="\varphi: \{1, \dots, m\} \times \{1, \dots, n\} \to \{1, \dots, mn\}" />
                    such that <Block tex="\varphi(a, b) \equiv a \mod m, \varphi(a, b) \equiv b \mod n." />
                </>
            },
            notes: [
                {
                    subtitle: "An alternate phrasing",
                    content: <>
                        The surjectivity of <Inline tex="\varphi" /> implies that for given residue classes <Inline tex="a \mod m" /> and <Inline tex="b \mod n" />, we can find a residue class <Inline tex="c \mod mn" /> such that <Block tex="c \equiv a \mod m, c \equiv b \mod n." />
                        The injectivity of <Inline tex="\varphi" /> implies that <Inline tex="c \mod mn" /> is unique with this proprety.
                        <Comment>
                            We see that <Inline tex="\gcd(m, n) = 1" /> is necessary for the existence of <Inline tex="c" />.
                        </Comment>
                    </>,
                },
                {
                    content: <>
                        We can inductively extend result: Let <Inline tex="n_1, \dots, n_k" /> be pairwise coprime moduli and let <Inline tex="a_i \mod n_i" /> be residue classes for <Inline tex="1 \leq i \leq k." /> Then, there exists a unique residue class <Inline tex="c \mod n_1 \cdots n_k" /> with
                        <Block tex="c \equiv a_1 \mod n_1" />
                        <Block tex="\vdots" />
                        <Block tex="c \equiv a_k \mod n_k." />
                    </>
                },
                {
                    content: <>
                        We often use this result for distinct primes <Inline tex="m, n." />
                    </>
                }
            ],
            branch: BranchData.N,
        }
    },
    TRIGONOMETRIC_FORMULAS: function (depth = 0) {
        return {
            id: 52,
            type: "wikiEntry",
            title: "Trigonometric formulas",
            altTitles: ["Angle addition formulas"],
            statement: {
                content: <>
                    For all <Inline tex="\alpha, \beta \in \mathbb R," />
                    <Block tex="\sin^2 \alpha + \cos^2 \alpha = 1," />
                    <Block tex="\sin (\alpha + \beta) = \sin \alpha \cos \beta + \cos \alpha \sin \beta," />
                    and
                    <Block tex="\cos (\alpha + \beta) = \cos \alpha \cos \beta - \sin \alpha \sin \beta." />
                </>
            },
            notes: [{
                content: <>
                    You don't need to (we think) remember more such formulas.
                </>
            }, {
                content: <>
                    Know that we can derive from that the half-, double-, triple-, ...-angle formulas for <Inline tex="\sin" /> and <Inline tex="\cos" /> and all analogous formulas for <Inline tex="\tan" />. Notably, <Inline tex="\cos(n \cdot X)" /> and <Inline tex="\sin(n \cdot X) / \sin(X)" /> are polynomials of <Inline tex="\cos X" /> of degrees <Inline tex="n" /> and <Inline tex="n-1" />.
                </>
            }, {
                content: <>
                    Moreover, we can deduce from that
                    <Block tex="\sin \alpha - \sin \beta = 2\cos \frac{\alpha + \beta}{2} \sin \frac{\alpha - \beta}{2}," />
                    and
                    <Block tex="\cos \alpha - \cos \beta = -2\sin \frac{\alpha + \beta}{2} \sin \frac{\alpha - \beta}{2}." />
                </>
            }, {
                subtitle: "Using complex numbers",
                content: <>
                    Complex numbers are very strong for deriving (remembering) complex angle addition formulas. We can derive the <Inline tex="\tan" /> angle-addition formula from the formulas for <Inline tex="\sin" /> and <Inline tex="\cos" />. But we can also verify all three directly using complex numbers.<p />
                    For example, let
                    <Block tex="\alpha + \beta \not\equiv \pi / 2 \mod \pi," />
                    and let
                    <Block tex="z = (1 + i \cdot \tan \alpha) \cdot (1 + i \cdot \tan \beta)." />
                    We have
                    <Block tex="\arg(z) \equiv \alpha + \beta \mod 2\pi." />
                    Hence, <Inline tex="\mathrm{Re}(z) \neq 0." /> Thus,
                    <Block tex="\tan (\alpha + \beta) = \frac{\mathrm{Im}(z)}{\mathrm{Re}(z)} = \frac{\tan \alpha + \tan \beta}{1 - \tan \alpha \tan \beta}." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    COMPLEX_NUMBERS: function (depth = 0) {
        return {
            id: 53,
            type: "wikiEntry",
            title: "The complex numbers",
            altTitles: ["Complex conjugate", "Real part", "Imaginary part"],
            statement: {
                content: <>
                    We define the complex numbers <Inline tex="(\mathbb C, +, \cdot)" />, where <Block tex="\mathbb C = \{a + bi \mid a, b \in \mathbb R\}" /> and for <Inline tex="a,b,c,d \in \mathbb R" />
                    <Block tex="(a+bi) + (c+di) = (a+b) + (c+d)i," />
                    <Block tex="(a+bi)(c+di) = (ac - bd) + (ad + bc)i." />
                </>
            },
            notes: [{
                content: <>
                    We have <Inline tex="i \cdot i = -1." />
                </>
            }, {
                content: <>
                    We associate <Inline tex="a + 0i \in \mathbb C" /> with <Inline tex="a \in \mathbb R" /> and thus write <Inline tex="\mathbb R \subseteq \mathbb C" />.<br />
                    Since <Inline tex="i \cdot i = -1 < 0" />, we have <Inline tex="i \in \mathbb C \backslash \mathbb R" /> and thus <Inline tex="\mathbb R \neq \mathbb C." />
                </>
            }, {
                content: <>
                    Addition and multiplication are associative
                    <Block tex="z_1 + (z_2 + z_3) = (z_1 + z_2) + z_3," />
                    <Block tex="z_1 \cdot (z_2 \cdot z_3) = (z_1 \cdot z_2) \cdot z_3," />
                    commutative
                    <Block tex="z_1 + z_2 = z_2 + z_1," />
                    <Block tex="z_1 \cdot z_2 = z_2 \cdot z_1," />
                    and distributive
                    <Block tex="z_1 \cdot (z_2 + z_3) = z_1 \cdot z_2 + z_1 \cdot z_3." />
                </>
            }, {
                subtitle: "Complex conjugate",
                content: <>
                    For <Inline tex="a, b \in \mathbb R" />, we define the complex conjugate of <Inline tex="z = a + bi" /> as <Block tex="\bar z = a - bi." />
                    By using the definition for complex multiplication directly, we obtain
                    <Block tex="z \bar z \in \mathbb R_{\geq 0}," />
                    <Block tex="\overline {zw} = \bar z \bar w." />
                </>
            }, {
                subtitle: "Real and imaginary part",
                content: <>
                    For <Inline tex="z \in \mathbb C" />, we define the real part <Inline tex="\mathrm{Re}(z)" /> and the imaginary part <Inline tex="\mathrm{Im}(z)" /> by
                    <Block tex="\mathrm{Re}(z) = \frac{z + \bar z}{2}," />
                    <Block tex="\mathrm{Im}(z) = \frac{z - \bar z}{2i}." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    ABSOLUTE_VALUE_COMPLEX: function (depth = 0) {
        return {
            id: 54,
            type: "wikiEntry",
            title: "Absolute value (complex numbers)",
            altTitles: [],
            statement: {
                content: <>
                    For <Inline tex="z \in" /> <WikiInfo depth={depth} data={WikiData.COMPLEX_NUMBERS}><Inline tex="\mathbb C" /></WikiInfo>, we define the absolute value of <Inline tex="z" /> as
                    <Block tex="|z| = \sqrt{z\bar z} = \sqrt{(\mathrm{Re}(z))^2 + (\mathrm{Im}(z))^2}." />
                </>
            },
            notes: [{
                subtitle: "Correspondence in the complex plane",
                content: <>
                    By the Pythagorean theorem, <Inline tex="|z|" /> is the distance from the origin to <Inline tex="z" /> in the <WikiInfo depth={depth} data={WikiData.COMPLEX_PLANE}>complex plane</WikiInfo>.
                </>
            }, {
                subtitle: "Multiplicativity",
                content: <>
                    We have
                    <Block tex="|zw| = \sqrt{zw \overline{zw}} = \sqrt{z\bar z} \sqrt{w \bar w} = |z| |w|." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    COMPLEX_PLANE: function (depth = 0) {
        return {
            id: 55,
            type: "wikiEntry",
            title: "Complex plane",
            altTitles: ["Unit circle (complex)"],
            statement: {
                content: <>
                    The complex plane is the plane with a cartesian coordinate system, where we associate the point <Inline tex="(a, b)" /> with the <WikiInfo depth={depth} data={WikiData.COMPLEX_NUMBERS}>complex number</WikiInfo> <Inline tex="a + bi." />
                </>
            },
            notes: [{
                content: <>
                    We also call the <Inline tex="x" />-axis the real axis. It corresponds to <Inline tex="\mathbb R." /> <br />
                    We also call the <Inline tex="y" />-axis the imaginary axis. It corresponds to <Inline tex="\{bi \mid b \in \mathbb R\}." /> <br />
                </>
            }, {
                subtitle: "Unit circle",
                content: <>
                    We define the unit circle <Inline tex="\{z \in \mathbb C \mid |z| = 1\}" /> as the circle with radius <Inline tex="1" /> centered at the origin of the complex plane.
                </>
            }, {
                subtitle: <><Inline tex="+, \cdot" /> in the complex plane</>,
                content: <>
                    <Inline tex="+" /> corresponds to component-wise addition in the complex plane. <br />
                    For interpreting <Inline tex="\cdot" />, we use the fact that we can interpret the <WikiInfo depth={depth} data={WikiData.ABSOLUTE_VALUE_COMPLEX}>absolute value</WikiInfo> and <WikiInfo depth={depth} data={WikiData.COMPLEX_ARGUMENT}>argument</WikiInfo> of a complex number in the complex plane and we know the behavior of both functions under multiplication. Therefore, <Inline tex="z \cdot w = w \cdot z" /> corresponds to the vector, we obtain from stretching <Inline tex="z" /> by <Inline tex="|w|" /> and rotating it by <Inline tex="\mathrm{arg}(w)" />.
                </>
            }],
            branch: BranchData.A,
        }
    },
    COMPLEX_ARGUMENT: function (depth = 0) {
        return {
            id: 56,
            type: "wikiEntry",
            title: "Argument (complex numbers)",
            altTitles: ["Complex angle"],
            statement: {
                content: <>
                    We define <Inline tex="\mathrm{arg}(0) = 0" /> and for <Inline tex="z \in \mathbb C \backslash \{0\}," /> we define
                    <Block tex="\mathrm{arg}(z) := \varphi \in (-\pi, \pi] : \frac{z}{|z|} = \cos \varphi + i \sin \varphi." />
                </>
            },
            notes: [{
                subtitle: "The argument is well-defined",
                content: <>
                    Such a unique <Inline tex="\varphi" /> exists because the function
                    <Block tex="f: (-\pi, \pi] \to \{(a, b) \in \mathbb R^2 \mid a^2 + b^2 = 1\}," />
                    <Block tex="\theta \mapsto (\cos \theta, \sin \theta)" />
                    is bijective.
                </>
            }, {
                content: <>
                    For <Inline tex="z, w \in \mathbb C \backslash \{0\}," /> we have
                    <Block tex="\mathrm{arg}(zw) \equiv \mathrm{arg}(z) + \mathrm{arg}(w) \mod 2\pi," />
                    where
                    <Block tex="x \equiv y \mod 2 \pi \iff \exists k \in \mathbb Z : x - y = k \cdot 2\pi." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    COMPLEX_POLAR_COORDINATES: function (depth = 0) {
        return {
            id: 57,
            type: "wikiEntry",
            title: "Polar coordinates (complex numbers)",
            altTitles: [],
            statement: {
                content: <>
                    We define <Block tex="e(\varphi) = \cos \varphi + i \sin \varphi." />
                    For <Inline tex="z \in" /> <WikiInfo depth={depth} data={WikiData.COMPLEX_NUMBERS}><Inline tex="\mathbb C" /></WikiInfo>, there is a unique pair <Block tex="(r, \varphi) \in \left(\mathbb R^+ \times (-\pi, \pi]\right) \cup \{(0, 0)\}" /> such that
                    <Block tex="z = r \cdot e(\varphi)." />
                    In this case, we have <Inline tex="(r, \varphi) = (|z|, \mathrm{arg}(z))" /> and we call this the polar coordinates of <Inline tex="z." />
                </>
            },
            notes: [{
                content: <>
                    The <WikiInfo depth={depth} data={WikiData.TRIGONOMETRIC_FORMULAS}>angle-addition formulas</WikiInfo> for <Inline tex="\sin" /> and <Inline tex="\cos" /> imply
                    <Block tex="e(\varphi_1 + \varphi_2) = e(\varphi_1) \cdot e (\varphi_2)." />
                </>
            }, {
                subtitle: "Multiplication and exponentiation",
                content: <>
                    Therefore, we conclude for <Block tex="z_1 = r_1 e(\varphi_1), z_2 = r_2 e(\varphi_2)" /> that
                    <Block tex="z_1z_2 = r_1 r_2 e(\varphi_1 + \varphi_2)." />
                    We inductively get for <Inline tex="k \in \mathbb Z" /> that
                    <Block tex="z_1^k = r_1^k e(k \varphi_1)." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    FUNDAMENTAL_THEOREM_OF_ALGEBRA: function (depth = 0) {
        return {
            id: 58,
            type: "wikiEntry",
            title: "Fundamental theorem of algebra",
            altTitles: [],
            statement: {
                content: <>
                    For any polynomial <Block tex="P(X) = \sum_{i=0}^n a_i X^i" /> with <WikiInfo depth={depth} data={WikiData.COMPLEX_NUMBERS}>complex coefficients</WikiInfo> of degree <Inline tex="n > 0" />, there exists a <Inline tex="z \in \mathbb C" /> such that <Inline tex="P(z) = 0." />
                </>
            },
            proofs: [{
                subtitle: "A sketch for one proof",
                content: <>
                    Since <Inline tex="|P(z)|" /> becomes large for sufficiently large <Inline tex="|z|" />, we can find a <Inline tex="R \in \mathbb R^+" /> such that
                    <Block tex="\inf \{P(z) \mid z \in \mathbb C\} = \inf \{P(z) \mid z \in \mathbb C, |z| \leq R\}," />
                    where <Inline tex="\inf" /> denotes the  <WikiInfo depth={depth} data={WikiData.MINIMUM_MAXIMUM_INFIMUM_SUPREMUM}>infimum</WikiInfo>. Since <Inline tex="\{z \in \mathbb C \mid |z| \leq R\}" /> is compact (it contains its boundary) and <Inline tex="|P|" /> is continuos, <Inline tex="|P|" /> attains its minimum on that set. Thus, there exists a <Inline tex="z_0 \in \mathbb C, |z_0| \leq R" /> such that <Block tex="P(z_0) = \min \{P(z) \mid z \in \mathbb C, |z| \leq R\} = \min\{P(z) \mid z \in \mathbb C\}." /> If <Inline tex="P(z_0) = 0" /> we are done. So, let us assume that <Inline tex="P(z_0) \neq 0" /> and thus <Block tex="\frac{P(z_0 + X)}{P(z_0)} =: \sum_{i=0}^n b_i X^i =: Q(X)" /> is a polynomial of degree <Inline tex="n > 0." /> We have <Inline tex="Q(0) = 1" /> and <Inline tex="|Q(z)| \geq 1 \quad \forall z \in \mathbb C." /> Moreover, there exists a smallest <Inline tex="i_0 > 0" /> such that <Inline tex="b_{i_0} \neq 0." /> Using polar coordinates, we can see that there is a <Inline tex="w \in \mathbb C" /> such that <Inline tex="w^{i_0} = -c_{i_0}" />. For small enough <Inline tex="\varepsilon > 0," /> we can prove that <Inline tex="|P(\varepsilon w)| < 1," /> a contradiction.
                </>
            }],
            notes: [{
                content: <>
                    Using induction, we conclude that we can find a factorization in linear terms over <Inline tex="\mathbb C." /> Namely, there exist <Inline tex="z_1, \dots, z_n \in \mathbb C" /> such that
                    <Block tex="P(X) = a_n\prod_{i=1}^n (X - z_i)." />
                </>
            }, {
                content: <>
                    The statement remains true if we consider a polynomial with coefficients <Inline tex="a_i \in \mathbb R \subseteq \mathbb C." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    ROOTS_OF_UNITY: function (depth = 0) {
        return {
            id: 59,
            type: "wikiEntry",
            title: "Roots of unity (complex numbers)",
            altTitles: [],
            statement: {
                content: <>
                    We call all <WikiInfo depth={depth} data={WikiData.COMPLEX_NUMBERS}>complex</WikiInfo> roots <Inline tex="e( k \cdot 2 \pi / n)" /> of the polynomial <Inline tex="X^n - 1" /> the <Inline tex="n" />-th roots of unity. The <Inline tex="n" /> distinct roots of unity <Block tex="\omega_n^1, \omega_n^2, \dots, \omega_n^n = 1" /> are the powers of <Inline tex="\omega_n := e(2 \pi / n)." />
                </>
            },
            notes: [{
                content: <>
                    Therefore, we can write
                    <Block tex="X^n - 1 = \prod_{i=1}^n (X - \omega_n^i)." />
                </>
            }, {
                subtitle: "Linear combinations",
                content: <>
                    Let us consider the case that <Inline tex="p := n" /> is prime. We know that the <WikiInfo depth={depth} data={WikiData.CYCLOTOMIC_POLYNOMIALS}>cyclotomic polynomial</WikiInfo> <Inline tex="\varphi_p" /> is <WikiInfo depth={depth} data={WikiData.IRREDUCIBLE_POLYNOMIAL}>irreducible</WikiInfo> and that
                    <Block tex="\varphi_p(\omega_p) = 0." />
                    Using polynomial division, we can conclude that any non-constant polynomial <Inline tex="P" /> with <Inline tex="P(\omega_p) = 0" /> has degree <Block tex="\deg P \geq \deg \varphi_p = p - 1" /> because we must have <Block tex="\varphi_p \mid P." /> This tells us that for a linear combination
                    <Block tex="\lambda_0 \omega_p^0 + \lambda_1 \omega_p^1 + \dots + \lambda_{p-1} \omega_p^{p-1}" />
                    is equal to <Inline tex="0" /> if and only if
                    <Block tex="\lambda_0 = \lambda_1 = \dots = \lambda_{p-1}." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    ROOTS_OF_UNITY_FILTER: function (depth = 0) {
        return {
            id: 60,
            type: "wikiEntry",
            title: "Roots of unity filter",
            altTitles: [],
            statement: {
                content: <>
                    Let <Block tex="P(X) = \sum_{i=0}^n a_i X^i" /> be a polynomial and let <Inline tex="m \in \mathbb Z^+" />. Let <Inline tex="\omega_m = e(2 \pi / m)" /> be an <Inline tex="m" />-th <WikiInfo depth={depth} data={WikiData.ROOTS_OF_UNITY}>root of unity</WikiInfo>. We have
                    <Block tex="\sum_{m \mid i} a_i = \frac{1}{m} \sum_{j=1}^m P(\omega_m^j)." />
                </>
            },
            proofs: [{
                content: <>
                    Note that <Block tex="X^m - 1 = \prod_{j=1}^m (X - \omega_m^j)." /> Vieta's theorem on the <Inline tex="m - 1" />-st coefficient tells us
                    <Block tex="\sum_{j=1}^m \omega_m^j = \begin{cases} m & \textnormal{for $\omega_m = 1$,}\\ 0 & \textnormal{otherwise.} \end{cases}" />
                    Let <Inline tex="k \in \mathbb Z^+" /> and <Inline tex="d := \mathrm{gcd}(k, m)" />. Because <Inline tex="k / d" /> and <Inline tex="m / d" /> are coprime, we have
                    <Block tex="\left\{1 \cdot \frac{k}{d}, 2 \cdot \frac{k}{d}, \dots, \frac{m}{d} \cdot \frac{k}{d}\right\} \equiv \left\{1, 2, \dots, \frac{m}{d}\right\} \mod \frac{m}{d}." />
                    Thus,
                    <Block tex="\sum_{j=1}^m \omega_m^{jk} = d \sum_{j=1}^{m / d} \omega_{m / d}^{j(k / d)} = d \sum_{j=1}^{m/d} \omega_{m / d}^j = \begin{cases}d & \textnormal{for $m / d = 1$,}\\ 0 &\textnormal{otherwise}\end{cases} = \begin{cases}m & \textnormal{for $m \mid k$,}\\ 0 &\textnormal{otherwise.}\end{cases}" />
                    Therefore, we have
                    <Block tex="\sum_{j=1}^m P(\omega_m^j) = \sum_{i=0}^n a_i \left(\sum_{j=1}^m \omega_m^{ji}\right) = m \sum_{m \mid i} a_i." />
                </>
            }],
            notes: [{
                subtitle: "Alternative",
                content: <>
                    Instead of using Vieta, we could have just noticed that <Block tex="1 + X + X^2 + \dots + X^{n-1} = \frac{X^n - 1}{X - 1}" /> and thus for <Inline tex="\omega_n \neq 1," /> we have <Block tex="1 + \omega_n + \omega_n^2 + \dots + \omega_n^{n-1} = 0." />
                </>
            }],
            branch: BranchData.C,
        }
    },
    REAL_POLYNOMIAL_FACTORIZATION: function (depth = 0) {
        return {
            id: 61,
            type: "wikiEntry",
            title: "Factorizing real polynomials",
            statement: {
                content: <>
                    Let <Inline tex="P(X)" /> be a polynomial with real coefficients. Then, we can write
                    <Block tex="P(X) = Q_1(X) \cdot Q_2(X) \cdot \dots \cdot Q_k(X)" />
                    for some polynomials <Inline tex="Q_i(X)" /> with real coefficients of degree at most <Inline tex="2." />
                </>
            },
            proofs: [{
                content: <>
                    We use induction on <Inline tex="n := \deg P." /> If <Inline tex="n \leq 2," /> we can set <Inline tex="k = 1" /> and <Inline tex="Q_1 = P." /> Now, let us consider <Inline tex="n > 2" /> and assume the statement holds for all such polynomials of degree <Inline tex="< n." /> If <Inline tex="P(x) = 0" /> for some <Inline tex="x \in \mathbb R," /> we can write <Block tex="P(X) = (X - x) \cdot Q(X)," /> which finishes by the induction hypothesis on <Inline tex="Q" />. So let us assume otherwise. The <WikiInfo depth={depth} data={WikiData.FUNDAMENTAL_THEOREM_OF_ALGEBRA}>fundamental theorem of algebra</WikiInfo> tells us that there exists some <Inline tex="z \in" /> <WikiInfo depth={depth} data={WikiData.COMPLEX_NUMBERS}><Inline tex="\mathbb C" /></WikiInfo> with <Inline tex="P(z) = 0." /> Because <Inline tex="\overline{z^k} = (\bar{z})^k," /> we also have <Block tex="P(\bar z) = \overline{P(z)} = \bar 0 = 0." /> By our assumption, we have <Inline tex="z \not \in \mathbb R" /> and thus <Inline tex="z \neq \bar z." /> So, we can write <Block tex="P(X) = (X - z)(X - \bar z) \cdot Q(X) = (X - 2\mathrm{Re}(z)X + |z|^2) \cdot Q(X)," /> which finishes by the induction hypothesis on <Inline tex="Q" />.
                </>
            }],
            notes: [{
                content: <>
                    Hence, an <WikiInfo depth={depth} data={WikiData.IRREDUCIBLE_POLYNOMIAL}>irreducible polynomial</WikiInfo> over the real numbers is of degree <Inline tex="\leq 2." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    IRREDUCIBLE_POLYNOMIAL: function (depth = 0) {
        return {
            id: 62,
            type: "wikiEntry",
            title: "Irreducible polynomials",
            statement: {
                content: <>
                    Let <Inline tex="F" /> be field. We call a non-constant  polynomial <Inline tex="P" /> with coefficients in <Inline tex="F" /> reducible over <Inline tex="F," /> if we can find two non-constant polynomials <Inline tex="Q_1, Q_2" /> with coefficients in <Inline tex="F" /> such that
                    <Block tex="P(X) = Q_1(X) \cdot Q_2(X)." />
                    Otherwise, we call <Inline tex="P" /> irreducible over <Inline tex="F." />
                </>
            },
            notes: [{
                subtitle: <><Inline tex="\mathbb R" /> and <Inline tex="\mathbb C" /></>,
                content: <>
                    We know that irreducible polynomials over <Inline tex="\mathbb C" /> are the linear polynomials (see <WikiInfo depth={depth} data={WikiData.FUNDAMENTAL_THEOREM_OF_ALGEBRA}>here</WikiInfo>). <br />
                    We can use this fact to conclude that irreducible polynomials over <Inline tex="\mathbb R" /> are linear or quadratic (see <WikiInfo depth={depth} data={WikiData.REAL_POLYNOMIAL_FACTORIZATION}>here</WikiInfo>).
                </>
            },
            {
                subtitle: <><Inline tex="\mathbb Z" /></>,
                content: <>
                    The <WikiInfo depth={depth} data={WikiData.CYCLOTOMIC_POLYNOMIALS}>cyclotomic polynomials</WikiInfo> are irreducible over the integers.<br />
                    Eisenstein's criterion is another result for proving irreducibility over integers: Let <Inline tex="P(X) = \sum_{i=0}^n a_iX^i" /> be a polynomial of degree <Inline tex="n" /> with integer coefficients. If
                    <Block tex="p^2 \nmid a_0," />
                    <Block tex="p \mid a_i \quad \forall 0 \leq i \leq n - 1," />
                    <Block tex="p \nmid a_n," />
                    then <Inline tex="P(X)" /> is irreducible.
                </>
            },
            {
                subtitle: "Factorization into irreducible polynomials",
                content: <>
                    We can factor a polynomial over a field <Inline tex="F" /> into irreducible polynomials. This factorization is unique up to permutation and multiplication with constants.
                </>
            }],
            branch: BranchData.A,
        }
    },
    CYCLOTOMIC_POLYNOMIALS: function (depth = 0) {
        return {
            id: 63,
            type: "wikiEntry",
            title: "Cyclotomic polynomials",
            statement: {
                content: <>
                    We define the cyclotomic polynomial of degree <Inline tex="n" /> recursively by
                    <Block tex="\prod_{d \mid n} \varphi_d(X) = X^n - 1." />
                </>
            },
            notes: [{
                content: <>
                    So, the roots of <Inline tex="\varphi_n(X)" /> are the <WikiInfo depth={depth} data={WikiData.ROOTS_OF_UNITY}><Inline tex="n" />-th roots of unity</WikiInfo> that are not a <Inline tex="d" />-th root of unity for some <Inline tex="d \mid n, d < n." /> So, we can explicitly write
                    <Block tex="\varphi_n(X) = \prod_{\substack{1 \leq i \leq n \\ \mathrm{gcd}(n, i) = 1}} (X - \omega_n^i)." />
                </>
            }, {
                subtitle: "Integer coefficients",
                content: <>
                    From this representation, we see that <Inline tex="\varphi_n(X)" /> is normed (has leading coefficient <Inline tex="1" />). Therefore,
                    <Block tex="\varphi_n(X) = \frac{X^n - 1}{\prod_{\substack{d \mid n \\ d < n}} \varphi_d(X)}" />
                    is the quotient of two normed polynomials with integer coefficients and is a polynomial. From <WikiInfo depth={depth} data={WikiData.EUCLIDS_ALGORITHM}>polynomial division</WikiInfo>, we deduce that <Inline tex="\varphi_n(X)" /> has integer coefficients.
                </>
            }, {
                subtitle: "Degree",
                content: <>
                    Moreover, we conclude that <Block tex="\deg \varphi_n = |\{1 \leq i \leq n \mid \mathrm{gcd}(n, i) = 1\}| = \varphi(n)." />
                </>
            }, {
                subtitle: "Irreducibility",
                content: <>
                    <Inline tex="\varphi_n(X)" /> is <WikiInfo depth={depth} data={WikiData.IRREDUCIBLE_POLYNOMIAL}>irreducible</WikiInfo> over integers. We can use this fact to prove the irreducibility over integers of a polynomial by recognizing it as a cyclotomic polynomial.
                </>
            }, {
                subtitle: "Notable cyclotomic polynomials",
                content: <>
                    For a prime <Inline tex="p," /> we have
                    <Block tex="\varphi_p(X) = \frac{X^p - 1}{X - 1} = 1 + X + X^2 + \dots + X^{p-1}." />
                    If <Inline tex="p" /> is odd, then
                    <Block tex="\varphi_{2p}(X) = \frac{X^{2p} - 1}{\frac{X^p - 1}{X-1}(X + 1)(X-1)} = 1 - X + X^2 \mp \dots + X^{p-1}." />
                    If <Inline tex="m \in \mathbb N," /> we have
                    <Block tex="\varphi_{p^m}(X) = 1 + X^{p^{m-1}} + X^{2p^{m-1}} + \dots + X^{(p-1)p^{m-1}}." />
                    In particular, this yields
                    <Block tex="\varphi_{2^m}(X) = 1 + X^{2^{m-1}}." />
                </>
            }, {
                subtitle: <>"Trick 17"</>,
                content: <>
                    Let <Inline tex="p" /> be a prime. Suppose that a prime <Inline tex="q" /> divides <Inline tex="\varphi_p(x)" /> for some <Inline tex="x \in \mathbb Z." /> It follows that
                    <Block tex="p = q \quad \textnormal{or} \quad q \equiv 1 \mod p." />
                </>
            }, {
                subtitle: <>"Trick 18"</>,
                content: <>
                    Let <Inline tex="n \in \mathbb N" />. Suppose that a prime <Inline tex="q" /> divides <Inline tex="\varphi_n(x)" /> for some <Inline tex="x \in \mathbb Z." /> It follows that
                    <Block tex="q \mid n \quad \textnormal{or} \quad q \equiv 1 \mod n." />
                </>
            }],
            branch: BranchData.N,
        }
    },
    EUCLIDS_ALGORITHM: function (depth = 0) {
        return {
            id: 64,
            type: "wikiEntry",
            title: "Euclid's algorithm",
            altTitles: ["Bezouts identity"],
            statement: {
                content: <>
                    We can compute the <WikiInfo depth={depth} data={WikiData.GCD_AND_LCM}><Inline tex="\gcd(a, b)" /></WikiInfo> using euclid's algorithm.<br />
                    If the <WikiInfo depth={depth} data={WikiData.MODULAR_CONGRUENCE}>congruence</WikiInfo> <Inline tex="b \equiv b' \mod a" /> holds, we have
                    <Block tex="\gcd(a, b) = \gcd(a, b')." />
                    This is the basis for Euclid's algorithm that is very useful. By applying the above identity repeatedly, reducing the size of one of the parameters until one is <Inline tex="0" />, we get the greatest common divisor because <Block tex="\gcd(m, 0) = m." />
                </>
            },
            notes: [
                {
                    subtitle: "Bézout's identity",
                    content: <>
                        Euclid's algorithm gives a proof for Bézout's identity, which states that there always exists <Inline tex="s, t \in \mathbb Z" />, such that
                        <Block tex="sa + tb = \gcd(a, b)." />
                        We can calculate these coefficients by going through Euclid's algorithm backwards and keeping track of the coefficients that yield the desired sum. <br />
                        For example:
                        <Block tex="\gcd(65, 25) = \gcd(15, 25) = \gcd(15, 10) = \gcd(5, 10) = \gcd(5, 0) = 5." />
                        Therefore, we have
                        <Block tex="5 = 1 \cdot 5 + 0 \cdot \underbrace{0}_{= 10 - 2\cdot 5} = 1 \cdot \underbrace{5}_{= 15 - 10} + 0 \cdot 10 = 1 \cdot 15 - 1 \cdot \underbrace{10}_{25 - 15} = 2 \cdot \underbrace{15}_{65 - 2 \cdot 25} - 1 \cdot 25 = 2 \cdot 65 - 5 \cdot 25." />
                    </>
                },
                {
                    subtitle: "Polynomial division",
                    content: <>
                        We divide polynomials analogously. This algorithm yields the following result:<br />
                        Let <Inline tex="P, Q" /> be polynomials with integer coefficients. If <Inline tex="Q" /> is normed (has leading coefficient <Inline tex="1" />), we can find polynomials <Inline tex="R, S" /> with integer coefficients and <Inline tex="\deg R < \deg Q" /> such that
                        <Block tex="P(X) = Q(X) \cdot S(X) + R(X)." />
                        Therefore, if for some polynomial <Inline tex="S(X)," /> we have <Inline tex="P(X) = Q(X) \cdot S(X)," /> then <Inline tex="S(X)" /> has integer coefficients.
                    </>
                }
            ],
            branch: BranchData.A,
        }
    },
    PASCALS_THEOREM: function (depth = 0) {
        return {
            id: 65,
            type: "wikiEntry",
            title: "Pascal's theorem",
            altTitles: [],
            statement: {
                content: <>
                    Consider <Inline tex="6" /> points <Inline tex="A,B,C,D,E,F" /> on a nondegenerate conic <Inline tex="k" /> in the <WikiInfo depth={depth} data={WikiData.PROJECTIVE_PLANE}>projective plane</WikiInfo>. We define the intersections of lines
                    <Block tex="X := AB \cap DE," />
                    <Block tex="Y := BC \cap EF," />
                    <Block tex="Z := CD \cap FA." />
                    Pascal's theorem states that <Inline tex="X,Y,Z" /> are collinear.
                    <div className="wiki-img-div">
                        <img src={require("../img/wiki-images/pascals-theorem.png")} id="pascals-theorem" />
                    </div>
                </>
            },
            notes: [
                {
                    subtitle: "Tangent lines",
                    content: <>
                        For <Inline tex="P \in k," /> we define <Inline tex="PP" /> to be the tangent of <Inline tex="k" /> at <Inline tex="P" /> here. So, <Inline tex="A,B,C,D,E,F" /> may be not pairwise distinct. This is an important special case of pascal's theorem.
                    </>
                },
            ],
            proofs: [{
                content: <>
                    Let <Inline tex="K_1, K_2" /> be the intersections of <Inline tex="k" /> and the line <Inline tex="XY." /> Let <Inline tex="P := XY \cap BE." /> Moreover, define <Inline tex="Z' := XY \cap CD" /> and <Inline tex="Z'' := XY \cap AF-" /> We need to prove that <Inline tex="Z' = Z''." /> Using <WikiInfo depth={depth} data={WikiData.CROSS_RATIO}>projective transformations</WikiInfo> at the points above the equality signs, we get
                    <Block tex="(K_1, K_2; Z', X) \stackrel{D}= (K_1, K_2; C, E)" />
                    <Block tex="\stackrel{B}= (K_1, K_2; Y, P) \stackrel{E}= (K_1, K_2;F, B)" />
                    <Block tex="\stackrel{A}= (K_1, K_2; Z'', X)." />
                    Thus, <Inline tex="Z' = Z''" /> follows.
                </>
            }],
            branch: BranchData.G,
        }
    },
    CAUCHY_SCHWARZ_INEQUALITY: function (depth = 0) {
        return {
            id: 66,
            type: "wikiEntry",
            title: "Cauchy-Schwarz inequality",
            altTitles: ["CSU", "CS", "Bunjakowski"],
            statement: {
                content: <>
                    Let <Inline tex="x_1, \dots, x_n \in \mathbb R," /> and <Inline tex="y_1, \dots, y_n \in \mathbb R." /> We have
                    <Block tex="\sum_{i=1}^n x_i^2 \cdot \sum_{i=1}^n y_i^2 \geq \left(\sum_{i=1}^n x_i \cdot y_i\right)^2." />
                </>
            },
            notes: [
                {
                    subtitle: "Titu's lemma",
                    content: <>
                        By setting <Inline tex="p_i = x_i^2," /> and <Inline tex="q_i = x_i y_i," /> we obtain Titu's lemma: <br />
                        Let <Inline tex="p_1, \dots, p_n \in \mathbb R_{> 0}," />
                        and <Inline tex="q_1, \dots, q_n \in \mathbb R." />
                        We have
                        <Block tex="\sum_{i=1}^n \frac{q_i^2}{p_i} \geq \frac{\left(\sum_{i=1}^n q_i\right)^2}{\sum_{i=1}^n p_i}" />
                    </>
                },
                {
                    content: <>
                        By setting <Inline tex="p_i = x_i^2," /> and <Inline tex="q_i = y_i^2," /> we obtain another useful form of the inequality: <br />
                        Let <Inline tex="p_1, \dots, p_n \in \mathbb R_{\geq 0}," /> and <Inline tex="q_1, \dots, q_n \in \mathbb R_{\geq 0}." /> We have
                        <Block tex="\sqrt{\sum_{i=1}^n p_i \cdot \sum_{i=1}^n q_i} \geq \sum_{i=1}^n \sqrt{p_i \cdot q_i}." />
                        For example, all <Inline tex="a,b,c,d \geq 0" /> satisfy
                        <Block tex="\sqrt{(a+b)(c+d)} \geq \sqrt{ac} + \sqrt{bd}." />
                    </>
                }
            ],
            proofs: [],
            branch: BranchData.A,
        }
    },
    JENSENS_INEQUALITY: function (depth = 0) {
        return {
            id: 67,
            type: "wikiEntry",
            title: "Jensen's inequality",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Let <Inline tex="f: \mathbb R \to \mathbb R" /> be a <WikiInfo data={WikiData.CONVEX_AND_CONCAVE_FUNCTIONS} depth={depth}>convex</WikiInfo> function. Jensen's inequality states that for <Inline tex="x_1, \dots, x_n \in \mathbb R" /> and weights <Inline tex="\lambda_1, \dots, \lambda_n \in [0, 1]" /> with sum <Inline tex="1," /> we have
                        <Block tex="f\left(\sum_{i=1}^n \lambda_i x_i\right) \leq \sum_{i=1}^n \lambda_i f(x_i)." />
                        The reverse inequality holds when <Inline tex="f" /> is concave.
                    </>
            },
            proofs: [],
            notes: [],
            examples: [],
            branch: BranchData.A,
        }
    },
    BERTRANDS_POSTULATE: function (depth = 0) {
        return {
            id: 69,
            type: "wikiEntry",
            title: "Bertrand's postulate",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        For any <Inline tex="n > 1," /> there exists a prime <Inline tex="p" /> with <Inline tex="n < p < 2n." />
                    </>
            },
            proofs: [],
            notes: [],
            examples: [],
            branch: BranchData.A,
        }
    },
    PROBABILITY_SPACE: function (depth = 0) {
        return {
            id: 70,
            type: "wikiEntry",
            title: "Probability space",
            altTitles: ["Union bound", "Principle of inclusion-exclusion", "PIE"],
            statement: {
                content: <>
                    Let <Inline tex="\Omega" /> be a set. For <Inline tex="A \subseteq \Omega," /> we define the complement of <Inline tex="A" /> by <Inline tex="A^C := \Omega \backslash A." /> We say that a set <Inline tex="\mathcal A \subseteq \mathcal P(\Omega)" /> of subsets of <Inline tex="\Omega" /> is a <Inline tex="\sigma" />-algebra if
                    <ul>
                        <li><Inline tex="\Omega \in \mathcal A," /></li>
                        <li><Inline tex="\forall A \in \mathcal A : A^C \in \mathcal A," /></li>
                        <li><Inline tex="\forall A_1, A_2, \dots \in \mathcal A : \bigcup_{i=1}^\infty A_i \in \mathcal A." /></li>
                    </ul>
                    Now, we consider a <Inline tex="\sigma" />-algebra <Inline tex="\mathcal A." /> We call the elements <Inline tex="A \in \mathcal A" /> events. A mapping <Inline tex="\mathbb P : \mathcal A \to [0, 1], A \mapsto \mathbb P[A]" /> is a probability measure on <Inline tex="(\Omega, \mathcal A)" /> if
                    <ul>
                        <li><Block tex="\mathbb P[\Omega] = 1," /></li>
                        <li><Block tex="\forall A_1, A_2, \dots \in \mathcal A, A_i \cap A_j = \varnothing \quad \forall i \neq j : \mathbb P \left[\bigcup_{i=1}^\infty A_i \right] = \sum_{i=1}^\infty \mathbb P[A_i]." /></li>
                    </ul>
                    If <Inline tex="\mathbb P" /> is a probability measure on <Inline tex="(\Omega, \mathcal A)," /> then we call <Inline tex="(\Omega, \mathcal A, \mathbb P)" /> a probability space.
                </>
            },
            examples: [{
                subtitle: "Elementary properties",
                content: <>
                    <ul>
                        <li><Block tex="\mathbb P[\varnothing] = 0." /></li>
                        <li><Block tex="\forall A, B \in \mathcal A, A \cap B = \varnothing : \mathbb P[A \cup B] = \mathbb P[A] + \mathbb P[B]. " /></li>
                        <li><Block tex="\forall A, B \in \mathcal A, A \subseteq B : \mathbb P[B] = \mathbb P[A] + \mathbb P[B \backslash A]." /> Hence, <Block tex="\mathbb P[A] \leq \mathbb P[B]," /> and <Block tex="\mathbb P[A^C] = 1 - \mathbb P[A]." /></li>
                    </ul>
                </>
            },
            {
                subtitle: "Union bound",
                content: <>
                    For <Inline tex="A_1, A_2, \dots \in \mathcal A," /> we have <Block tex="\mathbb P \left[\bigcup_{i=1}^\infty A_i \right] \leq \sum_{i=1}^\infty \mathbb P[A_i]." /></>
            },
            {
                subtitle: "Principle of inclusion-exclusion",
                content: <>
                    For <Inline tex="A_1, A_2, \dots, A_n \in \mathcal A," /> we have <Block tex="\mathbb P \left[\bigcup_{i=1}^n A_i \right] = \sum_{k=1}^n (-1)^{k-1} \sum_{1 \leq i_1 < \dots < i_k \leq n} \mathbb P[A_{i_1} \cap \dots \cap A_{i_k}]." /></>,
                proof: <>
                    Let <Inline tex="\mathbb 1_i := \mathbb 1_{A_i}" /> be the <WikiInfo depth={depth} data={WikiData.RANDOM_VARIABLE}>indicator function</WikiInfo> of <Inline tex="A_i." /> By <WikiInfo depth={depth} data={WikiData.EXPECTATION}>linearity of expectation</WikiInfo>, <Block tex="\mathbb P \left[\bigcup_{i=1}^n A_i \right] = 1 - \mathbb E\left[\mathbb 1_{\bigcap_{i=1}^n A_i^C} \right] = 1 - \mathbb E\left[\prod_{i=1}^n (1 - \mathbb 1_i) \right]" />
                    <Block tex="= \sum_{k=1}^n (-1)^{k-1} \sum_{1 \leq i_1 < \dots < i_k \leq n} \mathbb E[\mathbb 1_{i_1} \cdots \mathbb 1_{i_k}]." />
                    This finishes because
                    <Block tex="\mathbb E[\mathbb 1_{i_1} \cdots \mathbb 1_{i_k}] = \mathbb E\left[\mathbb 1_{A_{i_1} \cap \dots \cap A_{i_n}}\right] = \mathbb P[A_{i_1} \cap \dots \cap A_{i_n}]." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    RANDOM_VARIABLE: function (depth = 0) {
        return {
            id: 71,
            type: "wikiEntry",
            title: "Random variable",
            altTitles: ["Indicator function"],
            statement: {
                content: <>
                    Let <Inline tex="(\Omega, \mathcal A, \mathbb P)" /> be a <WikiInfo depth={depth} data={WikiData.PROBABILITY_SPACE}>probability space</WikiInfo>. A discrete random variable is a function <Inline tex="X : \Omega \to S" /> such that <Block tex="\{X = x\} := \{\omega \in \Omega \mid X(\omega) = x\} \in \mathcal A" /> for all <Inline tex="x \in S," /> where <Inline tex="S" /> is a countable set. We are mostly concerned with real-valued random variables <Inline tex="X," /> where <Inline tex="S \subseteq \mathbb R." />
                </>
            },
            notes: [{
                subtitle: "Distribution",
                content: <>
                    We call the probability distribution <Inline tex="\mu : \mathcal P(S) \to [0, 1]" /> defined by
                    <Block tex="\mu[A] := \mathbb P[X \in A] = (\mathbb P \circ X^{-1})[A]" />
                    the distribution of <Inline tex="X." /> We write <Inline tex="X \sim \mu." />
                </>
            }],
            examples: [{
                subtitle: "Indicator function",
                content: <>
                    For an event <Inline tex="A \in \mathcal A," /> we define the indicator function <Inline tex="\mathbb 1_A" /> of <Inline tex="A" /> by
                    <Block tex="\mathbb 1_A(\omega) = \begin{cases} 1 & \text{for $\omega \in A$,}\\ 0 & \text{otherwise.} \end{cases}" />
                    The <WikiInfo depth={depth} data={WikiData.EXPECTATION}>expectation</WikiInfo> of <Inline tex="\mathbb 1_A" /> is <Block tex="\mathbb E[\mathbb 1_A] = \mathbb P[A]." />
                </>
            }],
            branch: BranchData.A,
        }
    },
    EXPECTATION: function (depth = 0) {
        return {
            id: 73,
            type: "wikiEntry",
            title: "Expectation",
            altTitles: ["Markov's inequality", "Linearity of expectation"],
            statement: {
                content: <>
                    We define the expectation of a real-valued <WikiInfo depth={depth} data={WikiData.RANDOM_VARIABLE}>random variable</WikiInfo> <Inline tex="X" /> on <Inline tex="(\Omega, \mathcal A, \mathbb P)" /> by
                    <Block tex="\mathbb E[X] := \sum_{x \in X(\Omega)} x \cdot \mathbb P[X = x]" />
                    if the right side is well-defined (i.e. <Inline tex="X \geq 0" /> or <Inline tex="\mathbb E[|X|] < \infty." />)
                </>
            },
            notes: [
                {
                    subtitle: "Transformation formula",
                    content: <>
                        Let <Inline tex="X" /> be a random variable and let <Inline tex="g : X(\Omega) \to \mathbb R." /> Then, <Block tex="g(X) := g \circ X" /> is a real-valued random variable with expectation
                        <Block tex="\mathbb E[g(X)] = \sum_{x \in X(\Omega)} g(x) \cdot \mathbb P[X = x]" />
                        if <Inline tex="\mathbb E[g(X)]" /> is defined.<p />
                        We use the transformation formula all the time. For example, <Inline tex="\mathbb E[|X|] = \sum_{x \in X(\Omega)} |x| \cdot \mathbb P[X = x]." /> Moreover, for countable <Inline tex="\Omega, " /> <Inline tex="\mathrm{id}_\Omega" /> is a random variable for <Inline tex="\mathcal A = \mathcal P(\Omega)." /> Hence, <Block tex="\mathbb E[X] = \mathbb E[X(\mathrm{id}_\Omega)] = \sum_{\omega \in \Omega} X(\omega) \cdot \mathbb P[\{\omega\}]." /> We also use the transformation formula to prove linearity of expectation and equivalences between different representation of the variance.
                    </>,
                    proof: <>
                        For <Inline tex="y \in \mathbb R," /> we have <Block tex="(g(X))^{-1}(y) = \bigcup_{x \in X(\Omega) : g(x) = y} \{X^{-1}(x)\} \in \mathcal A." /> Hence, <Inline tex="g(X)" /> is a random variable. We have
                        <Block tex="\mathbb E[g(X)] = \sum_{y \in g(X(\Omega))} y \cdot \mathbb P[g(X) = y] = \sum_{y \in g(X(\Omega))} \sum_{x \in X(\Omega) : g(x) = y} y \cdot \mathbb P[X = x]" />
                        <Block tex="=\sum_{x \in X(\Omega)} \sum_{y \in g(X(\Omega)) : y = g(x)} y \cdot \mathbb P[X = x] = \sum_{x \in X(\Omega)} g(x) \cdot \mathbb P[X = x]." />
                    </>
                },
                {
                    subtitle: "Linearity of expectation",
                    content: <>
                        Let <Inline tex="X, Y" /> be real-valued random variables on <Inline tex="(\Omega, \mathcal A, \mathbb P)" /> such that <Inline tex="\mathbb E[|X|], \mathbb E[|Y|] < \infty." /> We have
                        <Block tex="\mathbb E[\alpha X + \beta Y] = \alpha \cdot \mathbb E[X] + \beta \cdot \mathbb E[Y] \quad \forall \alpha, \beta \in \mathbb R." />
                    </>,
                    proof: <>
                        We use the transformation formula with <Block tex="g(x, y) = \alpha x + \beta y." /> This yields
                        <Block tex="\mathbb E[\alpha X + \beta Y] = \mathbb E[g(X, Y)] = \sum_{x \in X(\Omega), y \in Y(\Omega)} g(x, y) \cdot \mathbb P [X = x, Y = y] = \sum_{x \in X(\Omega), y \in Y(\Omega)} (\alpha x + \beta y) \cdot \mathbb P [X = x, Y = y]." />
                        We can distribute and split the sum (since all sums converge absolutely) to write this as
                        <Block tex="\alpha \sum_{x \in X(\Omega), y \in Y(\Omega)} x \cdot \mathbb P [X = x, Y = y] + \beta \sum_{x \in X(\Omega), y \in Y(\Omega)} y \cdot \mathbb P [X = x, Y = y]" />
                        <Block tex="= \alpha \sum_{x \in X(\Omega)} x \cdot \mathbb P [X = x] + \beta \sum_{y \in Y(\Omega)} y \cdot \mathbb P [Y = y] = \alpha \cdot \mathbb E[X] + \beta \cdot \mathbb E[Y]." />
                    </>
                },
                {
                    subtitle: "Monotony",
                    content: <>
                        Let <Inline tex="X, Y" /> be real-valued random variables on <Inline tex="(\Omega, \mathcal A, \mathbb P)" /> such that <Inline tex="\mathbb E[|X|], \mathbb E[|Y|] < \infty" /> and <Inline tex="X(\omega) \leq Y(\omega)" /> for all <Inline tex="\omega \in \Omega." /> Then,
                        <Block tex="\mathbb E[X] \leq \mathbb E[Y]." />
                    </>,
                    proof: <>
                        We have <Block tex="0 \leq \mathbb E[X - Y] = \mathbb E[X] - \mathbb E[Y]" />
                        by linearity of expectation.
                    </>
                },
                {
                    subtitle: "Markov's inequality",
                    content: <>
                        Markov's inequality states that if <Inline tex="X \geq 0," /> then for any <Inline tex="\lambda > 0," />
                        <Block tex="\mathbb P\left[X \geq \lambda \right] \leq \frac{\mathbb E[X]}{\lambda}." />
                    </>,
                    proof: <>
                        We have <Block tex="Y := \lambda \cdot \mathbb 1_{X \geq \lambda} \leq X." />
                        Hence, by linearity and monotony of <Inline tex="\mathbb E," /> <Block tex="\lambda \cdot \mathbb P[X \geq \lambda] = \mathbb E[Y] \leq \mathbb E[X]." />
                    </>
                },
            ],
            examples: [],
            branch: BranchData.A,
        }
    },
    VARIANCE_AND_COVARIANCE: function (depth = 0) {
        return {
            id: 75,
            type: "wikiEntry",
            title: "Variance and covariance",
            altTitles: ["Chebyshev inequality", "Cauchy-Schwarz inequality"],
            statement: {
                content: <>
                    Let <Inline tex="X, Y" /> be real-valued <WikiInfo depth={depth} data={WikiData.RANDOM_VARIABLE}>random variables</WikiInfo> on <Inline tex="(\Omega, \mathcal A, \mathbb P)" />. If <Inline tex="\mathbb E[X]" /> is defined, we define the variance of <Inline tex="X" /> by
                    <Block tex="\mathrm{Var}[X] := \mathbb E \left[(X - \mathbb E [X])^2\right]." />
                    We define the covariance of <Inline tex="X" /> and <Inline tex="Y" /> by
                    <Block tex="\mathrm{Cov}[X, Y] := \mathbb E[(X - \mathbb E[X])(Y - \mathbb E[Y])] = \mathbb E[XY] - \mathbb E[X]\mathbb E[Y]" />
                    if <Inline tex="\mathbb E[X^2], \mathbb E[Y^2] < \infty." /> Thus,
                    <Block tex="\mathrm{Var}[X] = \mathrm{Cov}[X, X]." />
                </>
            },
            notes: [
                {
                    subtitle: "Alternate formula",
                    content: <>
                        By linearity of expectation,
                        <Block tex="\mathrm{Var}[X] = \mathbb E[X^2] - 2\mathbb E[X \cdot \mathbb E[X]] + \mathbb E[\mathbb E[X]^2] = \mathbb E[X^2] - \mathbb E[X]^2." />
                    </>
                },
                {
                    subtitle: "Alternate formula II",
                    content: <>
                        Let <Inline tex="x_1, \dots, x_n \in \mathbb R." /> Let <Inline tex="K \in \{1, \dots, n\}" /> be uniformly random and let <Inline tex="X := x_K." /> Then,
                        <Block tex="\mathbb E[X] = \frac{1}{n} \sum_{k=1}^n x_k" />
                        and
                        <Block tex="\mathrm{Var}[X] = \frac{1}{n} \sum_{k=1}^n x_k^2 - \left(\frac{1}{n} \sum_{k=1}^n x_k\right)^2 = \frac{1}{n^2} \sum_{k=1}^n \sum_{\ell=1}^n \frac{(x_k - x_\ell)^2}{2}." />
                    </>
                },
                {
                    subtitle: "Uncorrelatedness",
                    content: <>
                        We say that <Inline tex="X" /> and <Inline tex="Y" /> are uncorrelated if <Block tex="\mathrm{Cov}[X, Y] = 0." />
                    </>
                },
                {
                    subtitle: "Covariance is bilinear",
                    content: <>
                        For <Inline tex="X, Y, Z" /> with <Inline tex="\mathbb E[X^2], \mathbb E[Y^2], \mathbb E[Z^2] < \infty" /> and <Inline tex="\alpha \in \mathbb R," /> we have
                        <Block tex="\mathrm{Cov}[\alpha Y + Z, X] = \mathrm{Cov}[X, \alpha Y + Z] = \alpha \cdot \mathrm{Cov}[X, Y] + \mathrm{Cov}[X, Z]." />
                    </>
                },
                {
                    subtitle: "Chebyshev inequality",
                    content: <>
                        The Chebyshev inequality states that if <Inline tex="\mathrm{Var}[X] > 0," /> then for any <Inline tex="\lambda > 0," />
                        <Block tex="\mathbb P\left[|X - \mathbb E[X]| \geq \lambda \sqrt{\mathrm{Var}[X]}\right] \leq \frac{1}{\lambda^2}." />
                    </>,
                    proof: <>
                        Define <Block tex="A := \left\{|X - \mathbb E[X]| \geq \lambda \sqrt{\mathrm{Var}[X]}\right\}." /> Let <Block tex="Y = \lambda^2 \mathrm{Var}[X] \cdot \mathbb 1_A." /> We have <Block tex="Y \leq (X - \mathbb E[X])^2." /> Hence, by monotony
                        <Block tex="\mathbb E[Y] \leq \mathbb E[(X - \mathbb E[X])^2] = \mathrm{Var}[X]." />
                        We obtain
                        <Block tex="\mathbb P[A] = \frac{\mathbb E[Y]}{\lambda^2 \mathrm{Var}[X]} \leq \frac{1}{\lambda^2}." />
                    </>
                },
                {
                    subtitle: "Cauchy-Schwarz inequality",
                    content: <>
                        The Cauchy-Schwarz inequality states that
                        <Block tex="|\mathrm{Cov}[X, Y]| \leq \sqrt{\mathrm{Var}[X] \cdot \mathrm{Var}[Y]}." />
                    </>,
                    proof: <>
                        We may assume that <Inline tex="\mathrm{Var}[X], \mathrm{Var}[Y] \not\in \{0, \infty\}" /> because otherwise, the statement is clear. We use that for <Inline tex="\lambda \in \mathbb R," />
                        <Block tex="0 \leq \mathrm{Var}[X - \lambda Y] = \mathrm{Var}[X] + \lambda^2 \mathrm{Var}[Y] - 2\lambda \mathrm{Cov}[X, Y]." />
                        Setting <Block tex="\lambda = \pm \sqrt{\frac{\mathrm{Var}[X]}{\mathrm{Var}[Y]}}" />
                        gives
                        <Block tex="\pm \mathrm{Cov}[X, Y] \leq \sqrt{\mathrm{Var}[X] \cdot \mathrm{Var}[Y]}." />
                    </>
                }
            ],
            examples: [],
            branch: BranchData.A,
        }
    },
    CONDITIONAL_PROBABILITY: function (depth = 0) {
        return {
            id: 72,
            type: "wikiEntry",
            title: "Conditional probability",
            altTitles: ["Law of total probability", "Law of total expectation"],
            statement: {
                content: <>
                    Let <Inline tex="(\Omega, \mathcal A, \mathbb P)" /> be a <WikiInfo depth={depth} data={WikiData.PROBABILITY_SPACE}>probability space</WikiInfo>. Let <Inline tex="A, B \in \mathcal A" /> and <Inline tex="\mathbb P[B] \neq 0." /> Then, we define the conditional probability of <Inline tex="A" /> given <Inline tex="B" /> by
                    <Block tex="\mathbb P[A \mid B] := \frac{\mathbb P[A \cap B]}{\mathbb P[B]}." />
                </>
            },
            notes: [{
                subtitle: "Law of total probability",
                content: <>
                    Let <Inline tex="A, B_1, B_2, \dots \in \mathcal A" /> such that <Inline tex="\Omega = \bigcup_{i=1}^\infty B_i" /> and <Inline tex="B_i \cap B_j = \varnothing" /> for <Inline tex="i \neq j." /> Then, we have
                    <Block tex="\mathbb P[A] = \sum_{\substack{i=1 \\ \mathbb P[B_i] \neq 0}}^\infty \mathbb P[A \mid B_i] \cdot \mathbb P[B_i]." />
                </>,
                proof: <>
                    Set <Inline tex="X = \mathbb 1_A" /> in the law of total expectation that is proven below.
                </>
            },
            {
                subtitle: "Law of total expectation",
                content: <>
                    Let <Inline tex="X : \Omega \to \mathbb R" /> be a random variable such that <Inline tex="\mathbb E[X]" /> is defined and let <Inline tex="B_1, B_2, \dots \in \mathcal A" /> such that <Inline tex="\Omega = \bigcup_{i=1}^\infty B_i" /> and <Inline tex="B_i \cap B_j = \varnothing" /> for <Inline tex="i \neq j." /> Then, we have
                    <Block tex="\mathbb E[X] = \sum_{\substack{i=1 \\ \mathbb P[B_i] \neq 0}}^\infty \mathbb E[X \mid B_i] \cdot \mathbb P[B_i]." />
                    Here, we define
                    <Block tex="\mathbb E[X \mid B] = \frac{\mathbb E[X \cdot \mathbb 1_B]}{\mathbb P[B]}." />
                </>,
                proof: <>
                    We have
                    <Block tex="\sum_{\substack{i=1 \\ \mathbb P[B_i] \neq 0}}^\infty \mathbb E[X \mid B_i] \cdot \mathbb P[B_i] = \sum_{i=1}^\infty \mathbb E[X \cdot \mathbb 1_{B_i}] = \mathbb E\left[X \cdot \sum_{i=1}^\infty \mathbb 1_{B_i}\right] = \mathbb E[X]" />
                    by linearity of expectation.
                </>
            },],
            examples: [],
            branch: BranchData.A,
        }
    },
    INDEPENDENCE: function (depth = 0) {
        return {
            id: 74,
            type: "wikiEntry",
            title: "Independence",
            statement: {
                content: <>
                    Let <Inline tex="I" /> be an index set. We say that <Inline tex="A_i \in \mathcal A" /> for <Inline tex="i \in I" /> are independent if
                    <Block tex="\mathbb P[A_{i_1} \cap \dots \cap A_{i_n}] = \prod_{k=1}^n \mathbb P[A_{i_k}]" />
                    for any pairwise distinct <Inline tex="i_1, \dots, i_n \in I." />
                </>
            },
            notes: [{
                subtitle: "Independence is stable under complements",
                content: <>
                    Let <Inline tex="B_i \in \{A_i, A_i^c\}." /> The independence of <Inline tex="(A_i)_{i \in I}" /> implies the independence of <Inline tex="(B_i)_{i \in I}." />
                </>
            },
            {
                subtitle: "Pairwise independence does not imply independence!",
                content: <>
                    We can easily construct <Inline tex="A_1, A_2, A_3 \in \mathcal A" /> over a suitable probability space such that <Block tex="\mathbb P[A_i \cap A_{i+1}] = \mathbb P[A_i] \cdot \mathbb P[A_{i+1}] \quad \forall i = 1, 2, 3" />
                    (with indices taken <Inline tex="\mod 3" />) but
                    <Block tex="\mathbb P[A_1 \cap A_2 \cap A_2] \neq \mathbb P[A_1] \cdot \mathbb P[A_2] \cdot \mathbb P[A_3]." />
                </>
            },
            {
                subtitle: "Pairwise independence criterion",
                content: <>
                    Let <Inline tex="A, B \in \mathcal A" /> and <Inline tex="\mathbb P[B] \neq 0." /> Then, <Block tex="\text{$A, B$ independent} \iff \mathbb P[A \mid B] = \mathbb P[A]." />
                </>
            },
            {
                subtitle: "Independence of random variables",
                content: <>
                    We say that random variables <Inline tex="(X_i : \Omega \to S_i)_{i \in I}" /> are independent if for any <Inline tex="(A_i \subseteq S_i)_{i \in I}," /> the events <Inline tex="(\{X_i \in A_i\})_{i \in I}" /> are independent.
                </>
            },
            {
                subtitle: "Independence implies uncorrelatedness",
                content: <>
                    <Inline tex="X, Y" /> independent implies <Inline tex="X, Y" /> uncorrelated. The converse is not true. However,
                    <Block tex="\text{$X, Y$ independent $\quad \iff \quad f(X), g(Y)$ uncorrelated}" />
                    for any functions <Inline tex="f : X(\Omega) \to \mathbb R, g : Y(\Omega) \to \mathbb R" /> such that <Inline tex="\mathbb E[f(X)^2], \mathbb E[g(Y)^2] < \infty." />
                </>,
                proofsketch: <>
                    Assume that <Inline tex="X" /> and <Inline tex="Y" /> are independent and consider functions <Inline tex="f, g" /> with <Inline tex="\mathbb E[f(X)^2], \mathbb E[g(Y)^2] < \infty." /> Then,
                    <Block tex="\mathbb E[f(X) g(Y)] = \sum_{x \in X(\Omega)} \sum_{y \in Y(\Omega)} f(x) g(y) \mathbb P[X = x, Y = y]" />
                    <Block tex="= \sum_{x \in X(\Omega)} f(x) \mathbb P[X = x] \cdot \sum_{y \in Y(\Omega)} g(y) \mathbb P[Y = y] = \mathbb E[f(X)] \cdot \mathbb E[g(Y)]." />
                    Now, assume the right side holds and take <Inline tex="f = \mathbb 1_{\{x\}}, g = \mathbb 1_{\{y\}}" /> for <Inline tex="x \in X(\Omega), y \in Y(\Omega)." /> Then, <Block tex="\mathbb P[X = x, Y = y] = \mathbb E [f(X) g(Y)] = \mathbb E[f(X)] \cdot \mathbb E[g(Y)] = \mathbb P[X = x] \cdot \mathbb P[Y = y]." />
                </>
            }],
            examples: [],
            branch: BranchData.A,
        }
    },
    BERNOULLI_AND_BINOMIAL_DISTRIBUTION: function (depth = 0) {
        return {
            id: 76,
            type: "wikiEntry",
            title: "Bernoulli distribution and binomial distribution",
            statement: {
                content: <>
                    We say that a random variable <Inline tex="X" /> has distribution <Inline tex="\mathrm{Ber}(p)" /> or that it is Bernoulli-<Inline tex="p \in [0, 1]" /> distributed if <Block tex="\mathbb P[X = 1] = p" /> and <Block tex="\mathbb P[X = 0] = 1 - p." />
                    For independent <Inline tex="X_1, \dots, X_n \sim \mathrm{Ber}(p)," /> we say that
                    <Block tex="S := \sum_{i=1}^n X_i \sim \mathrm{Bin}(n, p)" />
                    has binomial distribution with parameters <Inline tex="n" /> and <Inline tex="p." /> For <Inline tex="0 \leq k \leq n," />
                    <Block tex="\mathbb P[S = k] = \binom{n}{k} p^k (1-p)^{n-k}." />
                </>
            },
            notes: [
                {
                    subtitle: "Expectation",
                    content: <>
                        We have
                        <Block tex="\mathbb E[X] = 1 \cdot \mathbb P[X = 1] = p." />
                        What is the expectation of <Inline tex="S" />? We could try to work out
                        <Block tex="\mathbb E[S] = \sum_{k=0}^n k \cdot \binom{n}{k} p^k (1-p)^{n-k}" />
                        with classical arguments. But there is a much nicer way! By linearity of expectation, we have
                        <Block tex="\mathbb E[S] = \mathbb E\left[\sum_{i=1}^n X_i\right] = \sum_{i=1}^n \mathbb E[X_i] = np." />
                    </>
                },
                {
                    subtitle: "Variance",
                    content: <>
                        We have
                        <Block tex="\mathrm{Var}[X] = (1-p)^2 \cdot \mathbb P[X = 1] + (0 - p)^2 \cdot \mathbb P[X = 0] = p(1-p)." />
                        Since the <Inline tex="X_i" /> are pairwise independent and thus uncorrelated, the bilinearity of <Inline tex="\mathrm{Cov}" /> implies
                        <Block tex="\mathrm{Var}[S] = \mathrm{Var}\left[\sum_{i=1}^n X_i\right]" />
                        <Block tex="= \sum_{i=1}^n \mathrm{Var}[X_i] + 2\sum_{i < j} \underbrace{\mathrm{Cov}[X_i, X_j]}_{= 0} = np(1-p)." />
                    </>
                }
            ],
            examples: [],
            branch: BranchData.A,
        }
    },
    GEOMETRIC_DISTRIBUTION: function (depth = 0) {
        return {
            id: 77,
            type: "wikiEntry",
            title: "Geometric distribution",
            statement: {
                content: <>
                    We say that a random variable <Inline tex="T : \Omega \to \mathbb N_{> 0}" /> has distribution <Inline tex="\mathrm{Geom}(p)" /> or that it has geometric distribution with parameter <Inline tex="p \in (0, 1]" /> if
                    <Block tex="\mathbb P[T = n] = p \cdot (1 - p)^{n-1} \quad \forall n \in \mathbb N_{> 0}." />
                    Hence,
                    <Block tex="\mathbb P[T \geq n] = \sum_{m = n}^\infty p \cdot (1 - p)^{m-1} = (1-p)^{n-1}." />
                    If <Inline tex="A_1, A_2, \dots \in \mathcal A" /> are independent events with <Inline tex="\mathbb P[A_n] = p" /> for all <Inline tex="n," /> then <Block tex="T(\omega) := \min\{n \in \mathbb N_{> 0} : \omega \in A_n\} \sim \mathrm{Geom}(p)." />
                    So the waiting time until tossing heads on a (possibly biased) coin is geometrically distributed.
                </>
            },
            proofs: [
                {
                    content: <>
                        If we define <Inline tex="T" /> as above, then for <Inline tex="n \in \mathbb N_{> 0}," />
                        <Block tex="\mathbb P[T = n] = \mathbb P[A_1^c \cap \dots \cap A_{n-1}^c \cap A_n]." />
                        By independence, this is
                        <Block tex="\mathbb P[A_1^c] \cdots \mathbb P[A_{n-1}^c] \cdot \mathbb P[A_n] = p \cdot (1-p)^{n-1}." />
                    </>
                }
            ],
            notes: [
                {
                    subtitle: "Memorylessness",
                    content: <>
                        We assume that <Inline tex="p < 1." /> Let <Inline tex="s, t \in \mathbb N_{> 0}." /> We have
                        <Block tex="\mathbb P[T = t] = \mathbb P[T = t + s \mid T > s]." />
                        Note that this implies
                        <Block tex="\mathbb E[f(T)] = \mathbb E[f(T - s) \mid T > s]" />
                        and thus
                        <Block tex="\mathbb E[f(T + s)] = \mathbb E[f(T) \mid T > s]" />
                        for any function <Inline tex="f" /> such that these expectations are defined.
                    </>,
                    proof: <>
                        We have
                        <Block tex="\mathbb P[T = t + s \mid T > s] = \frac{\mathbb P[\{T = t + s\} \cap \{T > s\}]}{\mathbb P[T > s]}" />
                        <Block tex="= \frac{p \cdot (1-p)^{t+s-1}}{(1-p)^s} = p \cdot (1-p)^{t-1} = \mathbb P[T = t]." />
                    </>
                },
                {
                    subtitle: "Expectation",
                    content: <>
                        We again note that expectation and variance can be directly calculated using the transformation formula (and a nice generating function representation). We present an approach using the memorylessness and the law of total expectation to condition on the outcome of the "first cointoss"
                        <Block tex="\mathbb E[X] = \mathbb E[X \mid X = 1] \cdot p + \mathbb E[X \mid X > 1] \cdot (1-p) = p + (1-p) \cdot (\mathbb E[X] + 1)." />
                        Hence,
                        <Block tex="\mathbb E[X] = \frac{1}{p}." />
                    </>
                },
                {
                    subtitle: "Variance",
                    content: <>
                        We have
                        <Block tex="\mathbb E[X^2] = \mathbb E[X^2 \mid X = 1] \cdot p + \mathbb E[X^2 \mid X > 1] \cdot (1-p)" />
                        <Block tex="= p + (1-p) \cdot (\mathbb E[X^2] + 2\mathbb E[X] + 1)" />
                        <Block tex="= 1 + 2 \cdot \frac{1-p}{p} + (1-p) \cdot \mathbb E[X^2]." />
                        Hence,
                        <Block tex="\mathbb E[X^2] = \frac{2-p}{p^2}." />
                        This yields
                        <Block tex="\mathrm{Var}[X] = \mathbb E[X^2] - \mathbb E[X]^2 = \frac{1-p}{p^2}." />
                    </>
                }
            ],
            examples: [],
            branch: BranchData.A,
        }
    },
    HALLS_MARIAGE_THEOREM: function (depth = 0) {
        return {
            id: 78,
            type: "wikiEntry",
            title: "Hall's marriage theorem",
            altTitles: ["Perfect matching condition"],
            statement: {
                content: <>
                    Let <Inline tex="G = (V, E)" /> be a <WikiInfo depth={depth} data={WikiData.BIPARTITE_GRAPH}>bipartite graph</WikiInfo> with bipartition <Inline tex="V = A \,\dot\cup\, B." /> Then, <Inline tex="G" /> has a <WikiInfo depth={depth} data={WikiData.MATCHING}>matching</WikiInfo> covering <Inline tex="A" /> if and only if
                    <Block tex="\forall X \subseteq A : |X| \leq |N(X)|." />
                </>,
            },
            proofs: [{
                subtitle: <>"<Inline tex="\implies" />"</>,
                content: <>
                    If there exists a matching <Inline tex="M" /> covering <Inline tex="A," /> then for any <Inline tex="X \subseteq A," />
                    <Block tex="|N(X)| \geq |N_M(X)| = |X|." />
                </>
            },
            {
                subtitle: <>"<Inline tex="\impliedby" />"</>,
                content: <>
                    Assume that
                    <Block tex="\forall X \subseteq A : |X| \leq |N(X)|." />
                    We will proof by induction on <Inline tex="|A|" /> that there exists a perfect matching. The base case is clear. <p />
                    Take a vertex <Inline tex="v \in A" /> with neighbor <Inline tex="w\in B" /> and consider the subgraph <Inline tex="G'" /> of <Inline tex="G" /> induced by <Inline tex="A\backslash\{v\}" /> and <Inline tex="B\backslash\{w\}" />. If
                    <Block tex="\forall S \subseteq A\backslash \{v\}: |S| \leq |N_{G'}(S)|," />
                    by induction there exists a matching <Inline tex="M'" /> in <Inline tex="G'" /> covering <Inline tex="A \backslash \{v\}" /> and we are done. <p />
                    Otherwise, we can find <Inline tex="Y\subseteq A\backslash\{v\}" /> with <Inline tex="|Y| \leq |N_{G'}(Y)| - 1" />. Together with <Inline tex="|Y| \geq |N_G(Y)|" />, we get
                    <Block tex="|Y| = |N(Y)|." /> By induction, there exists a matching <Inline tex="M_Y" /> in <Inline tex="G[Y \,\dot\cup\, N(Y)]" /> covering <Inline tex="Y." /> <p />
                    For any <Inline tex="X \subseteq A \backslash Y," /> we have
                    <Block tex="|N(X)| \geq |N(X \cup Y) \backslash N(Y)| = |N(X \cup Y)| - |N(Y)| \geq |X \cup Y| - |Y| = |X|." />
                    By induction, there exists a matching <Inline tex="M_{A \backslash Y}" /> in <Inline tex="G[V \backslash (Y \,\dot\cup\, N(Y))]" /> covering <Inline tex="A \backslash Y." /><p />
                    Now, <Inline tex="M := M_Y \cup M_{A \backslash Y}" /> is a matching in <Inline tex="G" /> covering <Inline tex="A." />
                </>
            },],
            notes: [{
                content: <>
                    Let <Inline tex="G = (V, E)" /> be a <WikiInfo depth={depth} data={WikiData.BIPARTITE_GRAPH}>bipartite graph</WikiInfo> with bipartition <Inline tex="V = A \,\dot\cup\, B." /> Moreover, assume that <Inline tex="|A| = |B|." /> Then, <Inline tex="G" /> has a <WikiInfo depth={depth} data={WikiData.MATCHING}>perfect matching</WikiInfo> if and only
                    <Block tex="\forall X \subseteq A : |X| \leq |N(X)|," />
                    where <Inline tex="N(X)" /> denotes the <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>neighborhood</WikiInfo> of <Inline tex="X." />
                </>
            }],
            examples: [],
            branch: BranchData.C,
        }
    },
    MATCHING: function (depth = 0) {
        return {
            id: 79,
            type: "wikiEntry",
            title: "Matching",
            altTitles: ["Perfect matching"],
            statement: {
                content: <>
                    Let <Inline tex="G = (V, E)" /> be a <WikiInfo depth={depth} data={WikiData.UNDIRECTED_GRAPH}>graph</WikiInfo>. We say that <Inline tex="M \subseteq E" /> is a matching if, for any distinct <Inline tex="e_1, e_2 \in M," /> we have <Inline tex="e_1 \cap e_2 = \varnothing." /> Equivalently, for <Inline tex="v \in V," /> we have <Inline tex="\deg_M(v) \leq 1." /> <p />
                    <Inline tex="M" /> covers <Inline tex="U \subseteq V" /> if <Inline tex="\deg_M(v) = 1" /> for <Inline tex="v \in U." /> <Inline tex="M" /> is a perfect matching if <Inline tex="M" /> covers <Inline tex="V." />
                </>
            },
            proofs: [],
            notes: [{
                content: <>
                    Let <Inline tex="M" /> be a matching. <Inline tex="M" /> is a perfect matching if and only if <Block tex="|M| = |V| / 2." />
                </>
            }],
            examples: [],
            branch: BranchData.C,
        }
    },
    EXPECTATION_BOUNDS_MAXIMUM: function (depth = 0) {
        return {
            id: 80,
            type: "wikiEntry",
            title: "Determinism and bounding by expectation",
            altTitles: ["Deterministic random variable", "Maximum", "Minimum"],
            statement: {
                content: <>
                    Let <Inline tex="Y \geq 0" /> be a real-valued random variable. Then,
                    <Block tex="\mathbb E[Y] = 0 \quad \iff \quad \mathbb P[Y = 0] = 1." />
                    Since expectation only depends on values that <Inline tex="Y" /> attains with positive probability, it is enough to assume that <Inline tex="\mathbb P[Y \geq 0] = 1." />
                </>
            },
            proofs: [{
                content: <>
                    If <Inline tex="\mathbb P[Y = 0] = 1," /> then <Inline tex="\mathbb E[Y] = 0 \cdot 1 = 0" /> by definition. <br />
                    Assume that <Inline tex="\mathbb E[Y] = 0." /> Markov's inequality tells us that for <Inline tex="\lambda > 0," />
                    <Block tex="\mathbb P[Y \geq \lambda] \leq \frac{\mathbb E[Y]}{\lambda} = 0." />
                    Hence, <Block tex="\mathbb P[Y > 0] = \mathbb P \left[\bigcup_{n = 1}^\infty \{Y \geq 1 / n\}\right] \leq \sum_{n=1}^\infty \mathbb P[Y \geq 1 / n] = 0." />
                </>
            }],
            notes: [
                {
                    subtitle: <><Inline tex="0" /> variance <Inline tex="\iff" /> deterministic</>,
                    content: <>
                        <Block tex="\mathrm{Var}[X] = 0 \quad \iff \quad \mathbb P[X = \mathbb E[X]] = 1." />
                        <GuardRelease>
                            See <PdfLink data={PdfData.TAIWAN_MO_2023_5} /> for an application.
                        </GuardRelease>
                    </>,
                    proof: <>
                        This follows from the previous part with <Inline tex="Y = (X - \mathbb E[X])^2." />
                    </>
                },
                {
                    content: <>
                        Let <Inline tex="X" /> be a real-valued random variable such that <Inline tex="\mathbb E[X] < \infty" /> is defined. Then, <Inline tex="\mathbb P[X \geq \mathbb E[X]] > 0" /> and <Inline tex="\mathbb P[X \leq \mathbb E[X]] > 0." /> <p />
                        If <Inline tex="X" /> is discrete, then we get that there exists <Inline tex="x^+ \geq \mathbb E[X]" /> and <Inline tex="x^- \leq \mathbb E[X]" /> such that <Inline tex="\mathbb P[X = x^+] > 0" /> and <Inline tex="\mathbb P[X = x^-] > 0." /> <br />
                        This is an important idea in the probabilistic method. See <PdfLink data={PdfData.IMO_2023_5} /> for an application.
                    </>
                }, {
                    content: <>
                        Usually, we care about the case where <Inline tex="X" /> is discrete. In this case the above statements are trivial but fundamental tools for the probabilistic method.
                    </>
                }],
        }
    },
    POP: function (depth = 0) {
        return {
            id: 2,
            type: "wikiEntry",
            title: "Power of a point theorem",
            subtitle: "POP",
            altTitles: ["POP", "Intersecting chords theorem", "Intersecting secants theorem"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        Consider a circle <Inline tex="\omega" /> with center <Inline tex="O" /> and radius <Inline tex="r" /> and a point <Inline tex="P." /> We define the power of <Inline tex="P" /> with respect to <Inline tex="\omega" /> as
                        <Block tex="\mathcal{Pow}_\omega(P) := OP^2 - r^2" />
                        POP states that for any line <Inline tex="\mathcal l" /> through <Inline tex="P" /> intersecting <Inline tex="\omega" /> at <Inline tex="A" /> and <Inline tex="B" /> (if <Inline tex="l" /> is a tangent to <Inline tex="\omega" />, then <Inline tex="A =B" />), we have
                        <Block tex="PA \cdot PB = \mathcal{Pow}_\omega(P)," />
                        where we define the signed product <Inline tex="PA \cdot PB" /> to be positive if and only if <Inline tex="P" /> lies strictly outside the segment <Inline tex="AB" />.
                    </>
            },
            proofs: [
                {
                    content: <>
                        If <Inline tex="P" /> lies on the boundary of <Inline tex="\omega" /> the statement is clear. <p />
                        Otherwise, consider at first a line <Inline tex="l" /> such that <Inline tex="PA = \pm PB" />: <p />
                        If <Inline tex="P" /> lies outside of <Inline tex="\omega" /> take <Inline tex="l" /> as a tangent to <Inline tex="\omega" /> through <Inline tex="P" />. Then <Inline tex="A = B = T" />, where <Inline tex="T" /> is the touching point of <Inline tex="l" /> and <Inline tex="\omega" />. <p />
                        If <Inline tex="P" /> lies inside <Inline tex="\omega" /> take <Inline tex="l" /> as the perpendicular line to <Inline tex="OP" /> through <Inline tex="P" />. Then <Inline tex="A = U" /> and <Inline tex="B = V" />, where <Inline tex="V" /> is the reflection of <Inline tex="U" /> over <Inline tex="P" />. <p />
                        The Pythagorean theorem gives the result for this case.
                        For the general case, we use similar triangles <p />
                        <Block tex="PAT\sim PTB" />
                        or <p />
                        <Block tex="PAU\sim PVB." />
                    </>
                }
            ],
            notes: [
                {
                    subtitle: "POP in \"if and only if\" form",
                    content:
                        <>
                            The following stronger equivalence also holds:<p />
                            Choose a point <Inline tex="A \in \omega" /> such that <Inline tex="A \neq P" /> and a point <Inline tex="B \in PA" />. Then,
                            <Block tex="\text{$B$ is the second intersection of $PA$ with $\omega$} \iff PA \cdot PB = \mathcal{Pow}_\omega(P)." />
                            Note that it is necessary to use the directed product <Inline tex="PA \cdot PB" /> here.
                        </>
                },
                {
                    subtitle: "Intersecting chords theorem, Intersecting secants theorem",
                    content:
                        <>
                            The intersecting chords theorem and intersecting secants theorem are corollaries of POP.
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/power-of-point.png")} id="power-of-a-point" />
                            </div>
                            We have
                            <Block tex="PT^2 = PA \cdot PB = PC \cdot PD." />
                            <div className="wiki-img-div">
                                <img src={require("../img/wiki-images/power-of-point-2.png")} id="power-of-a-point-2" />
                            </div>
                            We have
                            <Block tex="PA \cdot PB = PC \cdot PD." />
                        </>
                },
            ],
            examples: [],
            branch: BranchData.G,
        }
    },
    RADICAL_AXIS: function (depth = 0) {
        return {
            id: 81,
            type: "wikiEntry",
            title: "Radical axis",
            altTitles: ["POP", "Power of a point", "Power line"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        The radical axis of two circles <Inline tex="\omega_1" /> and <Inline tex="\omega_2" /> is defined as the set of points <Inline tex="P" /> such that the <WikiInfo depth={depth} data={WikiData.POP}>powers</WikiInfo> of <Inline tex="P" /> with respect to <Inline tex="\omega_1" /> and <Inline tex="\omega_2" /> are equal.<p />
                        The radical axis <Inline tex="g" /> of <Inline tex="\omega_1" /> and <Inline tex="\omega_2" /> is a line. If <Inline tex="\omega_1" /> and <Inline tex="\omega_2" /> intersect, then <Inline tex="g" /> passes through their intersections.
                        <div className="wiki-img-div">
                            <img src={require("../img/wiki-images/radical-axis.png")} id="radical-axis" />
                        </div>
                    </>
            },
            proofs: [{
                content: <>
                    Let <Inline tex="O_1, O_2" /> be the midpoints of <Inline tex="\omega_1, \omega_2," /> respectively. As we move <Inline tex="N" /> along <Inline tex="O_1O_2," /> we have <Block tex="\mathcal{Pow}_{\omega_1}(N) - \mathcal{Pow}_{\omega_2}(N) = C + |NO_1|^2 - |NO_2|^2," />
                    which is strictly monotonic and surjective. So, there is exactly one point on <Inline tex="O_1O_2" /> that lies on <Inline tex="g." /> We denote this point by <Inline tex="M." /> Consider any point <Inline tex="P" /> and let <Inline tex="N" /> be the projection of <Inline tex="P" /> onto <Inline tex="O_1O_2." /> We have
                    <Block tex="|PO_1|^2 - |PO_2|^2 = |NO_1|^2 - |NO_2|^2." />
                    Therefore, <Inline tex="P" /> lies on <Inline tex="g" /> if and only if <Inline tex="P" /> lies on the orthogonal line to <Inline tex="O_1O_2" /> passing through <Inline tex="M." /> <br />
                    If a point <Inline tex="A" /> is an intersection of <Inline tex="\omega_1" /> and <Inline tex="\omega_2," /> then <Block tex="\mathcal{Pow}_{\omega_1}(A) = 0 = \mathcal{Pow}_{\omega_2}(A)." />
                </>
            }],
            examples: [],
            branch: BranchData.G,
        }
    },
    PROJECTIVE_PLANE: function (depth = 0) {
        return {
            id: 82,
            type: "wikiEntry",
            title: "Projective plane",
            altTitles: [],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We define the (real) projective plane as an extension of the euclidean plane by adding
                        <ol>
                            <li>
                                one new point "at infinity" for each class of parallel lines such that each line goes through the point at infinity in its direction
                            </li>
                            <li>
                                one new line "at infinity" that passes through the points at infinity.
                            </li>
                        </ol>
                    </>
            },
            proofs: [],
            examples: [],
            notes: [{
                content: <>
                    In the projective plane, any two distinct lines intersect in exactly one point.
                </>
            },
            {
                subtitle: "points at infinity",
                content: <>
                    For a line <Inline tex="g," /> (that is not the line at infinity) we denote the point at infinity on <Inline tex="g" /> by <Inline tex="\infty_g." /> Therefore, if <Inline tex="h" /> is parallel to <Inline tex="g," /> then <Inline tex="\infty_g = \infty_h." />
                </>
            }],
            branch: BranchData.G,
        }
    },
    CROSS_RATIO: function (depth = 0) {
        return {
            id: 83,
            type: "wikiEntry",
            title: "Cross-ratios and projective transformations",
            altTitles: ["Anharmonic ratio", "Projective maps", "Double ratio"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We define the cross-ratio of four collinear points <Inline tex="A, B, C, D" /> on a line <Inline tex="g" /> in the <WikiInfo depth={depth} data={WikiData.PROJECTIVE_PLANE}>projective plane</WikiInfo> by
                        <Block tex="(A, B; C, D) := \frac{|AC| : |BC|}{|AD| : |BD|}," />
                        where the segment length ratios are signed.<p />
                        We say that a map from a subset of the projective plane to the projective plane is a projective transformation if it preserves the cross-ratios of any four collinear points.
                    </>
            },
            proofs: [],
            notes: [{
                subtitle: "Projective ratio of lines",
                content: <>
                    Let <Inline tex="P" /> be a point that is not collinear with <Inline tex="A, B, C, D." /> Denote lines <Inline tex="AP, BP, CP, DP" /> by <Inline tex="a, b, c, d," /> respectively. We define their cross-ratio by
                    <Block tex="(a, b; c, d) := \frac{\sin(\angle APC) : \sin(\angle BPC)}{\sin(\angle APD) : \sin(\angle BPD)}." />
                    We have <Block tex="(A, B; C, D) = (a, b; c, d)." />
                    If <Inline tex="h" /> is a line not going through <Inline tex="P" /> that intersects <Inline tex="a, b, c, d" /> at <Inline tex="A', B', C', D'," /> it follows that
                    <Block tex="(A', B'; C', D') = (a, b; c, d) = (A, B; C, D)." />
                    Therefore, the projection at <Inline tex="P" /> from <Inline tex="g" /> to <Inline tex="h" /> is a projective transformation.
                    <div className="wiki-img-div">
                        <img src={require("../img/wiki-images/cross-ratios.png")} id="cross-ratios" />
                    </div>
                </>,
                proof: <>
                    By the law of sines,
                    <Block tex="(A, B; C, D) = \frac{\sin(\angle APC) : \sin(\angle BPC)}{\sin(\angle APD) : \sin(\angle BPD)} : \frac{\sin(\angle CAP) : \sin(\angle CBP)}{\sin(\angle DAP) : \sin(\angle DBP)} = (a, b; c, d)." />
                </>
            },
            {
                subtitle: "Projective ratio of concyclic points",
                content: <>
                    Let <Inline tex="A, B; C, D" /> lie on a circle <Inline tex="k." /> We define
                    <Block tex="(A, B; C, D) := (AP, BP; CP, DP)," />
                    where <Inline tex="P" /> lies on <Inline tex="k." /> This is well-defined by the <WikiInfo depth={depth} data={WikiData.INSCRIBED_ANGLE_THEOREM}>inscribed angle theorem</WikiInfo>. As before, by the law of sines and the inscribed angle theorem, we obtain
                    <Block tex="(A, B; C, D) = \frac{|AC| : |BC|}{|AD| : |BD|}." />
                    Note that the lengths are again oriented, dependent on the choice of a point <Inline tex="P \in \omega" />. Here <Inline tex="|XY|" /> is positive if and only if <Inline tex="\angle XPY < 180^\circ." /> The sign of the cross-ratio does not depent on the choice of <Inline tex="P" />. <p /> In this setting, we obtain two more projective maps: The projections at <Inline tex="P" /> from <Inline tex="k" /> to another circle <Inline tex="k'" /> through <Inline tex="P" /> or to a line <Inline tex="g" /> not going through <Inline tex="P." /> For example, if <Inline tex="A', B', C', D'" /> are the intersections of <Inline tex="AP, BP, CP, DP" /> with <Inline tex="g," /> then
                    <Block tex="(A, B; C, D) = (AP, BP; CP, DP) = (A', B'; C', D')." />
                </>
            }, {
                subtitle: "The second intersection with a circle",
                content: <>
                    Let <Inline tex="A, B, C, D" /> lie on a circle <Inline tex="k" /> and let <Inline tex="P" /> not lie on <Inline tex="k." /> Denote the second intersections of <Inline tex="AP, BP, CP, DP" /> with <Inline tex="k" /> by <Inline tex="A', B', C', D'." /> We have
                    <Block tex="(A, B; C, D) = (A', B'; C', D')." />
                    <div className="wiki-img-div">
                        <img src={require("../img/wiki-images/projective-map-circle-circle.png")} id="projective-map-circle-circle" />
                    </div>
                </>,
                proof: <>
                    By the inscribed angle theorem, we get <Block tex="\triangle XYP \sim \triangle Y'X'P" />
                    for <Inline tex="X, Y \in \{A, B, C, D\}" /> distinct. Hence,
                    <Block tex="\frac{(A, B; C, D)}{(A', B'; C', D')} = \frac{(|AP| : |C'P|) : (|BP| : |C'P|)}{(|AP| : |D'P|) : (|BP| : |D'P|)} = 1." />
                </>
            }],
            branch: BranchData.G,
        }
    },
    HARMONIC_POINTS: function (depth = 0) {
        return {
            id: 84,
            type: "wikiEntry",
            title: "Harmonic pairs of points",
            altTitles: ["Harmonic quadrilateral"],
            statement:
            {
                subtitle: undefined,
                content:
                    <>
                        We say that two pairs of points <Inline tex="(A, B), (C, D)" /> in the <WikiInfo depth={depth} data={WikiData.PROJECTIVE_PLANE}>projective plane</WikiInfo> are harmonic if they lie on a line or a circle and if their <WikiInfo depth={depth} data={WikiData.CROSS_RATIO}>cross-ratio</WikiInfo> satisfies
                        <Block tex="(A, B; C, D) = -1." />
                        If <Inline tex="ABCD" /> is concyclic, we say that it is a harmonic quadrilateral.
                    </>
            },
            proofs: [],
            notes: [{
                subtitle: "Uniqueness",
                content: <>
                    Given any three points <Inline tex="A, B, C" /> (such that there is a line or circle passing through them; we have ignored the case of non-collinearity including points at infinity) there is a unique fourth point such that the pairs <Inline tex="(A, B), (C, D)" /> are harmonic.
                </>
            }],
            examples: [{
                subtitle: "The midpoint",
                content: <>
                    If <Inline tex="C" /> is the midpoint of segment <Inline tex="AB," /> then
                    <Block tex="(A, B; C, \infty_{AB}) = -1." />
                </>
            },
            {
                subtitle: "A characterization for harmonic quadrilaterals",
                content: <>
                    Let <Inline tex="A, B, C, D" /> be distinct points on a circle <Inline tex="\omega" /> and let <Inline tex="P" /> be the intersection of the tangents of <Inline tex="\omega" /> at <Inline tex="C" /> and <Inline tex="D." /> Then, the pairs <Inline tex="(A, B), (C, D)" /> are harmonic if and only if <Inline tex="A, B, P" /> are collinear.
                </>,
                proof: <>
                    Let <Inline tex="P'" /> be the intersection of the tangent of <Inline tex="\omega" /> at <Inline tex="C" /> and <Inline tex="AB." /> Let <Inline tex="D'" /> be the second intersection of <Inline tex="\omega" /> and <Inline tex="DP." /> <Inline tex="A, B, P" /> are collinear if and only if <Inline tex="P = P'," /> which is equivalent to <Inline tex="D = D'." /> By projection at <Inline tex="P'," /> we have
                    <Block tex="(A, B; C, D) = (B, A; C, D') = \frac{1}{(A, B; C, D')}." />
                    If <Inline tex="(A, B; C, D) = -1," /> then <Inline tex="(A, B; C, D') = -1," /> implying <Inline tex="D = D'" /> by uniqueness of the fourth point of a harmonic quadrilateral. <br />
                    If <Inline tex="D = D'," /> then
                    <Block tex="(A, B; C, D) = \frac{1}{(A, B; C, D)}" />
                    and since <Inline tex="A, B, C, D" /> are distinct, we have <Inline tex="(A, B; C, D) \neq 1" /> and thus, <Inline tex="(A, B; C, D) = -1." />
                </>
            }],
            branch: BranchData.G,
        }
    },
}


export default WikiData