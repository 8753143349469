import React from 'react';

import {
    ContestData,
    YearData,
    BranchData,
    DifficultyData,
    InfoTagData,
    ProblemButtonData,
} from "./TagData";
import WikiData from "./WikiData";
import { toDate } from "../functions/myTime";
import { Inline, Block } from "../elements/MyMath/MyMath";

import "./data.css";

import MyGraph from "../elements/MyGraph/MyGraph";
import WikiInfo from "../elements/WikiInfo/WikiInfo";
import { FaBullseye } from "react-icons/fa";

const PdfData = {
    ISL_2021_A5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 0,
            problemStatement: (
                <div>
                    Let <Inline tex="n \geq 2" /> be an integer, and let{" "}
                    <Inline tex="a_1, a_2, \dots, a_n" /> be positive real numbers such
                    that <Inline tex="a_1 + a_2 + \dots + a_n = 1." /> Prove that{" "}
                    <Block tex="\sum_{k=1}^n \frac{a_k}{1-a_k}(a_1 + a_2 + \dots + a_{k-1})^2 < \frac{1}{3}." />
                </div>
            ),
            thumbnailStatement: undefined,
            title: "ISL 2021/A5",
            thumbnailTitle: undefined,
            fileName: "ISL2021-A5.pdf",
            contest: ContestData.ISL,
            year: YearData(2021),
            branch: BranchData.A,
            difficulty: DifficultyData(11),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.PROBABILITIES],
            problemCode: [],
            releaseTime: toDate(2022, 9, 17, 15, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/M-FGvlrMdWg",
                    title: "An unexpected solution in the IMO Shortlist",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2021_N1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 1,
            problemStatement: (
                <div>
                    Determine all integers <Inline tex="n \geq 1" /> for which there
                    exists a pair of positive integers <Inline tex="(a, b)" /> such that
                    no cube of a prime divides <Inline tex="a^2+b+3" /> and{" "}
                    <Block tex="\frac{ab+3b+8}{a^2+b+3} = n." />
                </div>
            ),
            thumbnailStatement: undefined,
            title: "ISL 2021/N1",
            thumbnailTitle: undefined,
            fileName: "ISL2021-N1.pdf",
            contest: ContestData.ISL,
            year: YearData(2021),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 9, 23, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/FJUF_PEDEss",
                    title: "One short problem from the IMO Shortlist",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CNO_2019_3: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 2,
            problemStatement: (
                <div>
                    Let <Inline tex="S" /> be a set, <Inline tex="|S|=35." /> A set{" "}
                    <Inline tex="F" /> of mappings from <Inline tex="S" /> to itself is
                    called to be satisfying property <Inline tex="P(k)," /> if for any{" "}
                    <Inline tex="x,y\in S," /> there exist{" "}
                    <Inline tex="f_1, \cdots, f_k \in F" /> (not necessarily different),
                    such that{" "}
                    <Block tex="f_k(f_{k-1}(\cdots (f_1(x))))=f_k(f_{k-1}(\cdots (f_1(y))))." />
                    Find the least positive integer <Inline tex="m," /> such that if{" "}
                    <Inline tex="F" /> satisfies property <Inline tex="P(2019)," /> then
                    it also satisfies property <Inline tex="P(m)." />
                </div>
            ),
            thumbnailStatement: undefined,
            title: "CNO 2019/3",
            thumbnailTitle: undefined,
            fileName: "CNO2019-3.pdf",
            contest: ContestData.CNO,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(11),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2022, 9, 30, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/sf7enNjM3pw",
                    title: "A lot of functions in the Chinese national olympiad",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    APMO_2019_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 3,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb{Z}^+" /> be the set of positive integers.
                    Determine all functions{" "}
                    <Inline tex="f : \mathbb{Z}^+\to\mathbb{Z}^+" /> such that{" "}
                    <Inline tex="a^2+f(a)f(b)" /> is divisible by <Inline tex="f(a)+b " />{" "}
                    for all positive integers <Inline tex="a,b." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "APMO 2019/1",
            thumbnailTitle: undefined,
            fileName: "APMO2019-1.pdf",
            contest: ContestData.APMO,
            year: YearData(2019),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY, InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 10, 11, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/5Ki2wXtLT-c",
                    title: "Divisibility arguments on a function",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2022_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 4,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb{R}^+" /> denote the set of positive real
                    numbers. Find all functions{" "}
                    <Inline tex="f : \mathbb R^+ \to \mathbb R^+" /> such that for each{" "}
                    <Inline tex="x \in \mathbb R^+," /> there is exactly one{" "}
                    <Inline tex="y \in \mathbb R^+" /> satisfying{" "}
                    <Block tex="xf(y) + yf(x) \leq 2." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "IMO 2022/2",
            thumbnailTitle: undefined,
            fileName: "IMO2022-2.pdf",
            contest: ContestData.IMO,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(9),
            wikiCitations: [WikiData.AM_GM_INEQUALITY],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 9, 17, 15, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/aJJg7bpFilU",
                    title: "A new type of functional equation",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2022_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 5,
            problemStatement: (
                <>
                    Let <Inline tex="ABCDE" /> be a convex pentagon such that{" "}
                    <Inline tex="BC=DE." /> Assume that there is a point{" "}
                    <Inline tex="T" /> inside <Inline tex="ABCDE" /> with{" "}
                    <Inline tex="TB = TD," /> <Inline tex="TC = TE" /> and{" "}
                    <Inline tex="\angle ABT = \angle TEA." /> Let line <Inline tex="AB" />{" "}
                    intersect lines <Inline tex="CD" /> and <Inline tex="CT" /> at points{" "}
                    <Inline tex="P" /> and <Inline tex="Q," /> respectively. Assume that
                    the points <Inline tex="P," /> <Inline tex="B," /> <Inline tex="A," />{" "}
                    <Inline tex="Q" /> occur on their line in that order. Let line{" "}
                    <Inline tex="AE" /> intersect lines <Inline tex="CD" /> and{" "}
                    <Inline tex="DT" /> at points <Inline tex="R" /> and{" "}
                    <Inline tex="S," /> respectively. Assume that the points{" "}
                    <Inline tex="R," /> <Inline tex="E," /> <Inline tex="A," />{" "}
                    <Inline tex="S" /> occur on their line in that order. Prove that the
                    points <Inline tex="P," /> <Inline tex="S," /> <Inline tex="Q," />{" "}
                    <Inline tex="R" /> lie on a circle.
                </>
            ),
            thumbnailStatement: undefined,
            title: "IMO 2022/4",
            thumbnailTitle: undefined,
            fileName: "IMO2022-4.pdf",
            contest: ContestData.IMO,
            year: YearData(2022),
            branch: BranchData.G,
            difficulty: DifficultyData(7),
            wikiCitations: [WikiData.POP],
            infoTags: [InfoTagData.ANGLE_CHASING, InfoTagData.LENGTHS_AND_RATIOS, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2022, 9, 27, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/1Ks8xhev-Yg",
                    title: "The only geometry problem in this year's IMO",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2021_G4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 6,
            problemStatement: (
                <>
                    Let <Inline tex="ABCD" /> be a quadrilateral inscribed in a circle{" "}
                    <Inline tex="\Omega." /> Let the tangent to <Inline tex="\Omega" /> at{" "}
                    <Inline tex="D" /> meet rays <Inline tex="BA" /> and{" "}
                    <Inline tex="BC" /> at <Inline tex="E" /> and <Inline tex="F," />
                    respectively. A point <Inline tex="T" /> is chosen inside{" "}
                    <Inline tex="\triangle ABC" /> so that{" "}
                    <Inline tex="\overline{TE}\parallel\overline{CD}" /> and{" "}
                    <Inline tex="\overline{TF}\parallel\overline{AD}." /> Let{" "}
                    <Inline tex="K\ne D" /> be a point on segment <Inline tex="DF" />{" "}
                    satisfying <Inline tex="TD=TK." /> Prove that lines{" "}
                    <Inline tex="AC" /> <Inline tex="DT," /> and <Inline tex="BK" /> are
                    concurrent.
                </>
            ),
            thumbnailStatement: undefined,
            title: "ISL 2021/G4",
            thumbnailTitle: undefined,
            fileName: "ISL2021-G4.pdf",
            contest: ContestData.ISL,
            year: YearData(2021),
            branch: BranchData.G,
            difficulty: DifficultyData(11),
            wikiCitations: [],
            infoTags: [InfoTagData.LENGTHS_AND_RATIOS, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2022, 10, 18, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/6MQlcrVvdQs",
                    title: "A lot of parallel lines",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    SWISS_TST_2022_6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 7,
            problemStatement: (
                <>
                    Let <Inline tex="n \geq 2" /> be an integer. Prove that if
                    <Block tex="\frac{n^2+4^n+7^n}{n}" />
                    is an integer, then it is divisible by <Inline tex="11." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "Swiss TST 2022/6",
            thumbnailTitle: undefined,
            fileName: "SwissTST2022-6.pdf",
            contest: ContestData.Swiss_TST,
            year: YearData(2022),
            branch: BranchData.N,
            difficulty: DifficultyData(9),
            wikiCitations: [
                WikiData.MULTIPLICATIVE_ORDER,
                WikiData.FERMATS_LITTLE_THEOREM,
                WikiData.LTE_LEMMA,
            ],
            infoTags: [InfoTagData.DIVISIBILITY, InfoTagData.NT_FUNCTION],
            problemCode: [],
            releaseTime: toDate(2022, 10, 4, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/EoupbXjF0GA",
                    title: "Some cool divisibility arguments",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USAMO_2022_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 8,
            problemStatement: (
                <div>
                    Find all pairs of primes <Inline tex="(p, q)" /> for which{" "}
                    <Inline tex="p − q" /> and <Inline tex="pq − q" /> are both perfect
                    squares.
                </div>
            ),
            thumbnailStatement: undefined,
            title: "USAMO 2022/4",
            thumbnailTitle: undefined,
            fileName: "USAMO2022-4.pdf",
            contest: ContestData.USAMO,
            year: YearData(2022),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION, InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2022, 9, 20, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/zrCuThiuBnM",
                    title: "Factorization and bounding for the win",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2019_1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 9,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb{Z}" /> be the set of integers. Determine all
                    functions <Inline tex="f: \mathbb{Z} \rightarrow \mathbb{Z}" /> such
                    that, for all integers <Inline tex="a" /> and <Inline tex="b," />{" "}
                    <Block tex="f(2a)+2f(b)=f(f(a+b))." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "IMO 2019/1",
            thumbnailTitle: undefined,
            fileName: "IMO2019-1.pdf",
            contest: ContestData.IMO,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION, InfoTagData.FACTORIZATION],
            problemCode: [],
            releaseTime: toDate(2022, 10, 7, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/Q1Nsrb0j7EI",
                    title: "A functional equation in the integers",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CNO_2019_5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 10,
            problemStatement: (
                <>
                    Given any positive integer <Inline tex="c," /> denote{" "}
                    <Inline tex="p(c)" /> as the largest prime factor of{" "}
                    <Inline tex="c." /> A sequence <Inline tex="\{a_n\}" /> of positive
                    integers satisfies <Inline tex="a_1>1" /> and{" "}
                    <Inline tex="a_{n+1}=a_n+p(a_n)" /> for all <Inline tex="n\ge 1." />
                    Prove that there must exist at least one perfect square in the
                    sequence <Inline tex="\{a_n\}." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "CNO 2019/5",
            thumbnailTitle: undefined,
            fileName: "CNO2019-5.pdf",
            contest: ContestData.CNO,
            year: YearData(2019),
            branch: BranchData.N,
            difficulty: DifficultyData(12),
            wikiCitations: [WikiData.EUCLIDS_THEOREM],
            infoTags: [InfoTagData.NT_FUNCTION, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2022, 10, 21, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/agI_e7t3yag",
                    title: "Find the square in the sequence",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CNO_2020_5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 11,
            problemStatement: (
                <>
                    Let <Inline tex="P" /> be a convex polyhedron such that
                    <ol className="condition-ol">
                        <li>
                            {" "}
                            every vertex belongs to exactly <Inline tex="3" /> faces,
                        </li>
                        <li>
                            {" "}
                            for every natural number <Inline tex="n," /> there is an even
                            number of faces with <Inline tex="n" /> vertices.
                        </li>
                    </ol>
                    An ant walks along the edges of <Inline tex="P" /> and forms a
                    non-self-intersecting cycle, which divides the faces of this
                    polyhedron into two sides, such that for every natural number{" "}
                    <Inline tex="n," /> the number of faces with <Inline tex="n" />{" "}
                    vertices on each side are the same.
                    <br />
                    <br />
                    Show that the number of times the ant turns left is the same as the
                    number of times the ant turns right.
                </>
            ),
            thumbnailStatement: undefined,
            title: "CNO 2020/5",
            thumbnailTitle: undefined,
            fileName: "CNO2020-5.pdf",
            contest: ContestData.CNO,
            year: YearData(2020),
            branch: BranchData.C,
            difficulty: DifficultyData(11),
            wikiCitations: [
                WikiData.EULER_CHARACTERISTIC,
                WikiData.HANDSHAKING_LEMMA,
            ],
            infoTags: [InfoTagData.GRAPH_THEORY],
            problemCode: [],
            releaseTime: toDate(2022, 10, 14, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/hJbpCTd_jTU",
                    title: "Some cool arguments on a polyhedron",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2020_N2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 12,
            problemStatement: (
                <>
                    For each prime <Inline tex="p," /> construct a graph{" "}
                    <Inline tex="G_p" /> on <Inline tex="\{1,2,\ldots p\}," /> where{" "}
                    <Inline tex="m\neq n" /> are adjacent if and only if{" "}
                    <Inline tex="p" /> divides <Inline tex="(m^{2} + 1-n)(n^{2} + 1-m)" />
                    . Prove that <Inline tex="G_p" /> is disconnected for infinitely many{" "}
                    <Inline tex="p." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "ISL 2020/N2",
            thumbnailTitle: undefined,
            fileName: "ISL2020-N2.pdf",
            contest: ContestData.ISL,
            year: YearData(2020),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [
                WikiData.TREE_GRAPH,
                WikiData.PRIME_FIELD,
                WikiData.EULERS_CRITERION,
                WikiData.LAW_OF_QUADRATIC_RECIPROCITY,
                WikiData.DIRICHLETS_THEOREM_PRIMES,
                WikiData.EUCLIDS_THEOREM,
            ],
            infoTags: [
                InfoTagData.GRAPH_THEORY,
                InfoTagData.NT_FUNCTION,
                InfoTagData.FACTORIZATION,
                InfoTagData.DIVISIBILITY,
                InfoTagData.PRIME_FACTORS,
            ],
            problemCode: [
                {
                    thumbnailStatement: undefined,

                    thumbnailTitle: undefined,
                    fileName: "ISL2020-N2.cpp",
                    title:
                        "c++ program for calculating G_p and determining whether it is connected",
                },
            ],
            releaseTime: toDate(2022, 10, 28, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/kiOFrq_-EOU",
                    title: "From graphs to number theory",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2019_A2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 13,
            problemStatement: (
                <>
                    Let <Inline tex="u_1, u_2, \dots, u_{2019}" /> be real numbers
                    satisfying
                    <Block tex="u_{1}+u_{2}+\cdots+u_{2019}=0 \quad \text { and } \quad u_{1}^{2}+u_{2}^{2}+\cdots+u_{2019}^{2}=1." />
                    Let{" "}
                    <Inline tex="a=\min \left(u_{1}, u_{2}, \ldots, u_{2019}\right)" />{" "}
                    and{" "}
                    <Inline tex="b=\max \left(u_{1}, u_{2}, \ldots, u_{2019}\right)." />
                    Prove that
                    <Block tex="a b \leqslant-\frac{1}{2019}." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "ISL 2019/A2",
            thumbnailTitle: undefined,
            fileName: "ISL2019-A2.pdf",
            contest: ContestData.ISL,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2022, 11, 18, 11, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/JoUDGXHppeY",
                    title: "Two solutions to a nice inequality",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    EGMO_2021_6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 14,
            problemStatement: (
                <>
                    Does there exist a nonnegative integer <Inline tex="a" /> for which
                    the equation
                    <Block tex="\left\lfloor\frac{m}{1}\right\rfloor + \left\lfloor\frac{m}{2}\right\rfloor + \left\lfloor\frac{m}{3}\right\rfloor + \cdots + \left\lfloor\frac{m}{m}\right\rfloor = n^2 + a" />
                    has more than one million different solutions <Inline tex="(m, n)" />{" "}
                    where <Inline tex="m" /> and <Inline tex="n" /> are positive integers?
                    The expression <Inline tex="\lfloor x\rfloor" /> denotes the integer
                    part (or floor) of the real number <Inline tex="x." /> Thus
                    <Block tex="\lfloor\sqrt{2}\rfloor = 1, \lfloor\pi\rfloor =\lfloor 22/7 \rfloor = 3, \lfloor 42\rfloor = 42, \quad \textnormal{and} \quad \lfloor 0 \rfloor = 0." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "EGMO 2021/6",
            thumbnailTitle: undefined,
            fileName: "EGMO2021-6.pdf",
            contest: ContestData.EGMO,
            year: YearData(2021),
            branch: BranchData.N,
            difficulty: DifficultyData(11),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 10, 25, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/GlX7b9mFQBM",
                    title: "An equation with three unknowns",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_1986_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 15,
            problemStatement: (
                <>
                    Let <Inline tex="d" /> be any positive integer not equal to{" "}
                    <Inline tex="2, 5" /> or <Inline tex="13." /> Show that one can find
                    distinct <Inline tex="a,b" /> in the set <Inline tex="\{2,5,13,d\}" />{" "}
                    such that <Inline tex="ab-1" /> is not a perfect square.
                </>
            ),
            thumbnailStatement: undefined,
            title: "IMO 1986/1",
            thumbnailTitle: undefined,
            fileName: "IMO1986-1.pdf",
            contest: ContestData.IMO,
            year: YearData(1986),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 11, 1, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/JS6xuvdYd5Y",
                    title: "We can always find a non-perfect square",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    MEMO_2022_T7: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 16,
            problemStatement: (
                <>
                    Determine all functions{" "}
                    <Inline tex="f : \mathbb {N} \rightarrow \mathbb {N}" /> such that{" "}
                    <Inline tex="f" /> is increasing (not necessarily strictly) and the
                    numbers <Inline tex="f(n)+n+1" /> and <Inline tex="f(f(n))-f(n)" />{" "}
                    are both perfect squares for every positive integer <Inline tex="n" />
                    .
                </>
            ),
            thumbnailStatement: undefined,
            title: "MEMO 2022/T7",
            thumbnailTitle: undefined,
            fileName: "MEMO2022-T7.pdf",
            contest: ContestData.MEMO,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 11, 8, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A function with  squares",
                    url: "https://youtu.be/X0zqfk8v-h4",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    India_EGMO_TST_2022_1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 17,
            problemStatement: (
                <>
                    Let <Inline tex="n\ge 3" /> be an integer, and suppose{" "}
                    <Inline tex="x_1,x_2,\cdots ,x_n" /> are positive real numbers such
                    that <Inline tex="x_1+x_2+\cdots +x_n=1." /> Prove that
                    <Block tex="x_1^{1-x_2}+x_2^{1-x_3}\cdots+x_{n-1}^{1-x_n}+x_n^{1-x_1}<2. " />
                </>
            ),
            thumbnailStatement: undefined,
            title: "India EGMO TST 2022/1",
            thumbnailTitle: undefined,
            fileName: "IndiaEGMOTST2022-1.pdf",
            contest: ContestData.India_EGMO_TST,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [WikiData.BERNOULLIS_INEQUALITY, WikiData.WEIGHTED_AM_GM],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2022, 11, 4, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/uP9YosvfC_E",
                    title: "How to handle irrational exponents",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    Bulgaria_NMO_2022_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 18,
            problemStatement: (
                <>
                    Let <Inline tex="n\geq 4" /> be a positive integer and{" "}
                    <Inline tex="x_{1},x_{2},\ldots ,x_{n} \in \mathbb R." /> Let{" "}
                    <Inline tex="x_{i + kn} = x_i" /> for{" "}
                    <Inline tex="1\leq i\leq n, k \in \mathbb Z." /> If there exists an{" "}
                    <Inline tex="a>0" /> such that
                    <Block tex="x_{i}^2=a+x_{i+1}x_{i+2}\quad\forall 1\leq i\leq n," />
                    then prove that at least <Inline tex="2" /> of the numbers{" "}
                    <Inline tex="x_{1},x_{2},\ldots ,x_{n}" /> are negative.
                </>
            ),
            thumbnailStatement: undefined,
            title: "Bulgaria NMO 2022/4",
            thumbnailTitle: undefined,
            fileName: "BulgariaNMO2022-4.pdf",
            contest: ContestData.Bulgaria_NMO,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2022, 11, 11, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A recursion with some negative terms",
                    url: "https://youtu.be/3f7e8sW52og",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USA_TST_2021_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 19,
            problemStatement: (
                <>
                    Determine all integers <Inline tex="s \ge 4" /> for which there exist
                    positive integers <Inline tex="a," /> <Inline tex="b," />{" "}
                    <Inline tex="c," /> <Inline tex="d" /> such that{" "}
                    <Inline tex="s = a+b+c+d" /> and <Inline tex="s" /> divides{" "}
                    <Inline tex="abc+abd+acd+bcd." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "USA TST 2021/1",
            thumbnailTitle: undefined,
            fileName: "USATST2021-1.pdf",
            contest: ContestData.USA_TST,
            year: YearData(2021),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.FACTORIZATION, InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2022, 11, 20, 11, 0).getTime(),
            youtubeVideos: [
                {
                    title: "An interesting divisibility condition",
                    url: "https://youtu.be/j_iH-YOP91o",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALTIC_WAY_2022_5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 20,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb{R}" /> be the set of real numbers. Determine
                    all functions <Inline tex="f: \mathbb{R} \rightarrow \mathbb{R}" />{" "}
                    such that <Inline tex="f(0)+1=f(1)," /> and for any real numbers{" "}
                    <Inline tex="x" /> and <Inline tex="y," />
                    <Block tex="f(xy-x)+f(x+f(y))=yf(x)+3." />
                </>
            ),
            thumbnailStatement: undefined,
            title: "Baltic Way 2022/5",
            thumbnailTitle: undefined,
            fileName: "BalticWay2022-5.pdf",
            contest: ContestData.BALTIC_WAY,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 12, 2, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A special functional equation",
                    url: "https://youtu.be/Gp8U-vCuMTY",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALTIC_WAY_2022_17: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 21,
            problemStatement: (
                <>
                    Let <Inline tex="n" /> be a positive integer such that the sum of its
                    positive divisors is at least <Inline tex="2022n." /> Prove that{" "}
                    <Inline tex="n" /> has at least <Inline tex="2022" /> distinct prime
                    factors.
                </>
            ),
            thumbnailStatement: (
                <>
                    Consider <Inline tex="n \in \mathbb N" /> such that
                    <Block tex="\sum_{d \mid n} d \geq 2022n." />
                    Prove that <Inline tex="n" /> has at least <Inline tex="2022" />{" "}
                    distinct prime factors.
                </>
            ),
            title: "Baltic Way 2022/17",
            thumbnailTitle: undefined,
            fileName: "BalticWay2022-17.pdf",
            contest: ContestData.BALTIC_WAY,
            year: YearData(2022),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.NT_FUNCTION, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2022, 12, 9, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This number has a lot of different prime divisors",
                    url: "https://youtu.be/sio9uP04BK4",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USA_TST_2014_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 22,
            problemStatement: (
                <>
                    Let <Inline tex="n" /> be a positive even integer, and let{" "}
                    <Inline tex="c_1, c_2, \dots, c_{n-1}" /> be real numbers satisfying
                    <Block tex="\sum_{i=1}^{n-1} \left\lvert c_i-1 \right\rvert < 1." />
                    Prove that
                    <Block tex="2x^n - c_{n-1}x^{n-1} + c_{n-2}x^{n-2} - \dots - c_1x^1 + 2" />
                    has no real roots.
                </>
            ),
            thumbnailStatement: undefined,
            title: "USA TST 2014/4",
            thumbnailTitle: undefined,
            fileName: "USATST2014-4.pdf",
            contest: ContestData.USA_TST,
            year: YearData(2014),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.POLYNOMIALS, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2022, 11, 25, 11, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A polynomial with no real roots",
                    url: "https://youtu.be/CXHGAfc-3u4",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2017_A1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 23,
            problemStatement: (
                <>
                    Let <Inline tex="a_1,a_2,\ldots a_n,k," /> and <Inline tex="M" /> be
                    positive integers such that
                    <Block tex="\frac{1}{a_1}+\frac{1}{a_2}+\cdots+\frac{1}{a_n}=k\quad\text{and}\quad a_1a_2\cdots a_n=M." />
                    If <Inline tex="M>1," /> prove that the polynomial
                    <Block tex="P(x)=M(x+1)^k-(x+a_1)(x+a_2)\cdots (x+a_n)" />
                    has no positive roots.
                </>
            ),
            thumbnailStatement: undefined,
            title: "ISL 2017/A1",
            thumbnailTitle: undefined,
            fileName: "ISL2017-A1.pdf",
            contest: ContestData.ISL,
            year: YearData(2017),
            branch: BranchData.A,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.BERNOULLIS_INEQUALITY],
            infoTags: [InfoTagData.POLYNOMIALS, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2022, 12, 4, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This polynomial has a nice property",
                    url: "https://youtu.be/eYQqIaCi_pQ",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IRAN_MO_2020_2_5: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 24,
            problemStatement: (
                <>
                    Call a pair of integers <Inline tex="a" /> and <Inline tex="b" />{" "}
                    square makers , if <Inline tex="ab+1" /> is a perfect square.
                    Determine for which <Inline tex="n" /> is it possible to divide the
                    set <Inline tex="\{1,2, \dots , 2n\}" /> into <Inline tex="n" /> pairs
                    of square makers.
                </>
            ),
            thumbnailStatement: undefined,
            title: "Iran MO 2020/2/5",
            thumbnailTitle: undefined,
            fileName: "IranMO2020-2-5.pdf",
            contest: ContestData.IRAN_MO,
            year: YearData(2020),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2022, 11, 27, 11, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Building squares from a set of integers",
                    url: "https://youtu.be/s-XFhXIrl0g",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    VNO_2022_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 25,
            problemStatement: (
                <>
                    Find all functions{" "}
                    <Inline tex="f:\mathbb R^+ \rightarrow \mathbb R^+" /> such that
                    <Block tex="f\left(\frac{f(x)}{x}+y\right)=1+f(y) \quad \forall x,y \in \mathbb R^+." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Find all functions <Inline tex="f:\mathbb R^+ \to \mathbb R^+" /> such
                    that
                    <Block tex="f\left(\frac{f(x)}{x}+y\right)=1+f(y)" />
                    holds for all <Inline tex="x, y \in \mathbb R^+." />
                </div>
            ),
            title: "VNO 2022/2",
            thumbnailTitle: undefined,
            fileName: "VNO2022-2.pdf",
            contest: ContestData.VNO,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 12, 11, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "The perfect use of symmetry in a functional equation",
                    url: "https://youtu.be/CqURVIqt3FY",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CMO_2022_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 26,
            problemStatement: (
                <>
                    Let <Inline tex="d(k)" /> denote the number of positive integer
                    divisors of <Inline tex="k." /> For example, <Inline tex="d(6) = 4" />{" "}
                    since <Inline tex="6" /> has <Inline tex="4" /> positive divisors,
                    namely, <Inline tex="1, 2, 3," /> and <Inline tex="6." /> Prove that
                    for all positive integers{" "}
                    <span>
                        <Inline tex="n," />
                    </span>
                    <Block tex="d(1) + d(3) + d(5) +...+ d(2n - 1)\le  d(2) + d(4) + d(6) + ... + d(2n)." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Let <Inline tex="d(k)" /> denote the number of positive integer
                    divisors of <Inline tex="k." /> Prove that for{" "}
                    <Inline tex="n \in \mathbb N," />
                    <div style={{ fontSize: "35px", marginTop: "30px" }}>
                        <Block tex="d(1) + d(3) +...+ d(2n - 1)\le  d(2) + d(4) + ... + d(2n)." />
                    </div>
                </div>
            ),
            title: "CMO 2022/2",
            thumbnailTitle: undefined,
            fileName: "CMO2022-2.pdf",
            contest: ContestData.CMO,
            year: YearData(2022),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [WikiData.DOUBLE_COUNTING],
            infoTags: [InfoTagData.NT_FUNCTION, InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2022, 12, 16, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title:
                        "Changing the point of view solves this problem from the Canada Math Olympiad",
                    url: "https://youtu.be/W5S-mL5JT7I",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2011_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 27,
            problemStatement: (
                <>
                    Let <Inline tex="n > 0" /> be an integer. We are given a balance and{" "}
                    <Inline tex="n" /> weights of weight{" "}
                    <Inline tex="2^0,2^1, \cdots ,2^{n-1}." /> We are to place each of the{" "}
                    <Inline tex="n" /> weights on the balance, one after another, in such
                    a way that the right pan is never heavier than the left pan. At each
                    step we choose one of the weights that has not yet been placed on the
                    balance, and place it on either the left pan or the right pan, until
                    all of the weights have been placed. Determine the number of ways in
                    which this can be done.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div imo2011-4-div">
                    Count the number of ways to place the weights
                    <div className="imo2011-4-block1">
                        <Block tex="2^0,2^1, \cdots ,2^{n-1}" />
                    </div>
                    on a balance in any order, such that the right pan is at no point
                    heavier than the left pan.
                </div>
            ),
            title: "IMO 2011/4",
            thumbnailTitle: undefined,
            fileName: "IMO2011-4.pdf",
            contest: ContestData.IMO,
            year: YearData(2011),
            branch: BranchData.C,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.ENUMERATIVE_COMBINATORICS],
            problemCode: [],
            releaseTime: toDate(2022, 12, 18, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title:
                        "Placing weights onto a balance on the International Math Olympiad",
                    url: "https://youtu.be/YIiXNfR9F4Q",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USAMO_2008_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 28,
            problemStatement: (
                <>
                    At a certain mathematical conference, every pair of mathematicians are
                    either friends or strangers. At mealtime, every participant eats in
                    one of two large dining rooms. Each mathematician insists upon eating
                    in a room which contains an even number of his or her friends. Prove
                    that the number of ways that the mathematicians may be split between
                    the two rooms is a power of two (i.e., is of the form{" "}
                    <Inline tex="2^k" /> for some positive integer <Inline tex="k)." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="usamo2008-6">
                    <div id="usamo2008-6-img-div">
                        <img
                            src={require("../img/thumbnails/USAMO2008-6-TN-2.png")}
                            id="usamo2008-6-img"
                        />
                    </div>
                    There are always <Inline tex="2^k" /> configurations with
                    <br />
                    <Inline tex="2 \mid" />{" "}
                    <span className="usamo2008-6-red">
                        {" "}
                        <Inline tex="\deg" />
                    </span>{" "}
                    <span className="usamo2008-6-bracket">
                        <Inline tex="(" />
                    </span>{" "}
                    <span className="usamo2008-6-red">
                        <Inline tex="v" />{" "}
                    </span>
                    <span className="usamo2008-6-bracket">
                        <Inline tex=")" />
                    </span>
                    &nbsp; and <Inline tex="2 \mid" />{" "}
                    <span className="usamo2008-6-green">
                        {" "}
                        <Inline tex="\deg" />
                    </span>{" "}
                    <span className="usamo2008-6-bracket">
                        <Inline tex="(" />
                    </span>{" "}
                    <span className="usamo2008-6-green">
                        <Inline tex="v" />{" "}
                    </span>
                    <span className="usamo2008-6-bracket">
                        <Inline tex=")" />
                    </span>
                    &nbsp;!
                </div>
            ),
            title: "USAMO 2008/6",
            thumbnailTitle: undefined,
            fileName: "USAMO2008-6.pdf",
            contest: ContestData.USAMO,
            year: YearData(2008),
            branch: BranchData.C,
            difficulty: DifficultyData(14),
            wikiCitations: [WikiData.UNDIRECTED_GRAPH, WikiData.INDUCED_SUBGRAPH],
            infoTags: [
                InfoTagData.GRAPH_THEORY,
                InfoTagData.ENUMERATIVE_COMBINATORICS
            ],
            problemCode: [],
            releaseTime: toDate(2022, 12, 23, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This operation proves a surprising property of graphs",
                    url: "https://youtu.be/rR3iyhQGZ6E",
                },
                {
                    title:
                        "An elegant solution to the most challenging problem from USAMO 2008",
                    url: "https://youtu.be/A5w90STbHec",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    INMO_2015_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 29,
            problemStatement: (
                <>
                    Find all real functions <Inline tex="f: \mathbb{R} \to \mathbb{R}" />{" "}
                    such that
                    <Block tex="f(x^2+yf(x))=xf(x+y)." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Find all <Inline tex="f: \mathbb{R} \to \mathbb{R}" /> such that
                    <div id="INMO2015-3">
                        <Block tex="f(x^2+yf(x))=xf(x+y)." />
                    </div>
                </div>
            ),
            title: "INMO 2015/3",
            thumbnailTitle: undefined,
            fileName: "INMO2015-3.pdf",
            contest: ContestData.INMO,
            year: YearData(2015),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2022, 12, 25, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "How to analyze zeros in functional equations",
                    url: "https://youtu.be/5pzJWksAMBQ",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    JAPAN_MO_2022_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 30,
            problemStatement: (
                <>
                    Find all positive integer pairs <Inline tex="(x,y)" /> satisfying the
                    following equation:
                    <Block tex="3^x-8^y=2xy+1." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="japan-mo-2022-4">
                    Find <Inline tex="x, y \in \mathbb Z" /> with
                    <div id="japan-mo-2022-4-statement">
                        <Block tex="3^x-8^y=2xy+1." />
                    </div>
                </div>
            ),
            title: "Japan MO 2022/4",
            thumbnailTitle: undefined,
            fileName: "JapanMO2022-4.pdf",
            contest: ContestData.JAPAN_MO,
            year: YearData(2022),
            branch: BranchData.N,
            difficulty: DifficultyData(10),
            wikiCitations: [WikiData.P_ADIC_VALUATION, WikiData.LTE_LEMMA],
            infoTags: [
                InfoTagData.DIOPHANTINE_EQUATION,
                InfoTagData.FACTORIZATION,
                InfoTagData.NT_FUNCTION,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 1, 8, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Almost all number theory techniques in one problem",
                    url: "https://youtu.be/R5xuzMybTBU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ITAMO_2019_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 31,
            problemStatement: (
                <>
                    Let <Inline tex="p,q" /> be prime numbers. Prove that if{" "}
                    <Inline tex="p+q^2" /> is a perfect square, then{" "}
                    <Inline tex="p^2+q^n" /> is not a perfect square for any positive
                    integer <Inline tex="n." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="itamo-2019-2">
                    <Inline tex="p" /> and <Inline tex="q" /> primes.
                    <br />
                    <Inline tex="p+q^2" /> perfect square.
                    <p />
                    <div id="itamo-2019-2-statement">
                        Prove: <Inline tex="\,p^2 + q^n" /> no perfect square.
                    </div>
                </div>
            ),
            title: "ITAMO 2019/2",
            thumbnailTitle: undefined,
            fileName: "ITAMO2019-2.pdf",
            contest: ContestData.ITAMO,
            year: YearData(2019),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION, InfoTagData.FACTORIZATION],
            problemCode: [],
            releaseTime: toDate(2023, 2, 12, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Not everything can be a perfect square",
                    url: "https://youtu.be/4KXpLbCxjaU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CMC_2020_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 32,
            problemStatement: (
                <>
                    In triangle <Inline tex="ABC," /> <Inline tex="AB=BC," /> and let{" "}
                    <Inline tex="I" /> be the incentre of <Inline tex="\triangle ABC." />{" "}
                    <Inline tex="M" /> is the midpoint of segment <Inline tex="BI." />{" "}
                    <Inline tex="P" /> lies on segment <Inline tex="AC," /> such that{" "}
                    <Inline tex="AP=3PC." /> <Inline tex="H" /> lies on line{" "}
                    <Inline tex="PI," /> such that <Inline tex="MH\perp PH." />{" "}
                    <Inline tex="Q" /> is the midpoint of the arc <Inline tex="AB" /> of
                    the circumcircle of <Inline tex="\triangle ABC." /> Prove that{" "}
                    <Inline tex="BH\perp QH." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="cmc-2020-1">
                    <div id="usamo2008-6-img-div">
                        <img
                            src={require("../img/thumbnails/CMC2022-2-TN.png")}
                            id="cmc-2020-1-img"
                        />
                    </div>
                </div>
            ),
            title: "CMC 2020/1",
            thumbnailTitle: undefined,
            fileName: "CMC2020-1.pdf",
            contest: ContestData.CMC,
            year: YearData(2020),
            branch: BranchData.G,
            difficulty: DifficultyData(8),
            wikiCitations: [
                WikiData.DIRECTED_ANGLES,
                WikiData.INCENTER_EXCENTER_LEMMA,
            ],
            infoTags: [InfoTagData.LENGTHS_AND_RATIOS, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 1, 1, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A surprising statement in an isosceles triangle",
                    url: "https://youtu.be/-QnuAyYqeNA",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ROMANIA_TST_2012_2_1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 33,
            problemStatement: (
                <>
                    Prove that for any positive integer <Inline tex="n\geq 2," /> we have
                    that
                    <Block tex="\sum_{k=2}^n \lfloor \sqrt[k]{n}\rfloor=\sum_{k=2}^n\lfloor\log_{k}n\rfloor." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="romania-tst-2012-2-1">
                    <Block tex="\sum_{k=2}^n \lfloor \sqrt[k]{n}\rfloor \stackrel{!}{=}\sum_{k=2}^n\lfloor\log_{k}n\rfloor" />
                </div>
            ),
            title: "Romania TST 2012/2.1",
            thumbnailTitle: undefined,
            fileName: "RomaniaTST2012-2-1.pdf",
            contest: ContestData.ROMANIA_TST,
            year: YearData(2012),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [WikiData.DOUBLE_COUNTING],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2023, 1, 27, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Think about such problems in another way",
                    url: "https://youtu.be/MmNC8sHd_fU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    GMO_2019_3_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 34,
            problemStatement: (
                <>
                    Let <Inline tex="a_1, a_2, a_3, \dots" /> be an increasing integer
                    sequence such that
                    <Block tex="a_{m \cdot n} = a_m \cdot a_n \quad \forall m, n \geq 1" />
                    and <Inline tex="a_{59} = 59." /> Prove that{" "}
                    <Inline tex="a_{2019} = 2019." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="gmo-2019-3-6">
                    <Inline tex="a_1, a_2, a_3, \dots" /> increasing integer sequence{" "}
                    <br />
                    <Inline tex="a_{m \cdot n} = a_m \cdot a_n\," /> and{" "}
                    <Inline tex="\,a_{59} = 59" /> <p />
                    <div id="gmo-2019-3-6-statement">
                        Prove: <Inline tex="\,a_{2019} = 2019" />
                    </div>
                </div>
            ),
            title: "GMO 2019/3.6",
            thumbnailTitle: undefined,
            fileName: "GMO2019-3-6.pdf",
            contest: ContestData.GMO,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2023, 2, 10, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "How to deal with integer sequences",
                    url: "https://youtu.be/5N6CyRvrzJI",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    OLYMPIAD_PROBLEM_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 35,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a triangle with <Inline tex="AB = AC" />{" "}
                    and <Inline tex="\sphericalangle BAC = 20^\circ." /> Let{" "}
                    <Inline tex="D" /> be point on the side <Inline tex="AB" /> such that{" "}
                    <Inline tex="\sphericalangle BCD = 70^\circ." /> Let{" "}
                    <Inline tex="E" /> be point on the side <Inline tex="AC" /> such that{" "}
                    <Inline tex="\sphericalangle CBE = 60^\circ." /> Determine the value
                    of angle <Inline tex="\sphericalangle CDE." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="cmc-2020-1">
                    <div id="usamo2008-6-img-div">
                        <img src={require("../img/thumbnails/OlympiadProblem1-TN.png")} />
                    </div>
                </div>
            ),
            title: "Olympiad Problem 1",
            thumbnailTitle: (
                <div id="olympiad-problem-1-title">A very famous olympiad problem</div>
            ),
            fileName: "OlympiadProblem1.pdf",
            contest: ContestData.OLYMPIAD_PROBLEMS,
            year: YearData(2022),
            branch: BranchData.G,
            difficulty: DifficultyData(7),
            wikiCitations: [WikiData.INSCRIBED_ANGLE_THEOREM],
            infoTags: [InfoTagData.ANGLE_CHASING, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 2, 5, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A very famous olympiad geometry problem",
                    url: "https://youtu.be/OmmDRZBlOlI",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BENELUX_2022_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 36,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a scalene acute triangle. Let{" "}
                    <Inline tex="B_1" /> be the point on ray <Inline tex="[AC" /> such
                    that <Inline tex="|AB_1|=|BB_1|." /> Let <Inline tex="C_1" /> be the
                    point on ray <Inline tex="[AB" /> such that{" "}
                    <Inline tex="|AC_1|=|CC_1|." /> Let <Inline tex="B_2" /> and{" "}
                    <Inline tex="C_2" /> be the points on line <Inline tex="BC" /> such
                    that <Inline tex="|AB_2|=|CB_2|" /> and <Inline tex="|BC_2|=|AC_2|" />
                    . Prove that <Inline tex="B_1," /> <Inline tex="C_1," />{" "}
                    <Inline tex="B_2," /> <Inline tex="C_2" /> are concyclic.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="cmc-2020-1">
                    <div id="usamo2008-6-img-div">
                        <img
                            src={require("../img/thumbnails/Benelux2022-3-TN.png")}
                            id="cmc-2020-1-img"
                        />
                    </div>
                </div>
            ),
            title: "Benelux 2022/3",
            thumbnailTitle: undefined,
            fileName: "Benelux2022-3.pdf",
            contest: ContestData.BENELUX,
            year: YearData(2022),
            branch: BranchData.G,
            difficulty: DifficultyData(5),
            wikiCitations: [
                WikiData.DIRECTED_ANGLES,
                WikiData.TANGENT_CHORD_THEOREM,
                WikiData.POP,
            ],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 1, 15, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A short geometry problem from the Benelux Math Olympiad",
                    url: "https://youtu.be/CflsSrErskQ",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2015_N1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 37,
            problemStatement: (
                <>
                    Determine all positive integers <Inline tex="M" /> such that the
                    sequence <Inline tex="a_0, a_1, a_2, \dots" /> defined by{" "}
                    <Block tex=" a_0 = M + \frac{1}{2}   \qquad  \textrm{and} \qquad    a_{k+1} = a_k\lfloor a_k \rfloor   \quad \textrm{for} \, k = 0, 1, 2, \cdots " />
                    contains at least one integer term.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="isl-2015-n1">
                    When does <Inline tex="a_0, a_1, a_2, \dots\," /> defined by
                    <span id="isl-2015-n1-block">
                        <Block tex=" a_0 = M + \frac{1}{2}   \quad  \textrm{and} \quad    a_{k+1} = a_k\lfloor a_k \rfloor" />
                    </span>
                    contain an integer?
                </div>
            ),
            title: "ISL 2015/N1",
            thumbnailTitle: undefined,
            fileName: "ISL2015-N1.pdf",
            contest: ContestData.ISL,
            year: YearData(2015),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE, InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2023, 1, 6, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "In this sequence, we always find an integer",
                    url: "https://youtu.be/HVrNQHt3rdw",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    Bulgaria_NMO_2014_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 38,
            problemStatement: (
                <>
                    Find all pairs of prime numbers <Inline tex="p\,,q" /> for which
                    <Block tex="p^2 \mid q^3 + 1 \quad \text{and} \quad q^2 \mid p^6-1" />
                    holds.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="bulgaria-nmo-2014-4">
                    Find all primes <Inline tex="p, q" /> such that
                    <span id="bulgaria-nmo-2014-4-block">
                        <Block tex="p^2 \mid q^3 + 1 \quad \text{and} \quad q^2 \mid p^6-1." />
                    </span>
                </div>
            ),
            title: "Bulgaria NMO 2014/4",
            thumbnailTitle: undefined,
            fileName: "BulgariaNMO2014-4.pdf",
            contest: ContestData.Bulgaria_NMO,
            year: YearData(2014),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [
                InfoTagData.DIVISIBILITY,
                InfoTagData.FACTORIZATION,
                InfoTagData.PRIME_FACTORS,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 2, 3, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "How to deal with divisibility conditions",
                    url: "https://youtu.be/HSsvNDflK10",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2019_A4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 39,
            problemStatement: (
                <>
                    Let <Inline tex="n\geqslant 2" /> be a positive integer and{" "}
                    <Inline tex="a_1,a_2, \ldots ,a_n" /> be real numbers such that{" "}
                    <Block tex="a_1+a_2+\dots+a_n=0." />
                    Define the set <Inline tex="A" /> by
                    <Block tex="A=\left\{(i, j)\,|\,1 \leqslant i<j \leqslant n,\left|a_{i}-a_{j}\right| \geqslant 1\right\}" />
                    Prove that, if <Inline tex="A" /> is not empty, then
                    <Block tex="\sum_{(i, j) \in A} a_{i} a_{j}<0." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="isl2014-a4">
                    Given <Inline tex="a_1,a_2, \ldots ,a_n \in \mathbb R" /> with sum{" "}
                    <Inline tex="0" /> and that
                    <Block tex="A := \left\{(i, j)\,|\,1 \leqslant i<j \leqslant n,\left|a_{i}-a_{j}\right| \geqslant 1\right\}" />
                    is not empty. Prove that
                    <span id="isl2014-a4-block">
                        <Block tex="\sum_{(i, j) \in A} a_{i} a_{j}<0." />
                    </span>
                </div>
            ),
            title: "ISL 2019/A4",
            thumbnailTitle: undefined,
            fileName: "ISL2019-A4.pdf",
            contest: ContestData.ISL,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 1, 13, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Invert the statement and magic happens",
                    url: "https://youtu.be/jGNkXNoto_U",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    SERBIA_MO_2016_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 40,
            problemStatement: (
                <>
                    Let <Inline tex="a_1, a_2, \dots, a_{2^{2016}}" /> be positive
                    integers not bigger than <Inline tex="2016." /> We know that for each{" "}
                    <Inline tex="n \leq 2^{2016}," />{" "}
                    <Block tex="a_1a_2 \cdots a_{n} +1" /> is a perfect square. Prove that
                    for some <Inline tex="i" /> , <Inline tex="a_i=1." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="serbiamo-2016-6">
                    Let{" "}
                    <Inline tex="a_1, a_2, \dots, a_{2^{2016}} \in \{2, \dots, 2016\}." />
                    Prove that
                    <span id="serbiamo-2016-6-block">
                        <Block tex="a_1a_2 \cdots a_n +1" />
                    </span>
                    is not a perfect square for some <Inline tex="n \leq 2^{2016}." />
                </div>
            ),
            title: "Serbia MO 2016/6",
            thumbnailTitle: undefined,
            fileName: "SerbiaMO2016-6.pdf",
            contest: ContestData.SERBIA_MO,
            year: YearData(2016),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2023, 1, 20, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This number is always a perfect square",
                    url: "https://youtu.be/FpprIyA0VW0",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALTIC_WAY_2018_20: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 41,
            problemStatement: (
                <>
                    Find all the triples of positive integers <Inline tex="(a,b,c)" /> for
                    which the number
                    <Block tex="\frac{(a+b)^4}{c}+\frac{(b+c)^4}{a}+\frac{(c+a)^4}{b}" />
                    is an integer and <Inline tex="a+b+c" /> is a prime.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="serbiamo-2016-6">
                    Find all the triples of positive integers <Inline tex="(a,b,c)" /> for
                    which the number
                    <Block tex="\frac{(a+b)^4}{c}+\frac{(b+c)^4}{a}+\frac{(c+a)^4}{b}" />
                    is an integer and <Inline tex="a+b+c" /> is a prime.
                </div>
            ),
            title: "Baltic Way 2018/20",
            thumbnailTitle: undefined,
            fileName: "BalticWay2018-20.pdf",
            contest: ContestData.BALTIC_WAY,
            year: YearData(2018),
            branch: BranchData.N,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2023, 1, 22, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This expression should give us an integer",
                    url: "https://youtu.be/b3Piv1PEaY0",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BELARUS_TST_2016_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 42,
            problemStatement: (
                <>
                    Solve the equation <Block tex="p^3-q^3=pq^3-1" /> in primes{" "}
                    <Inline tex="p,q." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="serbiamo-2016-6">
                    Solve the equation
                    <span id="belarus-tst-2016-3-block">
                        <Block tex="p^3-q^3=pq^3-1" />
                    </span>
                    in primes <Inline tex="p,q." />
                </div>
            ),
            title: "Belarus TST 2016/3",
            thumbnailTitle: undefined,
            fileName: "BelarusTST2016-3.pdf",
            contest: ContestData.BELARUS_TST,
            year: YearData(2016),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION, InfoTagData.FACTORIZATION],
            problemCode: [],
            releaseTime: toDate(2023, 1, 29, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "The power of factoring",
                    url: "https://youtu.be/3oXGUU4Xa8A",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2009_5: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 43,
            problemStatement: (
                <>
                    Determine all functions{" "}
                    <Inline tex="f : \mathbb Z^+ \to \mathbb Z^+" /> such that, for all
                    positive integers <Inline tex="a" /> and <Inline tex="b," /> there
                    exists a non-degenerate triangle with sides of lengths
                    <Block tex="a, f(b) \quad \text{and} \quad f(b + f(a) - 1)." />
                    (A triangle is non-degenerate if its vertices are not collinear.)
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    For which functions <Inline tex="f" /> are
                    <div id="imo-2009-5-block">
                        <Block tex="a, f(b) \quad \text{and} \quad f(b + f(a) - 1)" />
                    </div>
                    always the sides of a triangle?
                </div>
            ),
            title: "IMO 2009/5",
            thumbnailTitle: undefined,
            fileName: "IMO2009-5.pdf",
            contest: ContestData.IMO,
            year: YearData(2009),
            branch: BranchData.A,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 2, 19, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This function forms triangles",
                    url: "https://youtu.be/lKGbpsbWpCQ",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2014_1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 44,
            problemStatement: (
                <>
                    Let <Inline tex="a_0 < a_1 < a_2 \ldots" /> be an infinite sequence of
                    positive integers. Prove that there exists a unique integer{" "}
                    <Inline tex="n\geq 1" /> such that
                    <Block tex="a_n < \frac{a_0+a_1+a_2+\cdots+a_n}{n} \leq a_{n+1}." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Let <Inline tex="a_0 < a_1 < a_2 < \dots" /> be positive integers.
                    Prove that exactly one index <Inline tex="n \geq 1" /> satisfies
                    <div id="imo-2014-1-block">
                        <Block tex="a_n < \frac{a_0+a_1+\dots+a_n}{n} \leq a_{n+1}." />
                    </div>
                </div>
            ),
            title: "IMO 2014/1",
            thumbnailTitle: undefined,
            fileName: "IMO2014-1.pdf",
            contest: ContestData.IMO,
            year: YearData(2014),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2023, 2, 17, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This inequality is true at exactly one point",
                    url: "https://youtu.be/1FiBu7J2gBQ",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    RMM_SL_2019_A1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 45,
            problemStatement: (
                <>
                    Determine all functions <Inline tex="f:\mathbb R\to\mathbb R" />{" "}
                    satisfying the equation
                    <Block tex="f(a^2 +ab+ f(b^2))=af(b)+b^2+ f(a^2)\quad\forall a,b\in\mathbb R." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Determine all functions <Inline tex="f:\mathbb R\to\mathbb R" />{" "}
                    satisfying
                    <div id="rmm-sl-2019-a1-block">
                        <Block tex="f(a^2 +ab+ f(b^2))=af(b)+b^2+ f(a^2)." />
                    </div>
                </div>
            ),
            title: "RMM SL 2019/A1",
            thumbnailTitle: undefined,
            fileName: "RMM-SL2019-A1.pdf",
            contest: ContestData.RMM_SL,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 3, 3, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "How to deal with squares in functional equations",
                    url: "https://youtu.be/3RjpZHIIVEo",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    TAIWAN_MO_2023_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 46,
            problemStatement: (
                <>
                    Find all positive integers <Inline tex="n" /> satisfying the following
                    conditions simultaneously:
                    <ol type="a">
                        <li>
                            the number of positive divisors of <Inline tex="n" /> is not a
                            multiple of <Inline tex="8" />;
                        </li>
                        <li>
                            for all integers <Inline tex="x," /> we have
                            <Block tex="x^n \equiv x \mod n." />
                        </li>
                    </ol>
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Find all <Inline tex="n \in \mathbb Z^+" /> with{" "}
                    <Inline tex="8 \nmid d(n)" /> satisfying
                    <div id="taiwan-mo-2023-2-block">
                        <Block tex="\forall x \in \mathbb Z : x^n \equiv x \mod n." />
                    </div>
                </div>
            ),
            title: "Taiwan MO 2023/2",
            thumbnailTitle: undefined,
            fileName: "TaiwanMO2023-2.pdf",
            contest: ContestData.TAIWAN_MO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [
                WikiData.DIVISOR_SUM_FUNCTION,
                WikiData.FERMATS_LITTLE_THEOREM,
                WikiData.MULTIPLICATIVE_ORDER,
                WikiData.PRIMITIVE_ROOT_MODULO_N,
            ],
            infoTags: [
                InfoTagData.NT_FUNCTION,
                InfoTagData.DIVISIBILITY,
                InfoTagData.PRIME_FACTORS,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 3, 17, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title:
                        "Can we say something about Fermat's little theorem for general n?",
                    url: "https://youtu.be/wXvnrxEsuxI",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USAMO_2018_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 47,
            problemStatement: (
                <>
                    Let <Inline tex="p" /> be a prime, and let{" "}
                    <Inline tex="a_1, \dots, a_p" /> be integers. Show that there exists
                    an integer <Inline tex="k" /> such that the numbers
                    <Block tex="a_1 + k, a_2 + 2k, \dots, a_p + pk" />
                    produce at least <Inline tex="\tfrac{1}{2} p" /> distinct remainders
                    upon division by <Inline tex="p." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="tn-usamo-2018-4">
                    We always find <Inline tex="k \in \mathbb Z" /> so that
                    <div id="usamo-2018-4-block">
                        <Block tex="a_1 + k, a_2 + 2k, \dots, a_p + pk" />
                    </div>
                    produce at least <Inline tex="\tfrac{1}{2} p" /> remainders upon
                    division by <Inline tex="p." />
                </div>
            ),
            title: "USAMO 2018/4",
            thumbnailTitle: undefined,
            fileName: "USAMO2018-4.pdf",
            contest: ContestData.USAMO,
            year: YearData(2018),
            branch: BranchData.N,
            difficulty: DifficultyData(5),
            wikiCitations: [WikiData.TREE_GRAPH],
            infoTags: [InfoTagData.PRIME_FACTORS, InfoTagData.GRAPH_THEORY],
            problemCode: [],
            releaseTime: toDate(2023, 3, 24, 17, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This number theory problem can be solved using graph theory",
                    url: "https://youtu.be/uTYhJVLG924",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2021_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 48,
            problemStatement: (
                <>
                    Show that the inequality
                    <Block tex="\sum_{i=1}^n \sum_{j=1}^n \sqrt{|x_i-x_j|}\leqslant \sum_{i=1}^n \sum_{j=1}^n \sqrt{|x_i+x_j|}" />
                    holds for all real numbers <Inline tex="x_1,\dots, x_n." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    For <Inline tex="x_1, x_2, \dots, x_n \in \mathbb R," /> prove that
                    <div id="imo2021-2-block">
                        <Block tex="\sum_{i=1}^n \sum_{j=1}^n \sqrt{|x_i-x_j|}\leqslant \sum_{i=1}^n \sum_{j=1}^n \sqrt{|x_i+x_j|}." />
                    </div>
                </div>
            ),
            title: "IMO 2021/2",
            thumbnailTitle: undefined,
            fileName: "IMO2021-2.pdf",
            contest: ContestData.IMO,
            year: YearData(2021),
            branch: BranchData.A,
            difficulty: DifficultyData(13),
            wikiCitations: [
                WikiData.CONVEX_AND_CONCAVE_FUNCTIONS,
                WikiData.MINIMUM_MAXIMUM_INFIMUM_SUPREMUM,
            ],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 2, 24, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "The hardest IMO problem 2 ever",
                    url: "https://youtu.be/qehg7waNdSQ",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    OLYMPIAD_PROBLEM_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 49,
            problemStatement: (
                <>
                    Show that the inequality
                    <Block tex="\sum_{i=1}^n \sum_{j=1}^n |x_i-x_j| \leq \sum_{i=1}^n \sum_{j=1}^n |x_i+x_j|" />
                    holds for all real numbers <Inline tex="x_1,\dots, x_n." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    For <Inline tex="x_1, x_2, \dots, x_n \in \mathbb R," /> prove that
                    <div id="olympiad-problem2-block">
                        <Block tex="\sum_{i=1}^n \sum_{j=1}^n |x_i-x_j| \leq \sum_{i=1}^n \sum_{j=1}^n |x_i+x_j|." />
                    </div>
                </div>
            ),
            title: "Olympiad Problem 2",
            thumbnailTitle: (
                <div id="olympiad-problem2-title">
                    A variation of an inequality from the IMO
                </div>
            ),
            fileName: "OlympiadProblem2.pdf",
            contest: ContestData.OLYMPIAD_PROBLEMS,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 3, 10, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/V2zB_gkXYBY",
                    title: "A variation of an inequality from the IMO",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    TURKEY_MO_2021_5: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 50,
            problemStatement: (
                <>
                    There are finitely many primes dividing the numbers{" "}
                    <Block tex="\{ a \cdot b^n + c\cdot d^n : n=1, 2,  3,... \}," /> where{" "}
                    <Inline tex="a, b, c, d" /> are positive integers. Prove that{" "}
                    <Inline tex="b=d." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="turkey-mo-2021-5">
                    Let <Inline tex="a, b, c, d \in \mathbb Z^+, b \neq d." /> <br />
                    Prove that there are infinitely many primes dividing
                    <div id="turkey-mo-2021-5-block">
                        <Block tex="\{ a \cdot b^n + c\cdot d^n : n=1, 2,  3,... \}." />
                    </div>
                </div>
            ),
            title: "Turkey MO 2021/5",
            thumbnailTitle: undefined,
            fileName: "TurkeyMO2021-5.pdf",
            contest: ContestData.TURKEY_MO,
            year: YearData(2021),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.PRIME_FACTORS, InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2023, 3, 5, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "These numbers have infinitely many prime divisors",
                    url: "https://youtu.be/UEOTpDpGh2g",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    Estonia_TST_2019_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 51,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f : \mathbb R \to \mathbb R" /> which
                    for all <Inline tex="x, y \in \mathbb R" /> satisfy{" "}
                    <Block tex="f(x^2)f(y^2) + |x|f(-xy^2) = 3|y|f(x^2y)." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Find all functions <Inline tex="f : \mathbb R \to \mathbb R" />{" "}
                    satisfying
                    <div id="estonia-tst-2019-3-block">
                        <Block tex="f(x^2)f(y^2) + |x|f(-xy^2) = 3|y|f(x^2y)." />
                    </div>
                </div>
            ),
            title: "Estonia TST 2019/3",
            thumbnailTitle: undefined,
            fileName: "EstoniaTST2019-3.pdf",
            contest: ContestData.ESTONIA_TST,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 2, 26, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A functional equation with absolute values",
                    url: "https://youtu.be/bOmrlmG1Gig",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    JAPAN_MO_2020_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 52,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb{Z}^{+}\to\mathbb{Z}^{+}" />{" "}
                    such that
                    <Block tex="m^{2}+f(n)^{2}+(m-f(n))^{2}\geq f(m)^{2}+n^{2}" />
                    for all pairs of positive integers <Inline tex="(m,n)." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Find <Inline tex="f : \mathbb Z^+ \to \mathbb Z^+" /> with
                    <div id="japan-mo-2020-3">
                        <Block tex="m^{2}+f(n)^{2}+(m-f(n))^{2}\geq f(m)^{2}+n^{2}." />
                    </div>
                </div>
            ),
            title: "Japan MO 2020/3",
            thumbnailTitle: undefined,
            fileName: "JapanMO2020-3.pdf",
            contest: ContestData.JAPAN_MO,
            year: YearData(2020),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 3, 26, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A nice functional inequality with a lot of squares",
                    url: "https://youtu.be/rRSECe_2KnI",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALTIC_WAY_2021_6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 53,
            problemStatement: (
                <>
                    Let <Inline tex="n" /> be a positive integer and <Inline tex="t" /> be
                    a non-zero real number. Let{" "}
                    <Inline tex="a_1, a_2, \ldots, a_{2n-1}" /> be real numbers (not
                    necessarily distinct). Prove that there exist distinct indices{" "}
                    <Inline tex="i_1, i_2, \ldots, i_n" /> such that, for all{" "}
                    <Inline tex="1 \le k, l \le n," /> we have{" "}
                    <Inline tex="a_{i_k} - a_{i_l} \neq t." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Are there distinct <Inline tex="1 \leq i_1, \dots, i_n \leq 2n - 1" />{" "}
                    with
                    <div id="baltic-way-2021-6-block">
                        <Block tex="a_{i_k} - a_{i_l} \neq t \quad \forall k, l?" />
                    </div>
                </div>
            ),
            title: "Baltic Way 2021/6",
            thumbnailTitle: undefined,
            fileName: "BalticWay2021-6.pdf",
            contest: ContestData.BALTIC_WAY,
            year: YearData(2021),
            branch: BranchData.C,
            difficulty: DifficultyData(5),
            wikiCitations: [WikiData.BIPARTITE_GRAPH],
            infoTags: [InfoTagData.GRAPH_THEORY],
            problemCode: [],
            releaseTime: toDate(2023, 3, 19, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A beautiful application of last weeks lemma",
                    url: "https://youtu.be/UYmvqRZFFzU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    THEOREM_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 54,
            problemStatement: (
                <>
                    Let <Inline tex="G = (V, E)" /> be a graph. <Inline tex="G" /> is{" "}
                    <WikiInfo depth={0} data={WikiData.BIPARTITE_GRAPH}>
                        bipartite
                    </WikiInfo>{" "}
                    if and only if <Inline tex="G" /> has no cycle of odd length.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="theorem-1">
                    <Inline tex="G" /> is bipartite <br /> <Inline tex="\iff" /> <br />{" "}
                    <Inline tex="G" /> has no odd cycle.
                </div>
            ),
            title: "Theorem 1",
            thumbnailTitle: "Characterizing bipartite graphs",
            fileName: "Theorem1.pdf",
            contest: ContestData.THEOREM,
            year: YearData(2023),
            branch: BranchData.C,
            difficulty: DifficultyData(3),
            wikiCitations: [],
            infoTags: [InfoTagData.GRAPH_THEORY],
            problemCode: [],
            releaseTime: toDate(2023, 3, 12, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/fHbQlDEoIIE",
                    title: "A characterization of bipartite graphs",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BWM_2019_1_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 55,
            problemStatement: (
                <>
                    In the decimal representation of{" "}
                    <Block tex="\sqrt{2} = 1.4142... =: \overline{b_0.b_1b_2b_3b_4...}," />{" "}
                    there are <Inline tex="k \geq 1" /> consecutive zeros{" "}
                    <Block tex="b_\ell = b_{\ell+1} = \dots = b_{\ell + k - 1} = 0." />{" "}
                    Prove that <Inline tex="\ell \geq k." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="tn-bwm-2019-1-4-1">How many zeros can we find?</div>
                    <div id="tn-bwm-2019-1-4-2">
                        <Block tex="\sqrt{2} = 1.4142\dots \underbrace{00 \dots 0}_{k \, \textnormal{zeros}}\dots" />
                    </div>
                </div>
            ),
            title: "BWM 2019/1/4",
            thumbnailTitle: "BWM 2019/1.4",
            fileName: "BWM2019-1-4.pdf",
            contest: ContestData.BWM,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2023, 7, 28, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/jjwPeMX5kUc",
                    title: "A nice trick to handle irrational numbers",
                }
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    EGMO_2016_1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 56,
            problemStatement: (
                <>
                    Let <Inline tex="n" /> be an odd positive integer, and let{" "}
                    <Inline tex="x_1,x_2,\cdots ,x_n" /> be non-negative real numbers. We
                    write <Inline tex="x_{n+i} = x_i." /> Show that
                    <Block tex=" \min_{i=1,\ldots,n} (x_i^2+x_{i+1}^2) \leq \max_{j=1,\ldots,n} (2x_jx_{j+1}). " />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="tn-egmo-2016-1-1">
                        For <Inline tex="n" /> odd, show that
                    </div>
                    <div id="tn-egmo-2016-1-2">
                        <Block tex=" \min_{i=1,\ldots,n} (x_i^2+x_{i+1}^2) \leq \max_{j=1,\ldots,n} (2x_jx_{j+1}). " />
                    </div>
                </div>
            ),
            title: "EGMO 2016/1",
            fileName: "EGMO2016-1.pdf",
            contest: ContestData.EGMO,
            year: YearData(2016),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 5, 19, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "The AM-GM inequality the other way round",
                    url: "https://youtu.be/qyWZ2KgwBek",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    INDIA_EGMO_TST_2023_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 57,
            problemStatement: (
                <>
                    Let <Inline tex="f, g: \mathbb{R} \rightarrow \mathbb{R}" /> such that
                    for all reals <Inline tex="x,y," />
                    <Block tex="f(g(x) + y) = g(x + y)." />
                    Prove that either <Inline tex="f" /> is the identity function or{" "}
                    <Inline tex="g" /> is periodic.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="tn-india-egmo-tst-2023-4-1">The functional equation</div>
                    <div id="tn-india-egmo-tst-2023-4-2">
                        <Block tex="f(g(x) + y) = g(x + y)" />
                    </div>
                    <div id="tn-india-egmo-tst-2023-4-3">
                        implies that <Inline tex="f" /> is the identity or{" "}
                        <Inline tex="g" /> is periodic.
                    </div>
                </div>
            ),
            title: "India EGMO TST 2023/4",
            fileName: "IndiaEGMOTST2023-4.pdf",
            contest: ContestData.India_EGMO_TST,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 4, 14, 13, 0),
            youtubeVideos: [
                {
                    title: "A functional equation with two functions",
                    url: "https://youtu.be/kVsd-e9DOBw",
                }
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CMO_2008_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 58,
            problemStatement: (
                <>
                    Determine all functions <Inline tex="f" /> defined on the natural
                    numbers that take values among the natural numbers for which
                    <Block tex="(f(n))^p \equiv n\quad {\rm mod}\; f(p)" />
                    for all <Inline tex="n \in {\mathbb N}" /> and all prime numbers{" "}
                    <Inline tex="p." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="tn-cmo-2008-4-1">Solving the functional equation</div>
                    <div id="tn-cmo-2008-4-2">
                        <Block tex="(f(n))^p \equiv n\quad {\rm mod}\; f(p)." />
                    </div>
                </div>
            ),
            title: "CMO 2008/4",
            fileName: "CMO2008-4.pdf",
            contest: ContestData.CMO,
            year: YearData(2008),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION, InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2023, 5, 5, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A functional equation modulo f(p)",
                    url: "https://youtu.be/_Kdz1r18TwU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    RMM_2023_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 59,
            problemStatement: (
                <>
                    Let <Inline tex="r,g,b" /> be non-negative integers and{" "}
                    <Inline tex="G" /> be a connected graph with <Inline tex="r+g+b+1" />{" "}
                    vertices. Its edges are colored red, green and blue. It turns out that{" "}
                    <Inline tex="G" /> contains
                    <ul>
                        <li>
                            {" "}
                            a spanning tree with exactly <Inline tex="r" /> red edges,
                        </li>
                        <li>
                            {" "}
                            a spanning tree with exactly <Inline tex="g" /> green edges,
                        </li>
                        <li>
                            {" "}
                            a spanning tree with exactly <Inline tex="b" /> blue edges.
                        </li>
                    </ul>
                    Prove that <Inline tex="G" /> contains a spanning tree with exactly{" "}
                    <Inline tex="r" /> red edges, <Inline tex="g" /> green edges and{" "}
                    <Inline tex="b" /> blue edges.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="tn-rmm-2023-6-1">
                        <div id="rmm-2023-6-img-div">
                            <img
                                src={require("../img/thumbnails/RMM2023-6-TN.png")}
                                id="rmm-2023-6-img"
                            />
                        </div>
                        How to find a spanning tree with exactly{" "}
                        <span id="rmm-2023-6-red">
                            <Inline tex="r" /> red
                        </span>
                        ,<br />{" "}
                        <span id="rmm-2023-6-green">
                            <Inline tex="g" /> green
                        </span>
                        , and{" "}
                        <span id="rmm-2023-6-blue">
                            <Inline tex="b" /> blue
                        </span>{" "}
                        edges.
                    </div>
                </div>
            ),
            title: "RMM 2023/6",
            fileName: "RMM2023-6.pdf",
            contest: ContestData.RMM,
            year: YearData(2023),
            branch: BranchData.C,
            difficulty: DifficultyData(12),
            wikiCitations: [
                WikiData.UNDIRECTED_GRAPH,
                WikiData.INDUCED_SUBGRAPH,
                WikiData.TREE_GRAPH,
            ],
            infoTags: [InfoTagData.GRAPH_THEORY],
            problemCode: [],
            releaseTime: toDate(2023, 4, 7, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Finding the perfect spanning tree",
                    url: "https://youtu.be/aA3eBFP4G3Q",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ROMANIA_SUPPLEMENTARY_TST_2004_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 60,
            problemStatement: (
                <>
                    Let <Inline tex="m, n \in \mathbb Z^+, m < n / 2." /> Consider{" "}
                    <Inline tex="n" /> distinct unit vectors with the common origin{" "}
                    <Inline tex="O." /> Suppose that for any line <Inline tex="\ell" />{" "}
                    through <Inline tex="O," /> at least <Inline tex="m" /> of these
                    vectors are on either side of <Inline tex="\ell." /> Prove that the
                    length of the sum of all <Inline tex="n" /> vectors cannot exceed{" "}
                    <Inline tex="n - 2m." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="tn-romania-sup-tst-2004-2-1">
                        A bound for the sum of evenly distributed unit vectors.
                    </div>
                    <div id="tn-romania-sup-tst-2004-2-2">
                        <img
                            src={require("../img/thumbnails/Romania_Suppelementary_TST2004-2.png")}
                            id="tn-romania-sup-tst-2004-2-img"
                        />
                    </div>
                </div>
            ),
            title: "Romania Sup. TST 2004/2",
            fileName: "RomaniaSupplementaryTST2004-2.pdf",
            contest: ContestData.ROMANIA_TST,
            year: YearData(2004),
            branch: BranchData.C,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.GEOMETRY_CROSSOVER],
            problemCode: [],
            releaseTime: toDate(2023, 7, 14, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Bounding the length of a sum of vectors",
                    url: "https://youtu.be/NcehijXk1mY",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CNO_2022_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 61,
            problemStatement: (
                <>
                    There are <Inline tex="n \geq 8" /> airports, some of which have
                    one-way direct routes between them. For any two airports,{" "}
                    <Inline tex="a" /> and <Inline tex="b," /> there is at most one
                    one-way direct route from <Inline tex="a" /> to <Inline tex="b," /> but there may be both one-way direct routes from <Inline tex="a" /> to{" "}
                    <Inline tex="b" /> and from <Inline tex="b" /> to <Inline tex="a." />
                    For any set <Inline tex="A" /> composed of airports{" "}
                    <Inline tex="(1\le | A| \le n-1)," /> there are at least{" "}
                    <Block tex="4\cdot \min \{|A|,n-|A| \}" /> one-way direct routes from
                    an airport in <Inline tex="A" /> to an airport not in{" "}
                    <Inline tex="A." /><br />
                    Prove that: For any airport <Inline tex="x," /> we can start from{" "}
                    <Inline tex="x" /> and return to the airport by no more than{" "}
                    <Inline tex="\sqrt{2n}" /> one-way direct routes.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Finding a short cycle in a highly connected directed graph.
                    <div id="tn-cno-2022-6">
                        <img
                            src={require("../img/thumbnails/CNO2022-6-TN.png")}
                            id="tn-cno-2022-6-img"
                        />
                    </div>
                </div>
            ),
            title: "CNO 2022/6",
            fileName: "CNO2022-6.pdf",
            contest: ContestData.CNO,
            year: YearData(2022),
            branch: BranchData.C,
            difficulty: DifficultyData(10),
            wikiCitations: [WikiData.UNDIRECTED_GRAPH, WikiData.AM_GM_INEQUALITY],
            infoTags: [InfoTagData.GRAPH_THEORY, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 6, 16, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Finding a cycle with a small length",
                    url: "https://youtu.be/uhobo28S5IU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    INTRODUCING_THE_IMAGINARY_NUMBER_AND_OTHER_ROOTS_OF_UNITY: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 62,
            problemStatement: (
                <>
                    We define complex numbers and explore first properties. We introduce
                    polar coordinates to evaluate <Inline tex="n" />
                    -th roots of unity. Thus, we achieve a factorization of{" "}
                    <Inline tex="X^n - 1" /> in linear factors.
                </>
            ),
            thumbnailTitle: (
                <div id="complex-numbers-1-title">Fundamentals of complex numbers</div>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="complex-numbers-1">
                    Factorizing
                    <div id="complex-numbers-1-1">
                        <Block tex="X^n - 1." />
                    </div>
                </div>
            ),
            title: "The imaginary unit and other roots of unity",
            fileName: "AnAlgebraicMethodForCounting1.pdf",
            contest: ContestData.COURSE,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(4),
            wikiCitations: [],
            infoTags: [InfoTagData.COMPLEX_NUMBERS],
            problemCode: [],
            releaseTime: toDate(2023, 4, 21, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Fundamentals of complex numbers",
                    url: "https://youtu.be/0fKErEJBS08",
                },
                {
                    title: "Fundamentals of complex numbers || part 2",
                    url: "https://youtu.be/nWe8D7udh-A",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
            foundationCourse: true,
        };
    },
    COMPLEX_NUMBERS2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 63,
            problemStatement: (
                <>
                    Let <Inline tex="n, \ell \in \mathbb Z_{\geq 0}," />{" "}
                    <Inline tex="n \geq \ell." /> Determine the number{" "}
                    <Inline tex="N_\ell" /> of <Inline tex="\ell" />
                    -element subsets of <Inline tex="\{1, \dots, 5n\}" /> such that their
                    sum is divisible by <Inline tex="5." />
                </>
            ),
            thumbnailTitle: (
                <div id="complex-numbers-3-title">
                    Using complex numbers for counting
                </div>
            ),
            thumbnailStatement: (
                <div
                    className="thumbnail-text-div"
                    id="application-of-complex-numbers-in-combinatorics"
                >
                    Determine the number <Inline tex="N(n, \ell)" /> of{" "}
                    <Inline tex="\ell" />
                    -element subsets of <Inline tex="\{1, \dots, 5n\}" /> with sum
                    divisible by <Inline tex="5." />
                </div>
            ),
            title: "Using complex numbers to solve a combinatorics problem",
            fileName: "AnAlgebraicMethodForCounting2.pdf",
            contest: ContestData.OLYMPIAD_PROBLEMS,
            year: YearData(2023),
            branch: BranchData.C,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [
                InfoTagData.COMPLEX_NUMBERS,
                InfoTagData.ENUMERATIVE_COMBINATORICS,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 6, 2, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Generalizing a viewer suggestion",
                    url: "https://youtu.be/PhYWTTC7hso",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    JAPAN_MO_2023_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 64,
            problemStatement: (
                <>
                    Determine all positive integers <Inline tex="n" /> such that{" "}
                    <Inline tex="n" /> divides <Inline tex="\varphi(n)^{d(n)}+1" /> but{" "}
                    <Inline tex="d(n)^5" /> does not divide{" "}
                    <Inline tex="n^{\varphi(n)}-1." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="japan-mo-2023-4-1">
                        <Block tex="n \mid \varphi(n)^{d(n)} + 1" />
                    </div>
                    <div id="japan-mo-2023-4-2">but</div>
                    <div id="japan-mo-2023-4-3">
                        <Block tex="d(n)^5 \nmid n^{\varphi(n)}-1." />
                    </div>
                </div>
            ),
            title: "Japan MO 2023/4",
            fileName: "JapanMO2023-4.pdf",
            contest: ContestData.JAPAN_MO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [
                InfoTagData.NT_FUNCTION,
                InfoTagData.PRIME_FACTORS,
                InfoTagData.DIVISIBILITY,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 4, 2, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "How to work with number theoretic functions",
                    url: "https://youtu.be/YZf-MnUN8SI",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BRITISH_MO_2023_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 65,
            problemStatement: (
                <>
                    The side lengths <Inline tex="a, b, c" /> of a triangle{" "}
                    <Inline tex="ABC" /> are positive integers such that the highest
                    common factor of <Inline tex="a, b" /> and <Inline tex="c" /> is{" "}
                    <Inline tex="1." /> Given that <Inline tex="\angle A = 3\angle B" />{" "}
                    prove that at least one of <Inline tex="a, b" /> and{" "}
                    <Inline tex="c" /> is a cube.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    A number theoric geometry problem.
                    <div>
                        <img
                            src={require("../img/thumbnails/BMO2023-4-TN.png")}
                            id="bmo2023-4-img"
                        />
                    </div>
                </div>
            ),
            title: "British MO 2023/4",
            fileName: "BritishMO2023-4.pdf",
            contest: ContestData.BRITISH_MO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.TRIGONOMETRIC_FORMULAS],
            infoTags: [
                InfoTagData.NT_FUNCTION,
                InfoTagData.FACTORIZATION,
                InfoTagData.TRIGONOMETRY,
                InfoTagData.GEOMETRY_CROSSOVER,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 6, 23, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "This problem combines number theory and geometry",
                    url: "https://youtu.be/DYuvTiAs8tU",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    RMM_2023_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 66,
            problemStatement: (
                <>
                    Determine all prime numbers <Inline tex="p" /> and all positive
                    integers <Inline tex="x" /> and <Inline tex="y" /> satisfying
                    <Block tex="x^3+y^3=p(xy+p)." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="rmm-2023-1">
                    <Block tex="x^3+y^3=p(xy+p)" />
                </div>
            ),
            title: "RMM 2023/1",
            fileName: "RMM2023-1.pdf",
            contest: ContestData.RMM,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.AM_GM_INEQUALITY],
            infoTags: [
                InfoTagData.NT_FUNCTION,
                InfoTagData.DIVISIBILITY,
                InfoTagData.FACTORIZATION,
                InfoTagData.DIOPHANTINE_EQUATION,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 5, 26, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "How to deal with diophantine equations",
                    url: "https://youtu.be/5t4Nlvvehz0",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    OLYMPIAD_PROBLEM_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 67,
            problemStatement: (
                <>
                    Let <Inline tex="S" /> be a set of <Inline tex="m \geq 4" /> points in
                    the plane, such that no three of them are collinear and not all of
                    them lie on a circle. Find all functions{" "}
                    <Inline tex="f : S \to \mathbb R" /> such that for any circle{" "}
                    <Inline tex="C" /> containing at least three points of{" "}
                    <Inline tex="S," />
                    <Block tex="\sum_{P \in C \cap S} f(P) = 0." />
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    The functional equation
                    <div id="olympiad-problem-3-1">
                        <Block tex="\sum_{P \in C \cap S} f(P) = 0" />
                    </div>
                    on points <Inline tex="S" /> in the euclidean plane.
                </div>
            ),
            title: "Combining geometry with algebra",
            fileName: "OlympiadProblem3.pdf",
            contest: ContestData.OLYMPIAD_PROBLEMS,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [
                InfoTagData.FUNCTIONAL_EQUATION,
                InfoTagData.GEOMETRY_CROSSOVER,
            ],
            problemCode: [],
            releaseTime: toDate(2023, 6, 9, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "A functional equation in the plane",
                    url: "https://youtu.be/5lqOECuhaU8",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CHINA_TST_2018_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 68,
            problemStatement: (
                <>
                    A number <Inline tex="n" /> is interesting if 2018 divides{" "}
                    <Inline tex="d(n)" /> (the number of positive divisors of{" "}
                    <Inline tex="n" />
                    ). Determine all positive integers <Inline tex="k" /> such that there
                    exists an infinite arithmetic progression with common difference{" "}
                    <Inline tex="k" /> whose terms are all interesting.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" id="china-tst-2018-2-tn">
                    Determine all positive integers <Inline tex="k" /> for which there
                    exists a positive integer <Inline tex="a" /> such that for every{" "}
                    <Inline tex="i \geq 0" />
                    <div id="tn-china-tst-2018-2">
                        <Block tex="2018 \mid d(a + i \cdot k)." />
                    </div>
                </div>
            ),
            title: "China TST 2018/2",
            fileName: "ChinaTST2018-2.pdf",
            contest: ContestData.CHINA_TST,
            year: YearData(2018),
            branch: BranchData.N,
            difficulty: DifficultyData(11),
            wikiCitations: [WikiData.DIRICHLETS_THEOREM_PRIMES],
            infoTags: [InfoTagData.NT_FUNCTION, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2023, 5, 12, 13, 0).getTime(),
            youtubeVideos: [
                {
                    title: "Number of divisors in an arithmetic sequence",
                    url: "https://youtu.be/Ci8d0jYGuzc",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALKAN_SL_2023_C5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 69,
            problemStatement: (
                <>
                    Given is a cube of side length <Inline tex="2021." /> In how many different ways is it possible to add somewhere on the boundary of this cube a <Inline tex="1\times 1\times 1" /> cube in such a way that the new shape can be filled in with <Inline tex="1\times 1\times k" /> shapes, for some natural number <Inline tex="k\geq 2" />?
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    Can we fill this with <Inline tex="1 \times 1 \times k" /> cuboids?
                    <div id="bsl-2023-c5-img-div">
                        <img
                            src={require("../img/thumbnails/BalkanSL2023-C5-TN.png")}
                            id="bsl-2023-c5-img"
                        />
                    </div>
                </div>
            ),
            title: "Balkan SL 2023/C5",
            fileName: "BalkanSL2023-C5.pdf",
            contest: ContestData.BALKAN_SL,
            year: YearData(2023),
            branch: BranchData.C,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.COMPLEX_NUMBERS, InfoTagData.COLORINGS],
            problemCode: [],
            releaseTime: toDate(2023, 6, 30, 13, 0).getTime(),
            youtubeVideos: [{
                url: "https://youtu.be/HbnIzlzf0Og",
                title: "Can we fill this modified cube with cuboids?"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2009_C1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 70,
            problemStatement: (
                <>
                    Consider <Inline tex="2009" /> cards, each having one gold side and one black side, lying on parallel on a long table. Initially all cards show their gold sides. Two players, standing by the same long side of the table, play a game with alternating moves. Each move consists of choosing a block of <Inline tex="50" /> consecutive cards, the leftmost of which is showing gold, and turning them all over, so those which showed gold now show black and vice versa. The last player who can make a legal move wins.
                    (a) Does the game necessarily end?
                    (b) Does there exist a winning strategy for the starting player?
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    How to tell which player can win <br /> a combinatorial game.
                    <div id="isl-2009-c1-img-div">
                        {
                            [...Array(5).keys()].map(key =>
                                <img
                                    src={require("../img/thumbnails/ISL2009-C1-TN.png")}
                                    className="isl-2009-c1-img"
                                    key={key}
                                />)
                        }
                    </div>
                </div>
            ),
            title: "ISL 2009/C1",
            fileName: "ISL2009-C1.pdf",
            contest: ContestData.ISL,
            year: YearData(2009),
            branch: BranchData.C,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.GAMES],
            problemCode: [],
            releaseTime: toDate(2023, 7, 21, 13, 0).getTime(),
            youtubeVideos: [
                {
                    url: "https://youtu.be/Atu-4e-RjXA",
                    title: "How to tell which player can win a combinatorial game."
                }
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    INDIA_EGMO_TST_2023_6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 71,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be an isosceles triangle with <Inline tex="AB = AC." /> Suppose <Inline tex="P,Q,R" /> are points on segments <Inline tex="AC, AB, BC" /> respectively such that <Inline tex="AP = QB," /> <Inline tex="\angle PBC = 90^\circ - \angle BAC" /> and <Inline tex="RP = RQ." /> Let <Inline tex="O_1, O_2" /> be the circumcenters of <Inline tex="\triangle APQ" /> and <Inline tex="\triangle CRP." /> Prove that <Inline tex="BR = O_1O_2." />
                </>
            ),
            thumbnailStatement: (
                <div>
                    <div className="thumbnail-text-div" id="india-egmo-tst-2023-6-text-div" >
                        Dealing with<br />many equal<br /> distances.
                    </div>
                    <div id="india-egmo-tst-2023-6-img-div">
                        <img
                            src={require("../img/thumbnails/IndiaEGMOTST2023-6-TN.png")}
                            id="india-egmo-tst-2023-6-img"
                        />
                    </div>
                </div>
            ),
            title: "India EGMO TST 2023/6",
            fileName: "IndiaEGMOTST2023-6.pdf",
            contest: ContestData.India_EGMO_TST,
            year: YearData(2023),
            branch: BranchData.G,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.ANGLE_CHASING, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 8, 4, 13, 0).getTime(),
            youtubeVideos: [{
                title: "How to deal with equal lengths",
                url: "https://youtu.be/yyTjCQV9f2w",
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    THAILAND_ONLINE_MO_2023_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 72,
            problemStatement: (
                <>
                    Let <Inline tex="P(x)" /> be a polynomial with real coefficients that is not the zero-polynomial. Prove that not all roots of <Inline tex="x^3P(x) + 1" /> are real.
                </>
            ),
            thumbnailStatement: (
                <div className="thumbnail-text-div" >
                    <div id="tomo-2023-2-block-1">
                        <Block tex="x^3 P(x) + 1" />
                    </div>
                    never has only real roots.
                </div>
            ),
            title: "Thailand Online MO 2023/2",
            fileName: "ThailandOnlineMO2023-2.pdf",
            contest: ContestData.THAILAND_ONLINE_MO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.POLYNOMIALS, InfoTagData.FACTORIZATION],
            problemCode: [],
            releaseTime: toDate(2023, 8, 11, 13, 0).getTime(),
            youtubeVideos: [{
                title: "This polynomial has a complex root",
                url: "https://youtu.be/gHnm9Q_PFl4",
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2023_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 73,
            problemStatement: (
                <>
                    Determine all composite integers <Inline tex="n > 1" /> that satisfy the following property: if <Inline tex="d_1, d_2, \ldots, d_k" /> are all the positive divisors of <Inline tex="n" /> with <Inline tex="1=d_1<d_2<\cdots<d_k=n," /> then <Inline tex="d_i" /> divides <Inline tex="d_{i+1}+d_{i+2}" /> for every <Inline tex="1 \leqslant i \leqslant k-2." />
                </>
            ),
            thumbnailTitle: "Problem 1",
            thumbnailStatement: (
                <div className="thumbnail-text-div" >
                    <div id="imo-2023-1-div">
                        Find <Inline tex="n" /> such that the divisors <Inline tex="1 = d_1 < d_2 < \dots < d_k = n" /> satisfy
                        <Block tex="d_i \mid d_{i+1} + d_{i+2}." />
                    </div>
                    <div className="imo-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-logo.png")}
                            className="imo-logo"
                        />
                    </div>
                    <div className="imo-2023-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-2023-logo.png")}
                            className="imo-2023-logo"
                        />
                    </div>
                    <div className="imo-2023-japan-div">
                        <img
                            src={require("../img/thumbnails/japan.png")}
                            className="imo-2023-japan"
                        />
                    </div>
                </div>
            ),
            title: "IMO 2023/1",
            fileName: "IMO2023-1.pdf",
            contest: ContestData.IMO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2023, 7, 9, 0, 0).getTime(),
            youtubeVideos: [
                {
                    title: "INTERNATIONAL MATH OLYMPIAD 2023 | Problem 1",
                    url: "https://youtu.be/Fp7-J1fJrxM"
                }
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2023_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 74,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be an acute-angled triangle with <Inline tex="AB < AC." /> Let <Inline tex="\Omega" /> be the circumcircle of <Inline tex="ABC." /> Let <Inline tex="S" /> be the midpoint of the arc <Inline tex="CB" /> of <Inline tex="\Omega" /> containing <Inline tex="A." /> The perpendicular from <Inline tex="A" /> to <Inline tex="BC" /> meets <Inline tex="BS" /> at <Inline tex="D" /> and meets <Inline tex="\Omega" /> again at <Inline tex="E \neq A." /> The line through <Inline tex="D" /> parallel to <Inline tex="BC" /> meets line <Inline tex="BE" /> at <Inline tex="L." /> Denote the circumcircle of triangle <Inline tex="BDL" /> by <Inline tex="\omega." /> Let <Inline tex="\omega" /> meet <Inline tex="\Omega" /> again at <Inline tex="P \neq B." /> Prove that the line tangent to <Inline tex="\omega" /> at <Inline tex="P" /> meets line <Inline tex="BS" /> on the internal angle bisector of <Inline tex="\angle BAC." />
                </>
            ),
            thumbnailTitle: "Problem 2",
            thumbnailStatement: (
                <div className="thumbnail-text-div" >
                    <div id="imo-2023-2-div">
                        <img
                            src={require("../img/thumbnails/IMO2023-2-TN.png")}
                            id="imo-2023-2"
                        />
                    </div>
                    <div className="imo-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-logo.png")}
                            className="imo-logo"
                        />
                    </div>
                    <div className="imo-2023-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-2023-logo.png")}
                            className="imo-2023-logo"
                        />
                    </div>
                </div>
            ),
            title: "IMO 2023/2",
            fileName: "IMO2023-2.pdf",
            contest: ContestData.IMO,
            year: YearData(2023),
            branch: BranchData.G,
            difficulty: DifficultyData(9),
            wikiCitations: [WikiData.DIRECTED_ANGLES, WikiData.PASCALS_THEOREM],
            infoTags: [InfoTagData.ANGLE_CHASING, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 7, 9, 0, 0).getTime(),
            youtubeVideos: [{
                url: "https://youtu.be/q0-B9iXJ_bU",
                title: "INTERNATIONAL MATH OLYMPIAD 2023 | Problem 2"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2023_3: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 75,
            problemStatement: (
                <>
                    For each integer <Inline tex="k \geqslant 2," /> determine all infinite sequences of positive integers <Inline tex="a_1, a_2, \ldots" /> for which there exists a polynomial <Inline tex="P" /> of the form <Block tex="P(x)=x^k+c_{k-1} x^{k-1}+\cdots+c_1 x+c_0," /> where <Inline tex="c_0, c_1, \ldots, c_{k-1}" /> are non-negative integers, such that
                    <Block tex="P\left(a_n\right)=a_{n+1} a_{n+2} \cdots a_{n+k}" />
                    for every integer <Inline tex="n \geqslant 1." />
                </>
            ),
            thumbnailTitle: "Problem 3",
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="imo-2023-3-div">
                        <Block tex="P(a_n) = a_{n+1} a_{n+2} \cdots a_{n+k}" />
                    </div>
                    <div className="imo-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-logo.png")}
                            className="imo-logo"
                        />
                    </div>
                    <div className="imo-2023-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-2023-logo.png")}
                            className="imo-2023-logo"
                        />
                    </div>
                    <div className="imo-2023-japan-div">
                        <img
                            src={require("../img/thumbnails/japan.png")}
                            className="imo-2023-japan"
                        />
                    </div>
                </div>
            ),
            title: "IMO 2023/3",
            fileName: "IMO2023-3.pdf",
            contest: ContestData.IMO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.POLYNOMIALS, InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2023, 7, 9, 0, 0).getTime(),
            youtubeVideos: [{
                url: "https://youtu.be/CmJn5FKxpPY",
                title: "INTERNATIONAL MATH OLYMPIAD 2023 | Problem 3"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2004_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 76,
            problemStatement: (
                <>
                    Let <Inline tex="n \geq 3" /> be an integer. Let <Inline tex="t_1," /> <Inline tex="t_2," /> ..., <Inline tex="t_n" /> be positive real numbers such that
                    <Block tex="n^2 + 1 > \left( t_1 + t_2 + \cdots + t_n \right) \left( \frac{1}{t_1} + \frac{1}{t_2} + \cdots + \frac{1}{t_n} \right)." />
                    Show that <Inline tex="t_i," /> <Inline tex="t_j," /> <Inline tex="t_k" /> are side lengths of a triangle for all <Inline tex="i," /> <Inline tex="j," /> <Inline tex="k" /> with <Inline tex="1 \leq i < j < k \leq n." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div className="imo-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-logo.png")}
                        className="imo-logo"
                    />
                </div>
                <div id="imo-2004-4-block">
                    <Block tex="n^2 + 1 > \left( {t_1} + {t_2} + \cdots + {t_n} \right) \left( \frac{1}{{t_1}} + \frac{1}{{t_2}} + \cdots + \frac{1}{{t_n}} \right)" />
                </div>
                <div id="imo-2004-4-text">
                    Are every three of <span className="high-vis">these numbers</span> the sidelengths of a triangle?
                </div>
            </div>,
            title: "IMO 2004/4",
            fileName: "IMO2004-4.pdf",
            contest: ContestData.IMO,
            year: YearData(2004),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 8, 25, 13, 0),
            youtubeVideos: [
                {
                    url: "https://youtu.be/CpkZpqzKXcE",
                    title: "Solving a problem from the INTERNATIONAL MATH OLYMPIAD with the triangle inequality"
                }
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USA_TSTST_2023_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 77,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a scalene triangle and let <Inline tex="P" /> and <Inline tex="Q" /> be two distinct points in its interior. Suppose that the angle bisectors of <Inline tex="\angle PAQ,\,\angle PBQ," /> and <Inline tex="\angle PCQ" /> are the altitudes of triangle <Inline tex="ABC." /> Prove that the midpoint of <Inline tex="\overline{PQ}" /> lies on the Euler line of <Inline tex="ABC." />
                    <p />
                    (The Euler line is the line through the circumcenter and orthocenter of a triangle.)
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div className="thumbnail-text-div" id="usa-tstst-2023-6-text" >
                    How to prove that the midpoint <span className="high-vis"><Inline tex="M" /></span> of <Inline tex="PQ" /> lies on the Euler line.
                </div>
                <div id="usa-tstst-2023-6-img-div">
                    <img
                        src={require("../img/thumbnails/USATSTST2023-6-TN.png")}
                        id="usa-tstst-2023-6-img"
                    />
                </div>
            </div>,
            title: "USA TSTST 2023/6",
            fileName: "USATSTST2023-6.pdf",
            contest: ContestData.USA_TSTST,
            year: YearData(2023),
            branch: BranchData.G,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.COMPLEX_NUMBERS, InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 9, 8, 13, 0),
            youtubeVideos: [{
                title: "A beautiful property of the Euler line",
                url: "https://youtu.be/CMX3iHomSBI"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ELMO_2023_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 78,
            problemStatement: (
                <>
                    Let <Inline tex="m" /> be a positive integer. Find, in terms of <Inline tex="m," /> all polynomials <Inline tex="P(x)" /> with integer coefficients such that for every integer <Inline tex="n," /> there exists an integer <Inline tex="k" /> such that <Inline tex="P(k)=n^m." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                For <Inline tex="m \in \mathbb Z^+" /> find all integer polynomials <Inline tex="P" /> with
                <div id="elmo-2023-1-block">
                    <Block tex="\forall n \in \mathbb Z \exists k \in \mathbb Z : P(k) = n^m." />
                </div>
            </div>,
            title: "ELMO 2023/1",
            fileName: "ELMO2023-1.pdf",
            contest: ContestData.ELMO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.POLYNOMIALS],
            problemCode: [],
            releaseTime: toDate(2023, 9, 1, 13, 0),
            youtubeVideos: [{
                title: "Can you find a polynomial that attains all these values?",
                url: "https://youtu.be/Cu2BVFu0U0E"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USAEGMOTST2020_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 79,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a triangle. Distinct points <Inline tex="D," /> <Inline tex="E," /> <Inline tex="F" /> lie on sides <Inline tex="BC," /> <Inline tex="AC," /> and <Inline tex="AB," /> respectively, such that quadrilaterals <Inline tex="ABDE" /> and <Inline tex="ACDF" /> are cyclic. Line <Inline tex="AD" /> meets the circumcircle of <Inline tex="\triangle ABC" /> again at <Inline tex="P." /> Let <Inline tex="Q" /> denote the reflection of <Inline tex="P" /> across <Inline tex="BC." /> Show that <Inline tex="Q" /> lies on the circumcircle of <Inline tex="\triangle AEF." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="usa-egmo-tst-2020-1-text">
                    <Inline tex="ABDE, ACDF" /> cyclic,<br />
                    <Inline tex="P :" /> second intersection of <Inline tex="AD," /> and <Inline tex="\odot ABC," /> <br />
                    <Inline tex="Q :" /> reflection of <Inline tex="P" /> on <Inline tex="BC." />
                    <div id="usa-egmo-tst-202-1-to-prove">
                        Prove: <span className="high-vis"><Inline tex="{AFQE}" /></span>  concyclic.
                    </div>
                </div>
                <div id="usa-egmo-tst-2020-1-img-div">
                    <img
                        src={require("../img/thumbnails/USAEGMOTST2020-4.png")}
                        id="usa-egmo-tst-2020-1-img"
                    />
                </div>
            </div>,
            title: "USA EGMO TST 2020/4",
            fileName: "USAEGMOTST2020-4.pdf",
            contest: ContestData.USA_EGMO_TST,
            year: YearData(2020),
            branch: BranchData.G,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 9, 15, 13, 0),
            youtubeVideos: [{
                title: "An elegant proof using reflections",
                url: "https://youtu.be/D4NBqdboUbE"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BMO_2023_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 80,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f\colon \mathbb{R} \rightarrow \mathbb{R}" /> such that for all <Inline tex="x,y \in \mathbb{R}," />
                    <Block tex="xf(x+f(y))=(y-x)f(f(x))." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="bmo-2023-1">
                Solve the functional equation
                <div id="bmo-2023-1-block">
                    <Block tex="xf(x+f(y))=(y-x)f(f(x))" />
                </div>
                over <Inline tex="\mathbb R." />
            </div>,
            title: "BMO 2023/1",
            fileName: "BMO2023-1.pdf",
            contest: ContestData.BALKAN_MO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 8, 18, 13, 0),
            youtubeVideos: [
                {
                    title: "A nice functional equation from the Balkan math olympiad",
                    url: "https://youtu.be/ua4E1djhHmc",
                },
            ],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IRAN_MO_2021_N2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 81,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb{N} \to \mathbb{N}" /> such that for any two positive integers <Inline tex="a" /> and <Inline tex="b" /> we have <Block tex="f^a(b) + f^b(a) \mid 2(f(ab) +b^2 -1)," />
                    where <Inline tex="f^n(m)" /> is defined in the iterative manner.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="iran-mo-2021-n2">
                <Block tex="f^{a}(b) + f^{b}(a) \mid 2(f({ab}) +b^2 -1)" />
            </div>,
            title: "Iran MO 2021/N2",
            fileName: "IranMO2021-N2.pdf",
            contest: ContestData.IRAN_MO,
            year: YearData(2021),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 9, 22, 13, 0),
            youtubeVideos: [{
                title: "A special functional equation",
                url: "https://youtu.be/G9GmO8s_EXs",
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2023_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 82,
            problemStatement: (
                <>
                    Let <Inline tex="x_1,x_2,\dots,x_{2023}" /> be pairwise different positive real numbers such that
                    <Block tex="a_n=\sqrt{(x_1+x_2+\dots+x_n)\left(\frac{1}{x_1}+\frac{1}{x_2}+\dots+\frac{1}{x_n}\right)}" />
                    is an integer for every <Inline tex="n=1,2,\dots,2023." /> Prove that <Inline tex="a_{2023} \geqslant 3034." />
                </>
            ),
            thumbnailTitle: "Problem 4",
            thumbnailStatement: (
                <div className="thumbnail-text-div">
                    <div id="imo-2023-4-div">
                        Given that
                        <div id="imo-2023-4-block">
                            <Block tex="a_n=\sqrt{(x_1+x_2+\dots+x_n)\left(\frac{1}{x_1}+\frac{1}{x_2}+\dots+\frac{1}{x_n}\right)}" />
                        </div>
                        are integers. Prove that
                        <Block tex="a_{2023} \geq 3034." />
                    </div>
                    <div className="imo-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-logo.png")}
                            className="imo-logo"
                        />
                    </div>
                    <div className="imo-2023-logo-div">
                        <img
                            src={require("../img/thumbnails/imo-2023-logo.png")}
                            className="imo-2023-logo"
                        />
                    </div>
                    <div className="imo-2023-japan-div">
                        <img
                            src={require("../img/thumbnails/japan.png")}
                            className="imo-2023-japan"
                        />
                    </div>
                </div>
            ),
            title: "IMO 2023/4",
            fileName: "IMO2023-4.pdf",
            contest: ContestData.IMO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [WikiData.CAUCHY_SCHWARZ_INEQUALITY],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2023, 10, 20, 13, 0),
            youtubeVideos: [{
                title: "Inequalities are back in the INTERNATIONAL MATH OLYMPIAD",
                url: "https://youtu.be/nkO1rkggbqw"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMOC_2023_N5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 83,
            problemStatement: (
                <>
                    Let <Inline tex="p = 4k + 1" /> be a prime for <Inline tex="k \in \mathbb Z." /> Let <Inline tex="|x| \leq \frac{p-1}{2}" /> such that
                    <Block tex="\binom{2k}{k} \equiv x \mod p." />
                    Prove that <Inline tex="|x| \leq 2 \sqrt{p}." />
                </>
            ),
            thumbnailStatement: (
                <div id="imoc-2023-n5-tn">

                    <div className="imoc-2023-taiwan">
                        <img
                            src={require("../img/thumbnails/taiwan-circle.png")}
                            className="imoc-2023-taiwan-img"
                        />
                    </div>
                    <div id="imoc-2023-n5-main">
                        <div id="imoc-2023-n5-text">
                            Let <Inline tex="p = 4k + 1" /> be a prime. <br />
                            There is an <Inline tex="x" /> with <Inline tex="|x| \leq 2 \sqrt{p}" /> and
                        </div>
                        <div id="imoc-2023-n5-block">
                            <Block tex="\binom{2k}{k} \equiv x \mod p." />
                        </div>
                    </div>
                    <div id="imoc-2023-n5-chapter">
                        Chapter 2: Half* of fourth powers plus one are squares modulo <Inline tex="p." />
                    </div>
                </div>
            ),
            title: "IMOC 2023/N5",
            fileName: "IMOC2023-N5.pdf",
            contest: ContestData.IMOC,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(12),
            wikiCitations: [WikiData.PRIME_FIELD, WikiData.PRIMITIVE_ROOT_MODULO_N, WikiData.EULERS_CRITERION],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.ENUMERATIVE_COMBINATORICS, InfoTagData.SECOND_MOMENT],
            problemCode: [],
            releaseTime: toDate(2023, 9, 29, 13, 0),
            youtubeVideos: [{
                title: "Analyzing a binomial coefficient modulo p",
                url: "https://youtu.be/YSbrN3aOPYU"
            },
            {
                title: "Counting the number of solutions to an equation modulo p",
                url: "https://youtu.be/Qazt1DAwb4c"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    APMO_2023_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 84,
            problemStatement: (
                <>
                    Let <Inline tex="c>0" /> be a given positive real and <Inline tex="\mathbb{R}_{>0}" /> be the set of all positive reals. Find all functions <Inline tex="f \colon \mathbb{R}_{>0} \to \mathbb{R}_{>0}" /> such that
                    <Block tex="f((c+1)x+f(y))=f(x+2y)+2cx \quad \textrm{for all } x,y \in \mathbb{R}_{>0}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="apmo-2023-4">
                <Block tex="f((c+1)x+f(y))=f(x+2y)+2cx" />
                <div id="apmo-2023-4-chapter">
                    How to get a contradiction in a functional equation on <Inline tex="\mathbb R^+." />
                </div>
            </div>,
            title: "APMO 2023/4",
            fileName: "APMO2023-4.pdf",
            contest: ContestData.APMO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 10, 13, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/yRc3q7caBvk",
                title: "How to get a contradiction in a functional equation on the positive numbers."
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2022_A1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 85,
            problemStatement: (
                <>
                    Let <Inline tex="(a_n)_{n\geq 1}" /> be a sequence of positive real numbers with the property that
                    <Block tex="(a_{n+1})^2 + a_na_{n+2} \leq a_n + a_{n+2}" />
                    for all positive integers <Inline tex="n." /> Show that <Inline tex="a_{2022}\leq 1." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                What is the behavior of <Inline tex="(a_n)" /> with
                <div id="isl-2022-a1-block">
                    <Block tex="(a_{n+1})^2 + a_na_{n+2} \leq a_n + a_{n+2}?" />
                </div>
            </div>,
            title: "ISL 2022/A1",
            fileName: "ISL2022-A1.pdf",
            contest: ContestData.ISL,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 11, 17, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/LCRnjfFuJ9Q",
                title: "Clever factorization on the International Math Olympiad Shortlist"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2022_A6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 86,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb R" /> be the set of real numbers. We denote by <Inline tex="\mathcal F" /> the set of all functions <Inline tex="f\colon\mathbb R\to\mathbb R" /> such that
                    <Block tex="f(x + f(y)) = f(x) + f(y)" />for every <Inline tex="x,y\in\mathbb R." /> Find all rational numbers <Inline tex="q" /> such that for every function <Inline tex="f\in\mathcal F," /> there exists some <Inline tex="z\in\mathbb R" /> satisfying <Inline tex="f(z)=qz." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Let <Inline tex="\mathcal F" /> be the family of real functions satisfying
                <Block tex="f(x + f(y)) = f(x) + f(y)." />
                Find all rational numbers <Inline tex="q" /> such that
                <Block tex="\forall f \in \mathcal F \exists z \in \mathbb R : \quad f(z) = q \cdot z." />
            </div>,
            title: "ISL 2022/A6",
            fileName: "ISL2022-A6.pdf",
            contest: ContestData.ISL,
            year: YearData(2022),
            branch: BranchData.A,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 11, 10, 13, 0),
            youtubeVideos: [{
                title: "This problem was almost on the International Math Olympiad",
                url: "https://youtu.be/TEeJZLUY3bY"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMC_2023_8: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 87,
            problemStatement: (
                <>
                    Let <Inline tex="T" /> be a tree with <Inline tex="n" /> vertices; that is, a connected simple graph on <Inline tex="n" /> vertices that contains no cycle. For every pair <Inline tex="u," /> <Inline tex="v" /> of vertices, let <Inline tex="d(u,v)" /> denote the distance between <Inline tex="u" /> and <Inline tex="v," /> that is, the number of edges in the shortest path in <Inline tex="T" /> that connects <Inline tex="u" /> with <Inline tex="v." /> Consider the sums
                    <Block tex="W(T)=\sum_{\substack{\{u,v\}\subseteq V(T)\\ u\neq v}}d(u,v) \quad \text{and} \quad H(T)=\sum_{\substack{\{u,v\}\subseteq V(T)\\ u\neq v}}\frac{1}{d(u,v)}." />
                    Prove that
                    <Block tex="W(T)\cdot H(T)\geq \frac{(n-1)^3(n+2)}{4}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Given a tree <Inline tex="T" /> with vertices <Inline tex="V" /> and distance function <Inline tex="d," /> the values <Inline tex="d(u, v)" /> for <Inline tex="u, v \in V" /> cannot be very evenly distributed:
                <div id="imc-2023-8-comment">
                    <Block tex="\sum d(u, v) \cdot \sum d(u, v)^{-1} \geq \frac{(n-1)^3(n+2)}{4}." />
                </div>
            </div>,
            title: "IMC 2023/8",
            fileName: "IMC2023-8.pdf",
            contest: ContestData.IMC,
            year: YearData(2023),
            branch: BranchData.C,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.JENSENS_INEQUALITY],
            infoTags: [InfoTagData.GRAPH_THEORY, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2023, 10, 27, 13, 0),
            youtubeVideos: [{
                title: "Graph theory combined with Algebra",
                url: "https://youtu.be/nJlWRSZUKOM"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMOC_2023_A1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 88,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb{N} \rightarrow \mathbb{N}" /> such that for all positive integers <Inline tex="n," /> there exists an unique positive integer <Inline tex="k," /> satisfying <Inline tex="f^k(n)\leq n+k+1." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="imoc-2023-a1-block">
                    <Block tex="f^k(n) \leq n+k+1" />
                </div>
                is true for exactly one <Inline tex="k" /> for every <Inline tex="n." />
            </div>,
            title: "IMOC 2023/A1",
            fileName: "IMOC2023-A1.pdf",
            contest: ContestData.IMOC,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 11, 3, 13, 0),
            youtubeVideos: [{
                title: "This functional inequality holds for exactly one value.",
                url: "https://youtu.be/l8HParMvTy4"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    OLYMPIAD_PROBLEM_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 89,
            problemStatement: (
                <>
                    Let <Inline tex="t" /> be a positive integer. Prove that
                    <Block tex="\sum_{1 \leq x \leq y \leq t} \frac{2^{t - x}}{xy} = \sum_{1 \leq x \leq y \leq t} \frac{\binom{t}{0} + \binom{t}{1} + \dots + \binom{t}{y - 1}}{xy}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="olympiad-problem-4-block">
                    <Block tex="\sum_{1 \leq x \leq y \leq t} \frac{2^{t - x}}{xy} = \sum_{1 \leq x \leq y \leq t} \frac{\binom{t}{0} + \binom{t}{1} + \dots + \binom{t}{y - 1}}{xy}" />
                </div>
                is always true!
                <div id="olympiad-problem-4-chapter">
                    How can we determine the sum of the first <Inline tex="k" /> binomial coefficients?
                </div>
            </div>,
            title: "A crazy combinatorial identity",
            fileName: "OlympiadProblem4.pdf",
            contest: ContestData.OLYMPIAD_PROBLEMS,
            year: YearData(2022),
            branch: BranchData.C,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.COMBINATORIC_IDENTITY],
            problemCode: [],
            releaseTime: toDate(2023, 12, 1, 13, 0),
            youtubeVideos: [{
                title: "Can you find a combinatorial argument for this?",
                url: "https://youtu.be/JY26HHVtCes"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    MEMO_2023_T7: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 90,
            problemStatement: (
                <>
                    Find all positive integers <Inline tex="n," /> for which there exist positive integers <Inline tex="a>b," /> satisfying <Block tex="n=\frac{4ab}{a-b}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="memo-2023-t7-text">
                    Find all <Inline tex="\textcolor{orange}{n}" /> with
                </div>
                <div id="memo-2023-t7-block">
                    <Block tex="\textcolor{orange}{n} = \frac{4ab}{a-b}." />
                </div>
            </div>,
            title: "MEMO 2023/T7",
            fileName: "MEMO2023-T7.pdf",
            contest: ContestData.MEMO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 11, 24, 13, 0),
            youtubeVideos: [{
                title: "Which integers can be represented in this beautiful way.",
                url: "https://youtu.be/aVE9TX08oTk"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    EGMO_2021_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 91,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb{Q}\to\mathbb{Q}" /> such that the equation
                    <Block tex="f(xf(x)+y) = f(y) + x^2" />
                    holds for all rational numbers <Inline tex="x" /> and <Inline tex="y." />

                    Here, <Inline tex="\mathbb{Q}" /> denotes the set of rational numbers.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="egmo-2021-2-text">
                    Find all <Inline tex="\textcolor{orange}f : \mathbb Q \to \mathbb Q" /> with
                </div>
                <div id="egmo-2021-2-block">
                    <Block tex="\textcolor{orange}f(x \cdot \textcolor{orange}f(x)+y) = \textcolor{orange}f(y) + x^2." />
                </div>
            </div>,
            title: "EGMO 2021/2",
            fileName: "EGMO2021-2.pdf",
            contest: ContestData.EGMO,
            year: YearData(2021),
            branch: BranchData.A,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2023, 12, 15, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/t6fHUGQ2sCQ",
                title: "A clever trick with rational numbers solves this equation"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    OLYMPIAD_PROBLEM_5: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 92,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a triangle with incenter <Inline tex="I" /> and let <Inline tex="D" /> be the touching point of the incircle with line <Inline tex="BC." /> <Inline tex="J_B" /> and <Inline tex="J_C" /> are the incenters of triangle <Inline tex="ABD" /> and <Inline tex="ADC." /> Prove that the circumcenter <Inline tex="O" /> of triangle <Inline tex="AJ_CJ_B" /> lies on the interior angle bisector of <Inline tex="\sphericalangle BAC." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div className="thumbnail-text-div" id="olympiad-problem-5-div">
                    <div id="olympiad-problem-5-text-div">
                        Prove that the circumcenter of <Inline tex="\textcolor{#bb86fc}{AJ_BJ_C}" /> lies on <Inline tex="AI." />
                    </div>
                    <div id="olympiad-problem-5-img-div">
                        <img id="olympiad-problem-5-img" src={require("../img/thumbnails/OlympiadProblem5-TN.png")} />
                    </div>
                </div>
            </div>,
            thumbnailTitle: "An elegant incircle identity",
            title: "Olympiad Problem 5",
            fileName: "OlympiadProblem5.pdf",
            contest: ContestData.OLYMPIAD_PROBLEMS,
            year: YearData(2023),
            branch: BranchData.G,
            difficulty: DifficultyData(5),
            wikiCitations: [WikiData.INCENTER],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2023, 12, 9, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/Cx_0D2Q1vq0",
                title: "A circumcenter lying on the angle bisector"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    SWISS_MO_2023_3: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 93,
            problemStatement: (
                <>
                    Let <Inline tex="x,y" /> and <Inline tex="a_0, a_1, a_2, \cdots" /> be integers satisfying <Inline tex="a_0 = a_1 = 0," /> and
                    <Block tex="a_{n+2} = xa_{n+1}+ya_n+1" />
                    for all integers <Inline tex="n \geq 0." /> Let <Inline tex="p" /> be any prime number. Show that <Inline tex="\gcd(a_p,a_{p+1})" /> is either equal to <Inline tex="1" /> or greater than <Inline tex="\sqrt{p}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Let <Inline tex="\textcolor{ForestGreen}p" /> be a prime and
                <div id="swiss-mo-2023-3-block">
                    <Block tex="\textcolor{orange}a_{n+2} = x\textcolor{orange}a_{n+1}+y\textcolor{orange}a_n+1." />
                </div>
                Then <Inline tex="\gcd(\textcolor{orange}a_\textcolor{ForestGreen}p,\textcolor{orange}a_{\textcolor{ForestGreen}p+1})" /> is either<br /> equal to <Inline tex="1" /> or greater than <Inline tex="\sqrt{\textcolor{ForestGreen}p}." />
            </div>,
            title: "SWISS MO 2023/3",
            fileName: "SwissMO2023-3.pdf",
            contest: ContestData.SWISS_MO,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2023, 12, 29, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/AzB3Q0aSshU",
                title: "Arguing with periods to find large prime divisors"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USA_TST_2024_1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 94,
            problemStatement: (
                <>
                    Find the smallest constant <Inline tex="C > 1" /> such that the following statement holds: for every integer <Inline tex="n \geq 2" /> and sequence of non-integer positive real numbers <Inline tex="a_1, a_2, \dots, a_n" /> satisfying
                    <Block tex="\frac{1}{a_1} + \frac{1}{a_2} + \cdots + \frac{1}{a_n} = 1," />
                    it's possible to choose positive integers <Inline tex="b_i" /> such that
                    (i) for each <Inline tex="i = 1, 2, \dots, n," /> either <Inline tex="b_i = \lfloor a_i \rfloor" /> or <Inline tex="b_i = \lfloor a_i \rfloor + 1," /> and
                    (ii) we have
                    <Block tex="1 < \frac{1}{b_1} + \frac{1}{b_2} + \cdots + \frac{1}{b_n} \leq C." />
                    Merlijn Staps
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Find the smallest <Inline tex="\textcolor{ForestGreen}C" /> such that
                <div id="usa-tst-2024-1-block-1">
                    <Block tex="\forall n \in \mathbb N \forall a_1, \dots a_n \in \mathbb R^+ \backslash \mathbb Z: \quad \sum_{i=1}^n \frac{1}{a_i} \textcolor{WildStrawberry}= \textcolor{WildStrawberry}1" />
                </div>
                <div id="usa-tst-2024-1-block-2">
                    <Block tex="\implies \exists b_i \in \{\lfloor a_i \rfloor, \lceil a_i \rceil\} : \quad \textcolor{WildStrawberry}1 \textcolor{WildStrawberry}< \sum_{i=1}^n \frac{1}{b_i} \textcolor{ForestGreen}\leq \textcolor{ForestGreen}C." />
                </div>
            </div>,
            title: "USA TST 2024/1",
            fileName: "USATST2024-1.pdf",
            contest: ContestData.USA_TST,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY, InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2024, 1, 12, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/k0_M6o8O8rY",
                title: "Developing a nontrivial counter example of real numbers"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_1999_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 95,
            problemStatement: (
                <>
                    Find all the pairs of positive integers <Inline tex="(x,p)" /> such that <Inline tex="p" /> is a prime, <Inline tex="x \leq 2p" /> and <Inline tex="x^{p-1}" /> is a divisor of <Inline tex="(p-1)^{x}+1." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Find all <Inline tex="\textcolor{ForestGreen}x, \textcolor{WildStrawberry}p \in \mathbb Z^+" /> with <Inline tex="\textcolor{WildStrawberry}p" /> prime and <Inline tex="\textcolor{ForestGreen}x \leq 2\textcolor{WildStrawberry}p" /> with
                <div id="imo-1999-4-block">
                    <Block tex="\textcolor{ForestGreen}x^{\textcolor{WildStrawberry}p-1} \mid (\textcolor{WildStrawberry}p-1)^{\textcolor{ForestGreen}x}+1." />
                </div>
            </div>,
            title: "IMO 1999/4",
            fileName: "IMO1999-4.pdf",
            contest: ContestData.IMO,
            year: YearData(1999),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [WikiData.MULTIPLICATIVE_ORDER, WikiData.FERMATS_LITTLE_THEOREM, WikiData.LTE_LEMMA],
            infoTags: [InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2023, 12, 22, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/DtVQP4OXVCw",
                title: "Using the order modulo a prime to solve a divisibility condition"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALTIC_WAY_2023_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 96,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f: \mathbb{R} \rightarrow \mathbb{R}" /> such that
                    <Block tex="f(f(x)+y)+xf(y)=f(xy+y)+f(x)" />
                    for reals <Inline tex="x, y." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Find all functions <Inline tex="\textcolor{ForestGreen}f" /> from <Inline tex="\mathbb R" /> to <Inline tex="\mathbb R" /> with
                <div id="baltic-way-2023-4-block">
                    <Block tex="\textcolor{ForestGreen}f(\textcolor{ForestGreen}f(x)+y)+x\textcolor{ForestGreen}f(y)" />
                </div>
                <div id="baltic-way-2023-4-block">
                    <Block tex="=" />
                </div>
                <div id="baltic-way-2023-4-block">
                    <Block tex="\textcolor{ForestGreen}f(xy+y)+\textcolor{ForestGreen}f(x)." />
                </div>
            </div>,
            title: "Baltic Way 2023/4",
            fileName: "BalticWay2023-4.pdf",
            contest: ContestData.BALTIC_WAY,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 1, 5, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/TPsMv_clprQ",
                title: "An astonishing way to prove a functional equation"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CNO_2023_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 97,
            problemStatement: (
                <>
                    Let <Inline tex="a_1, a_2, \ldots, a_{2023}" /> be nonnegative real numbers such that <Inline tex="a_1 + a_2 + \ldots + a_{2023} = 100." /> Let <Inline tex="A = \left\{ (i,j) \mid 1 \leqslant i \leqslant j \leqslant 2023, \, a_ia_j \geqslant 1 \right\}." /> Prove that <Inline tex="|A| \leqslant 5050" /> and determine when the equality holds.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Let <Inline tex="\textcolor{ForestGreen}a_1 + \textcolor{ForestGreen}a_2 + \dots + \textcolor{ForestGreen}a_{2023} = 100." /> Then
                <div className="cno-2023-4-block">
                    <Block tex="\textcolor{RedOrange}A := \left\{ (\textcolor{RedOrange}i,\textcolor{RedOrange}j) \mid 1 \leqslant \textcolor{RedOrange}i \leqslant \textcolor{RedOrange}j \leqslant 2023, \, \textcolor{ForestGreen}a_{\textcolor{RedOrange}i}\textcolor{ForestGreen}a_{\textcolor{RedOrange}j} \geqslant 1 \right\}" />
                </div>
                satisfies
                <div className="cno-2023-4-block">
                    <Block tex="|\textcolor{RedOrange}A| \leq 5050." />
                </div>
            </div>,
            title: "CNO 2023/4",
            thumbnailTitle: "China MO 2023 / 4",
            fileName: "CNO2023-4.pdf",
            contest: ContestData.CNO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2024, 1, 26, 13, 0),
            youtubeVideos: [{
                title: "Bounding the size of a set with clever inequalities",
                url: "https://youtu.be/oq2lp7lb1WE"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    FRANCE_TST_2002_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 98,
            problemStatement: (
                <>
                    There are three colleges in a town. Each college has <Inline tex="n" /> students. Any student of a college knows <Inline tex="n+1" /> students in total from the other two colleges. Prove that it is possible to choose three students, one from each college, such that they all know each other.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="france-tst-2002-4-first">
                    Each of three colleges has <Inline tex="\textcolor{Orange}n" /> students. Any student knows <Inline tex="\textcolor{Orange}n+1" /> students in total from the other two colleges.<br />
                </div>
                <div id="france-tst-2002-4-second">
                    <span id="france-tst-2002-4-prove">Prove</span> &nbsp;&nbsp; We can choose one student from each college, such that they all know each other.
                </div>
            </div>,
            title: "France TST 2002/4",
            fileName: "FranceTST2002-4.pdf",
            contest: ContestData.FRANCE_TST,
            year: YearData(2002),
            branch: BranchData.C,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.GRAPH_THEORY],
            problemCode: [],
            releaseTime: toDate(2024, 3, 8, 13, 0),
            youtubeVideos: [{
                title: "An important combinatorics exercise",
                url: "https://youtu.be/6yfpahKs7SE"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CHINA_TST_2022_1_2: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 99,
            problemStatement: (
                <>
                    Let <Inline tex="p" /> be a prime, <Inline tex="A" /> is an infinite set of integers. Prove that there is a subset <Inline tex="B" /> of <Inline tex="A" /> with <Inline tex="2p-2" /> elements, such that the arithmetic mean of any pairwise distinct <Inline tex="p" /> elements in <Inline tex="B" /> does not belong to <Inline tex="A." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="china-tst-2022-1-2-tn">
                For any infinite set of integers <Inline tex="\textcolor{#189AB4}A" /> and every subset <Inline tex="\textcolor{#189AB4}B" /> with <Inline tex="2\textcolor{#EF7C8E}p - 2" /> elements, there is a <nobr><Inline tex="\textcolor{#EF7C8E}p" />-element</nobr> subset <Inline tex="\textcolor{#189AB4}C" /> of <Inline tex="\textcolor{#189AB4}B" /> whose element's arithmetic mean is not in <Inline tex="\textcolor{#189AB4}A." />
            </div>,
            title: "China TST 2022/1.2",
            fileName: "ChinaTST2022-1-2.pdf",
            contest: ContestData.CHINA_TST,
            year: YearData(2022),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.DIOPHANTINE_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 2, 9, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/fnr7Zno77Ks",
                title: "Chinese students had to solve this to come into the International Math Olympiad"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_1977_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 100,
            problemStatement: (
                <>
                    Let <Inline tex="f : \mathbb Z^+\rightarrow \mathbb Z^+." /> Prove that if
                    <Block tex="f(n+1)>f(f(n)), \quad \forall n \in \mathbb Z^+," />
                    then <Inline tex="f(n) = n \quad \forall n \in \mathbb Z^+." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                How do we find the solutions to
                <div id="imo-1977-6-block">
                    <Block tex="\textcolor{#189AB4}f(n+1) > \textcolor{#189AB4}f(\textcolor{#189AB4}f(n)) \quad \forall n \in \mathbb N?" />
                </div>
            </div>,
            title: "IMO 1977/6",
            fileName: "IMO1977-6.pdf",
            contest: ContestData.IMO,
            year: YearData(1977),
            branch: BranchData.A,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 2, 23, 13, 0),
            youtubeVideos: [{
                title: "The final problem in the INTERNATIONAL MATH OLYMPIAD 1977",
                url: "https://youtu.be/4IQ4c92USgo"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BELARUS_MO_2019_7: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 101,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb R\to\mathbb R" /> satisfying the equality
                    <Block tex="f(f(x)+f(y))=(x+y)f(x+y)" />
                    for all real <Inline tex="x" /> and <Inline tex="y." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Is there a function <Inline tex="\textcolor{#EF7C8E}f" /> on <Inline tex="\mathbb R" /> satisfying
                <div id="belarus-mo-2019-7-block">
                    <Block tex="\textcolor{#EF7C8E}f(\textcolor{#EF7C8E}f(x)+\textcolor{#EF7C8E}f(y))=(x+y)\textcolor{#EF7C8E}f(x+y)?" />
                </div>
            </div>,
            title: "Belarus MO 2019/7",
            fileName: "BelarusMO2019-7.pdf",
            contest: ContestData.BELARUS_MO,
            year: YearData(2019),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 2, 2, 13, 0),
            youtubeVideos: [{
                title: "A classic functional equation solution using injectivity",
                url: "https://youtu.be/3gd-E43sAbc"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BRAZIL_TST_2023_4_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 102,
            problemStatement: (
                <>
                    Find all positive integers <Inline tex="n" /> with the following property: There are only a finite number of positive multiples of <Inline tex="n" /> that have exactly <Inline tex="n" /> positive divisors.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="brazil-tst-2023-4-4-tn">
                For <Inline tex="\textcolor{#189AB4}n \in \mathbb Z^+," /> how many multiples <Inline tex="\textcolor{#EF7C8E}m" /> of <Inline tex="\textcolor{#189AB4}n" /> have exactly <Inline tex="\textcolor{#189AB4}n" /> divisors?
            </div>,
            title: "Brazil TST 2023/4.4",
            fileName: "BrazilTST2023-4-4.pdf",
            contest: ContestData.BRAZIL_TST,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [WikiData.DIVISOR_SUM_FUNCTION],
            infoTags: [InfoTagData.NT_FUNCTION],
            problemCode: [],
            releaseTime: toDate(2024, 1, 19, 13, 0),
            youtubeVideos: [{
                title: "On infinitely large integers with the same number of divisors",
                url: "https://youtu.be/7DdZOcjMMos"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CHINA_TST_2016_1_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 103,
            problemStatement: (
                <>
                    Let <Inline tex="m,n" /> be naturals satisfying <Inline tex="n \geq m \geq 2" /> and let <Inline tex="S" /> be a set consisting of <Inline tex="n" /> naturals. Prove that <Inline tex="S" /> has at least <Inline tex="2^{n-m+1}" /> distinct subsets, each whose sum is divisible by <Inline tex="m." /> (The zero set counts as a subset).
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="china-tst-2016-1-6">
                Let <Inline tex="\textcolor{BurntOrange}n \geq \textcolor{Plum}m \geq 2" /> and <Inline tex="\textcolor{BurntOrange}S \subseteq \mathbb N" /> with <Inline tex="|\textcolor{BurntOrange}S| = \textcolor{BurntOrange}n." /> Prove that the number of subsets of <Inline tex="\textcolor{BurntOrange}S" /> with sum divisible
                <div id="china-tst-2016-1-6-text">by <Inline tex="\textcolor{Plum}m" /> is &nbsp; <Inline tex="\geq 2^{\textcolor{BurntOrange}n-\textcolor{Plum}m+1}." /></div>
            </div>,
            title: "China TST 2016/1.6",
            fileName: "ChinaTST2016-1-6.pdf",
            contest: ContestData.CHINA_TST,
            year: YearData(2016),
            branch: BranchData.C,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.ENUMERATIVE_COMBINATORICS],
            problemCode: [],
            releaseTime: toDate(2024, 3, 22, 13, 0),
            youtubeVideos: [{
                title: "Counting the number of subsets with a prescribed sum",
                url: "https://youtu.be/Srm9H8tXsOs"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USA_TST_2024_6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 104,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f\colon\mathbb R\to\mathbb R" /> such that for all real numbers <Inline tex="x" /> and <Inline tex="y" />,
                    <Block tex="f(xf(y))+f(y)=f(x+y)+f(xy)." />
                    Milan Haiman
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="usa-tst-2024-6-info">
                    <q>
                        How to solve a hard functional equation when you get stuck
                    </q>
                </div>
                Find all <Inline tex="\textcolor{orange}f : \mathbb R \to \mathbb R" /> with
                <div id="usa-tst-2024-6-block">
                    <Block tex="\textcolor{orange}f(x\textcolor{orange}f(y))+\textcolor{orange}f(y)=\textcolor{orange}f(x+y)+\textcolor{orange}f(xy)." />
                </div>
            </div>,
            title: "USA TST 2024/6",
            fileName: "USATST2024-6.pdf",
            contest: ContestData.USA_TST,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(11),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 2, 16, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/J1XltIHX6ig",
                title: "The hardest functional equation in the history of USA Team Selection Tests."
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USA_TSTST_2012_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 105,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb N" /> be the set of positive integers. Let <Inline tex="f: \mathbb N \to \mathbb N" /> be a function satisfying the following two conditions:

                    <ol type="a">
                        <li>
                            <Inline tex="f(m)" /> and <Inline tex="f(n)" /> are relatively prime whenever <Inline tex="m" /> and <Inline tex="n" /> are relatively prime.
                        </li>
                        <li>
                            <Inline tex="n \le f(n) \le n+2012" /> for all <Inline tex="n." />
                        </li>
                    </ol>
                    Prove that for any natural number <Inline tex="n" /> and any prime <Inline tex="p" />, if <Inline tex="p" /> divides <Inline tex="f(n)" /> then <Inline tex="p" /> divides <Inline tex="n." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Let <Inline tex="\quad\textcolor{Emerald}n \leq \textcolor{BrickRed}f(\textcolor{Emerald}n) \leq \textcolor{Emerald}n + 2012\quad" /> and
                <Block tex="\mathrm{gcd}(\textcolor{Emerald}m, \textcolor{Emerald}n) = 1 \,\,\,\, \implies \,\,\,\, \mathrm{gcd}(\textcolor{BrickRed}f(\textcolor{Emerald}m), \textcolor{BrickRed}f(\textcolor{Emerald}n)) = 1." />
                Prove that
                <Block tex="p \mid \textcolor{BrickRed}f(\textcolor{Emerald}n) \,\,\,\, \implies \,\,\,\, p \mid \textcolor{Emerald}n." />
            </div>,
            title: "USA TSTST 2012/3",
            fileName: "USATSTST2012-3.pdf",
            contest: ContestData.USA_TSTST,
            year: YearData(2012),
            branch: BranchData.N,
            difficulty: DifficultyData(10),
            wikiCitations: [WikiData.CHINESE_REMAINDER_THEOREM, WikiData.DIRICHLETS_THEOREM_PRIMES],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2024, 3, 29, 13, 0),
            youtubeVideos: [{
                title: "A functional equation for number theorists",
                url: "https://youtu.be/7_MtLjadof4"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IRAN_TST_2015_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 106,
            problemStatement: (
                <>
                    <Inline tex="I_b" /> is the <Inline tex="B" />-excenter of the triangle <Inline tex="ABC" /> and <Inline tex="\omega" /> is the circumcircle of this triangle. <Inline tex="M" /> is the middle of arc <Inline tex="BC" /> of <Inline tex="\omega" /> which doesn't contain <Inline tex="A." /> <Inline tex="MI_b" /> meets <Inline tex="\omega" /> at <Inline tex="T\not =M." /> Prove that
                    <Block tex="TB\cdot TC=TI_b^2." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="iran-tst-2015-2-img-div">
                    <img
                        src={require("../img/thumbnails/IranTST2015-2-TN.png")}
                        id="usamo2008-6-img"
                    />
                </div>
                <div id="iran-tst-2015-2-div">
                    Prove that
                    <Block tex="TB\cdot TC=TI_b^2." />
                </div>
            </div>,
            title: "Iran TST 2015/2",
            fileName: "IranTST2015-2.pdf",
            contest: ContestData.IRAN_TST,
            year: YearData(2015),
            branch: BranchData.G,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2024, 3, 15, 13, 0),
            youtubeVideos: [{
                title: "One of the most important properties about excircles",
                url: "https://youtu.be/GClFR42aUoU",
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CHINA_TST_2016_1_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 107,
            problemStatement: (
                <>
                    Let <Inline tex="c,d \geq 2" /> be naturals. Let <Inline tex="\{a_n\}" /> be the sequence satisfying <Inline tex="a_1 = c, a_{n+1} = a_n^d + c" /> for <Inline tex="n = 1,2,\cdots." />
                    Prove that for any <Inline tex="n \geq 2" />, there exists a prime number <Inline tex="p" /> such that <Inline tex="p|a_n" /> and <Inline tex="p \not | a_i" /> for <Inline tex="i = 1,2,\cdots n-1." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="china-tst-2016-1-4-block">
                    <Block tex="a_0 = 0 \quad \text{and} \quad a_{n+1} = a_n^d + c." />
                </div>
                Prove that there always exists a prime number <Inline tex="\textcolor{orange}p \mid a_n" /> with <Inline tex="\textcolor{orange}p \nmid a_1 \cdots a_{n-1}." />
            </div>,
            title: "China TST 2016/1.4",
            fileName: "ChinaTST2016-1-4.pdf",
            contest: ContestData.CHINA_TST,
            year: YearData(2016),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [WikiData.P_ADIC_VALUATION],
            infoTags: [InfoTagData.PRIME_FACTORS, InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: toDate(2024, 3, 1, 13, 0),
            youtubeVideos: [{
                title: "Discovering a beautiful property of this sequence",
                url: "https://youtu.be/TapKwmarZXA"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2012_N8: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 108,
            problemStatement: (
                <>
                    Prove that for every prime <Inline tex="p>100" /> and every integer <Inline tex="r," /> there exist two integers <Inline tex="a" /> and <Inline tex="b" /> such that <Inline tex="p" /> divides <Inline tex="a^2+b^5-r." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="isl-2012-n8-text">
                    For any <Inline tex="r," /> there exist <Inline tex="a, b" /> with
                </div>
                <div id="isl-2012-n8-block">
                    <Block tex="p \mid a^2 + b^5 - r." />
                </div>
            </div>,
            title: "ISL 2012/N8",
            fileName: "ISL2012-N8.pdf",
            contest: ContestData.ISL,
            year: YearData(2012),
            branch: BranchData.N,
            difficulty: DifficultyData(10),
            wikiCitations: [WikiData.PRIME_FIELD, WikiData.PRIMITIVE_ROOT_MODULO_N],
            infoTags: [InfoTagData.SECOND_MOMENT, InfoTagData.ENUMERATIVE_COMBINATORICS, InfoTagData.PROBABILITIES],
            problemCode: [],
            releaseTime: toDate(2024, 4, 19, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/B69Nvz88nzQ",
                title: "A probabilistic approach to a very hard number theory problem"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BALTIC_WAY_2018_19: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 109,
            problemStatement: (
                <>
                    An infinite set <Inline tex="B" /> consisting of positive integers has the following property. For each <Inline tex="a,b \in B" /> with <Inline tex="a>b" /> the number <Inline tex="\frac{a-b}{(a,b)}" /> belongs to <Inline tex="B." /> Prove that <Inline tex="B" /> contains all positive integers. Here, <Inline tex="(a,b)" /> is the greatest common divisor of numbers <Inline tex="a" /> and <Inline tex="b." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <Inline tex="\textcolor{BrickRed}B" /> is an infinite set of positive integers and
                <div id="baltic-way-2018-19-block">
                    <Block tex="a > b \in \textcolor{BrickRed}B \implies \frac{a - b}{\mathrm{gcd}(a,b)} \in \textcolor{BrickRed}B." />
                </div>
                Prove that <Inline tex="\textcolor{BrickRed}B" /> contains all positive integers.
            </div>,
            title: "Baltic Way 2018/19",
            fileName: "BalticWay2018-19.pdf",
            contest: ContestData.BALTIC_WAY,
            year: YearData(2018),
            branch: BranchData.N,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2024, 4, 5, 13, 0),
            youtubeVideos: [{
                title: "How to deal with infinitely large sets",
                url: "https://youtu.be/zot_IEIOwzo"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    USAMO_2024_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 110,
            problemStatement: (
                <>
                    Find all integers <Inline tex="n \geq 3" /> such that the following property holds: if we list the divisors of <Inline tex="n!" /> in increasing order as <Inline tex="1 = d_1 < d_2 < \dots < d_k = n!," /> then we have
                    <Block tex="d_2 - d_1 \leq d_3 - d_2 \leq \dots \leq d_k - d_{k-1}." />
                    Proposed by Luke Robitaille.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                The divisors of <Inline tex="n!" /> are <Inline tex="d_1 < d_2 < \dots < d_k." /><p />
                Do we have
                <div id="usamo-2024-1-block">
                    <Block tex="d_2 - d_1 \leq \dots \leq d_k - d_{k-1}?" />
                </div>
            </div>,
            title: "USAMO 2024/1",
            fileName: "USAMO2024-1.pdf",
            contest: ContestData.USAMO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [WikiData.BERTRANDS_POSTULATE],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2024, 4, 12, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/SpCOVwNKazY",
                title: "An interesting property of the divisors of n!"

            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    CHINA_TST_2024_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 111,
            problemStatement: (
                <>
                    Let <Inline tex="n" /> be a positive square free integer, <Inline tex="S" /> is a subset of <Inline tex="[n]:=\{1,2,\ldots ,n\}" /> such that <Inline tex="|S|\ge n/2." /> Prove that there exists three elements <Inline tex="a,b,c\in S" /> (can be same), satisfy <Inline tex="ab\equiv c\pmod n." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                For <Inline tex="S" /> with <Inline tex="|S| \geq \frac{n}{2}" /> elements, we can find <Inline tex="a, b, c \in S" /> such that
                <div id="china-tst-2024-4">
                    <Block tex="ab \equiv c \mod n." />
                </div>
            </div>,
            title: "China TST 2024/4",
            fileName: "ChinaTST2024-4.pdf",
            contest: ContestData.CHINA_TST,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2024, 4, 26, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/TZ2QkC8Nsh0",
                title: "Can you find subsets with the best properties?"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IZHO_2024_3: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 112,
            problemStatement: (
                <>
                    <Inline tex="d \in \mathbb Z^+" /> is not a perfect square. For each positive integer <Inline tex="n," />" let <Inline tex="s(n)" /> denote the number of digits <Inline tex="1" /> among the first <Inline tex="n" /> digits in the binary representation of <Inline tex="\sqrt{d}." /> Prove that there exists an integer <Inline tex="n_0" /> such that <Inline tex="s(n)>\sqrt{2n}-2" /> for all integers <Inline tex="n \geq n_0." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="izho-2024-3-text">
                    There are at least
                </div>
                <div id="izho-2024-3-block">
                    <Block tex="\sqrt{2n}-2" />
                </div>
                <div id="izho-2024-3-text">
                    <Inline tex="1" />'s among the first <Inline tex="n" /> binary digits of <Inline tex="\sqrt d." />
                </div>
            </div >,
            title: "IZhO 2024/3",
            fileName: "IZhO2024-3.pdf",
            contest: ContestData.IZHO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2024, 5, 9, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/xVmiiBgnFYw",
                title: "How to count the 1's in an infinite binary representation"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2023_5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 113,
            problemStatement: (
                <>
                    Let <Inline tex="n" /> be a positive integer. A Japanese triangle consists of <Inline tex="1 + 2 + \dots + n" /> circles arranged in an equilateral triangular shape such that for each <Inline tex="i = 1," /> <Inline tex="2," /> <Inline tex="\dots," /> <Inline tex="n," /> the <Inline tex="i^{th}" /> row contains exactly <Inline tex="i" /> circles, exactly one of which is coloured red. A ninja path in a Japanese triangle is a sequence of <Inline tex="n" /> circles obtained by starting in the top row, then repeatedly going from a circle to one of the two circles immediately below it and finishing in the bottom row.<br />
                    In terms of <Inline tex="n," /> find the greatest <Inline tex="k" /> such that in each Japanese triangle there is a ninja path containing at least <Inline tex="k" /> red circles.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" >
                <div id="imo-2023-5-div">
                    What is the maximum number <Inline tex="k(n)" /> of red circles on a top-down path, when every row contains one red circle?
                </div>
                <div className="imo-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-logo.png")}
                        className="imo-logo"
                    />
                </div>
                <div className="imo-2023-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-2023-logo.png")}
                        className="imo-2023-logo"
                    />
                </div>
                <div className="imo-2023-japanese-triangle-div">
                    <img
                        src={require("../img/thumbnails/IMO2023-5.png")}
                        className="imo-2023-japanese-triangle"
                    />
                </div>
            </div>,
            title: "IMO 2023/5",
            fileName: "IMO2023-5.pdf",
            contest: ContestData.IMO,
            year: YearData(2023),
            branch: BranchData.C,
            difficulty: DifficultyData(10),
            wikiCitations: [],
            infoTags: [InfoTagData.PROBABILITIES],
            problemCode: [],
            releaseTime: toDate(2024, 5, 23, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/tL6-102WzmM",
                title: "INTERNATIONAL MATH OLYMPIAD 2023 | Problem 5"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    EGMO_2024_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 114,
            problemStatement: (
                <>
                    We call a positive integer <Inline tex="n{ }" /> peculiar if, for any positive divisor <Inline tex="d{ }" /> of <Inline tex="n{ }" /> the integer <Inline tex="d(d + 1)" /> divides <Inline tex="n(n + 1)." /> Prove that for any four different peculiar positive integers <Inline tex="A, B, C" /> and <Inline tex="D{ }" /> the following holds:
                    <Block tex="\gcd(A, B, C, D) = 1. " />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="egmo-2024-3-div">
                <Inline tex="\textcolor{WildStrawberry}n" /> is <span id="peculiar-span"> peculiar </span> if
                <div className="egmo-2024-3-blocks">
                    <Block tex="d \mid \textcolor{WildStrawberry}n \quad \implies \quad d(d+1) \mid \textcolor{WildStrawberry}n(\textcolor{WildStrawberry}n+1)." />
                </div>
                Then, any four distinct <span id="peculiar-span"> peculiar </span> numbers <Inline tex="\textcolor{WildStrawberry}A, \textcolor{WildStrawberry}B, \textcolor{WildStrawberry}C, \textcolor{WildStrawberry}D" /> satisfy
                <div className="egmo-2024-3-blocks">
                    <Block tex="\mathrm{gcd}(\textcolor{WildStrawberry}A, \textcolor{WildStrawberry}B, \textcolor{WildStrawberry}C, \textcolor{WildStrawberry}D) = 1." />
                </div>
                <div className="egmo-2024-div">
                    <img
                        src={require("../img/thumbnails/egmo-2024.png")}
                        className="egmo-2024"
                    />
                </div>
            </div >,
            title: "EGMO 2024/3",
            fileName: "EGMO2024-3.pdf",
            contest: ContestData.EGMO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2024, 5, 16, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/4YCdtVGNg4w",
                title: "Peculiar numbers in the European Girls Math Olympiad"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    RMM_SL_2018_C3: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 115,
            problemStatement: (
                <>
                    <Inline tex="N \geq 1" /> teams take part in a league. Every team plays every other team exactly once during the league, and receives 2 points for each win, 1 point for each draw, and 0 points for each loss. At the end of the league, the sequence of total points in descending order <Inline tex="\mathcal{A} = (a_1 \ge a_2 \ge \cdots \ge a_N )" /> is known, as well as which team obtained which score. Find the number of sequences <Inline tex="\mathcal{A}" /> such that the outcome of all matches is uniquely determined by this information.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="rmm-sl-2018-c3-text">
                    Do the <span id="rmm-final-scores">final scores</span> determine the outcome of <span id="rmm-all-games">all games</span> in a tournament?
                </div>
                <div className="rmm-sl-2018-c3-img">
                    <img
                        src={require("../img/thumbnails/RMM-SL-2018-C3.png")}
                        className="imo-2023-japanese-triangle"
                    />
                </div>
            </div >,
            title: "RMM SL 2018/C3",
            fileName: "RMM-SL2018-C3.pdf",
            contest: ContestData.RMM_SL,
            year: YearData(2018),
            branch: BranchData.C,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.GRAPH_THEORY, InfoTagData.ENUMERATIVE_COMBINATORICS],
            problemCode: [],
            releaseTime: toDate(2024, 6, 21, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/zaS6PBJzMjk",
                title: "A math problem every sports player has thought about (feat. Fibonacci numbers)"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    RMM_2024_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 116,
            problemStatement: (
                <>
                    Fix integers <Inline tex="a" /> and <Inline tex="b" /> greater than <Inline tex="1." /> For any positive integer <Inline tex="n," /> let <Inline tex="r_n" /> be the (non-negative) remainder that <Inline tex="b^n" /> leaves upon division by <Inline tex="a^n." /> Assume there exists a positive integer <Inline tex="N" /> such that <Inline tex="r_n < \frac{2 ^ n}{n}" /> for all integers <Inline tex="n\geq N." /> Prove that <Inline tex="a" /> divides <Inline tex="b." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="usa-tst-2024-6-info">
                    <q>
                        Converting an intuition into a proof in number theory
                    </q>
                </div>
                <div id="rmm-2024-4-text">
                    If <Inline tex="\textcolor{Emerald}b^n\, \textnormal{mod}\,\, \textcolor{BrickRed}a^n" /> is small<br /> for all <Inline tex="n \geq N," /> then
                </div>
                <div id="rmm-2024-4-block">
                    <Block tex="\textcolor{BrickRed}a \mid \textcolor{Emerald}b." />
                </div>
            </div >,
            title: "RMM 2024/4",
            fileName: "RMM2024-4.pdf",
            contest: ContestData.RMM,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2024, 6, 7, 13, 0),
            youtubeVideos: [{
                title: "Only the best IMO participants are invited to this competition",
                url: "https://youtu.be/2afMLcv6ZUA"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    KRAFT_MC_MILLAN: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 117,
            problemStatement: (
                <>
                    Let <Inline tex="F" /> be a finite collection of finite binary strings such that for <Inline tex="b_1, \cdot, b_m, c_1, \cdot c_n \in F" /> where <Inline tex="(b_1, \dots, b_m) \neq (c_1, \dots, c_n)," /> the concatenations <Inline tex="(b_1 \mid \dots \mid b_m)" /> and <Inline tex="(c_1 \mid \dots \mid c_n)" /> are distinct. Let <Inline tex="N_i" /> denote the number of strings of length <Inline tex="i" /> in <Inline tex="F." /> Prove that
                    <Block tex="\sum_{i = 0}^\infty \frac{N_i}{2 ^ i} \leq 1." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
            </div >,
            title: "Kraft-McMillan Inequality",
            fileName: "Kraft-McMillan.pdf",
            contest: ContestData.THEOREM,
            year: YearData(2024),
            branch: BranchData.C,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.GEOMETRIC_DISTRIBUTION, WikiData.CONDITIONAL_PROBABILITY],
            infoTags: [InfoTagData.PROBABILITIES],
            problemCode: [],
            releaseTime: undefined,
            youtubeVideos: [],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BMO_2024_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 118,
            problemStatement: (
                <>
                    Let <Inline tex="a" /> and <Inline tex=" b" /> be distinct positive integers such that <Inline tex="3^a + 2" /> is divisible by <Inline tex="3^b + 2" /> Prove that <Inline tex="a > b^2." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
            </div >,
            title: "BMO 2024/3",
            fileName: "BMO2024-3.pdf",
            contest: ContestData.BALKAN_MO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2024, 5, 31, 13, 0),
            youtubeVideos: [{
                title: "These numbers rarely divide each other",
                url: "https://youtu.be/5-GG2ai1FNc"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    MALAYSIA_APMO_SELECTION_2024_3: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 119,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb{Z}\rightarrow \mathbb{Z}" /> such that for all integers <Inline tex=" x," /> <Inline tex="y," />
                    <Block tex="f(x-f(y))=f(f(y))+f(x-2y)." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="usa-tst-2024-6-info">
                    <q>
                        How to argue with injectivity in a functional equation
                    </q>
                </div>
                <div id="malaysia-apmo-selection-2024-3-tn">
                    Find all functions <Inline tex="\textcolor{orange}f:\mathbb{Z}\rightarrow \mathbb{Z}" /> such that
                    <Block tex="\textcolor{orange}f(x-\textcolor{orange}f(y))=\textcolor{orange}f(\textcolor{orange}f(y))+\textcolor{orange}f(x-2y)." />
                </div>
            </div >,
            title: "Malaysia APMO Selection 2024/3",
            thumbnailTitle: "Malaysia APMO TST 2024/3",
            fileName: "MalaysiaAPMOSelection2024-3.pdf",
            contest: ContestData.MALAYSIA_APMO_TST,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 6, 14, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/FEmh7nBaXLs",
                title: "You can't guess the solutions to this functional equation"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    TAIWAN_MO_2023_5: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 120,
            problemStatement: (
                <>
                    Let <Inline tex="m" /> be a positive integer, and real numbers <Inline tex="a_1, a_2,\ldots , a_m" /> satisfy
                    <Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i = 1," />
                    <Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i ^2= 11," />
                    <Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i ^3= 1," />
                    <Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i ^4= 131." />
                    Prove that <Inline tex="m" /> is a multiple of <Inline tex="7." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="taiwan-mo-2023-5">
                <div id="block1"><Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i = 1," /></div>
                <div id="block2"><Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i ^2= 11," /></div>
                <div id="block3"><Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i ^3= 1," /></div>
                <div id="block4"><Block tex="\frac{1}{m}\sum_{i = 1}^{m}a_i ^4= 131" /></div>
                <div id="block5"><Block tex="\implies 7 \mid m." /></div>
            </div >,
            title: "Taiwan MO 2023/5",
            fileName: "TaiwanMO2023-5.pdf",
            contest: ContestData.TAIWAN_MO,
            year: YearData(2023),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.PROBABILITIES, InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2024, 12, 20, 13, 0),
            youtubeVideos: [{
                title: "Using probability theory to interpret these equations",
                url: "https://youtu.be/6dsV22rOXU8"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
            videoStatement: <div id="taiwan-mo-2023-5-vt">
                Let <Inline tex="\textcolor{orange}m \in \mathbb Z^+" />, and <Inline tex="a_1, a_2,\ldots , a_m \in \mathbb R" /> with <Inline tex="\frac{1}{\textcolor{orange}m}\sum_{i = 1}^{\textcolor{orange}m}a_i = 1, \frac{1}{\textcolor{orange}m}\sum_{i = 1}^{\textcolor{orange}m}a_i ^2= 11," /><br />
                <Inline tex="\frac{1}{\textcolor{orange}m}\sum_{i = 1}^{\textcolor{orange}m}a_i ^3= 1, \frac{1}{\textcolor{orange}m}\sum_{i = 1}^{\textcolor{orange}m}a_i ^4= 131." /> Prove that <Inline tex="\textcolor{orange}m" /> is a multiple of <Inline tex="7." />
            </div>
        };
    },
    USAMO_1982_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 121,
            problemStatement: (
                <>
                    Prove that there exists a positive integer <Inline tex="k" /> such that <Inline tex="k \cdot 2^n+1" /> is composite for every integer <Inline tex="n \geq 0." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="usamo-1982-4-div">
                    There exists a <Inline tex="\textcolor{#189AB4} k \in \mathbb Z^+" /> such that
                    <div id="usamo-1982-4-block">
                        <Block tex="\textcolor{#189AB4}k \cdot 2^n+1" />
                    </div>
                    is never prime.
                </div>
            </div >,
            title: "USAMO 1982/4",
            fileName: "USAMO1982-4.pdf",
            contest: ContestData.USAMO,
            year: YearData(1982),
            branch: BranchData.N,
            difficulty: DifficultyData(6),
            wikiCitations: [WikiData.MULTIPLICATIVE_ORDER],
            infoTags: [InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2024, 10, 4, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/fdVjrj2USAA",
                title: "FERMAT wasn't able to solve this problem"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    BWM_2020_2_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 122,
            problemStatement: (
                <>
                    Let <Inline tex="A" /> be a table with <Inline tex="m" /> rows and <Inline tex="n" /> columns and entries in <Inline tex="\mathbb R_{\geq 0}" /> with <Inline tex="m < n." /> Any column contains a positive entry. Prove that there exists a positive entry such that the sum of entries of its row is greater than the sum of entries of its column.
                </>
            ),
            thumbnailTitle: "",
            thumbnailStatement: <div className="thumbnail-text-div" id="bwm-2020-2-4">
                <div id="title">
                    The final problem on the<br />
                    German National Math Competition
                </div>
                <div id="bwm-2020-2-4-img-div">
                    <img
                        src={require("../img/thumbnails/BWM2020-2-4-TN.png")}
                        id="bwm-2020-2-4-img"
                    />
                </div>
                <div id="bwm-2020-2-4-text-div">
                    There exists a positive entry with greater row-sum than column-sum.
                </div>
            </div >,
            title: "BWM 2020/2.4",
            fileName: "BWM2020-2-4.pdf",
            contest: ContestData.BWM,
            year: YearData(2020),
            branch: BranchData.A,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.MATCHING, WikiData.HALLS_MARIAGE_THEOREM],
            infoTags: [InfoTagData.INEQUALITY],
            problemCode: [],
            releaseTime: toDate(2024, 9, 13, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/lG7W7i4QEeE",
                title: "This is a solution using a result from graph theory"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    IMO_2024_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 123,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a triangle with <Inline tex=" AB < AC < BC." /> Let the incenter and incircle of triangle <Inline tex=" ABC" /> be <Inline tex=" I" /> and <Inline tex="\omega," /> respectively. Let <Inline tex=" X" /> be the point on line <Inline tex="BC" /> different from <Inline tex=" C" /> such that the line through <Inline tex="X" /> parallel to <Inline tex=" AC" /> is tangent to <Inline tex="\omega." /> Similarly, let <Inline tex=" Y" /> be the point on line <Inline tex="BC" /> different from <Inline tex=" B" /> such that the line through <Inline tex="Y" /> parallel to <Inline tex=" AB" /> is tangent to <Inline tex="\omega." /> Let <Inline tex=" AI" /> intersect the circumcircle of triangle <Inline tex="ABC" /> at <Inline tex=" P \ne A." /> Let <Inline tex=" K" /> and <Inline tex=" L" /> be the midpoints of <Inline tex="AC" /> and <Inline tex=" AB," /> respectively. <br />
                    Prove that <Inline tex="\angle KIL + \angle YPX = 180^{\circ}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div-dark">
                <div id="imo-2024-4-div1">
                    Prove that
                    <Block tex="\textcolor{#D3FBD8}{\angle KIL} + \textcolor{#D77F65}{\angle YPX} = 180^\circ." />
                </div>
                <div id="imo-2024-4-div2">
                    <img
                        src={require("../img/thumbnails/IMO2024-4-TN.png")}
                        id="imo-2024-2"
                    />
                </div>
                <div className="imo-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-logo.png")}
                        className="imo-logo"
                    />
                </div>
                <div className="imo-2024-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-2024-logo.png")}
                        className="imo-2024-logo"
                    />
                </div>
                <div className="imo-2024-uk-div">
                    <img
                        src={require("../img/thumbnails/uk-flag.png")}
                        className="imo-2023-japan"
                    />
                </div>
            </div >,
            title: "IMO 2024/4",
            fileName: "IMO2024-4.pdf",
            contest: ContestData.IMO,
            year: YearData(2024),
            branch: BranchData.G,
            difficulty: DifficultyData(7),
            wikiCitations: [WikiData.POP],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2024, 8, 30, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/DNZw9wMdYH4",
                title: "INTERNATIONAL MATH OLYMPIAD 2024/4: The power of reflection"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    EGMO_2023_6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 124,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a triangle with circumcircle <Inline tex="\Omega." /> Let <Inline tex=" S_b" /> and <Inline tex=" S_c" /> respectively denote the midpoints of the arcs <Inline tex="AC" /> and <Inline tex=" AB" /> that do not contain the third vertex. Let <Inline tex="N_a" /> denote the midpoint of arc <Inline tex=" BAC" /> (the arc <Inline tex="BC" /> including <Inline tex=" A" />). Let <Inline tex=" I" /> be the incenter of <Inline tex=" ABC." /> Let <Inline tex="\omega_b" /> be the circle that is tangent to <Inline tex=" AB" /> and internally tangent to <Inline tex="\Omega" /> at <Inline tex=" S_b," /> and let <Inline tex="\omega_c" /> be the circle that is tangent to <Inline tex=" AC" /> and internally tangent to <Inline tex="\Omega" /> at <Inline tex=" S_c." /> Show that the line <Inline tex=" IN_a," /> and the lines through the intersections of <Inline tex="\omega_b" /> and <Inline tex="\omega_c," /> meet on <Inline tex="\Omega." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div-dark">
                <div id="egmo-2023-6-div1">
                    Prove that <Inline tex="X" /> lies on the <span id="egmo-2023-6-colored">radical axis</span> of <Inline tex="\omega_B" /> and <Inline tex="\omega_C." />
                </div>
                <div id="egmo-2023-6-div2">
                    <img
                        src={require("../img/thumbnails/EGMO2023-6-TN.png")}
                        className="egmo-2023-6-img"
                    />
                </div>
            </div >,
            title: "EGMO 2023/6",
            fileName: "EGMO2023-6.pdf",
            contest: ContestData.EGMO,
            year: YearData(2023),
            branch: BranchData.G,
            difficulty: DifficultyData(9),
            wikiCitations: [WikiData.CROSS_RATIO, WikiData.POP, WikiData.HARMONIC_POINTS, WikiData.CROSS_RATIO],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY, InfoTagData.PROJECTIVE_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2024, 10, 18, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/SfpkS9Ifv_M",
                title: "All properties of radical axes in one problem"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    INMO_2024_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 125,
            problemStatement: (
                <>
                    A finite set <Inline tex="\mathcal{S}" /> of positive integers is called cardinal if <Inline tex="\mathcal{S}" /> contains the integer <Inline tex="|\mathcal{S}|" /> where <Inline tex="|\mathcal{S}|" /> denotes the number of distinct elements in <Inline tex="\mathcal{S}." /> Let <Inline tex=" f" /> be a function from the set of positive integers to itself such that for any cardinal set <Inline tex="\mathcal{S}," /> the set <Inline tex=" f(\mathcal{S})" /> is also cardinal. Here <Inline tex="f(\mathcal{S})" /> denotes the set of all integers that can be expressed as <Inline tex=" f(a)" /> where <Inline tex="a \in \mathcal{S}." /> Find all possible values of <Inline tex=" f(2024)." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div>
                    <Block tex="\bbox[lightgreen, 2pt, border: 1px solid green]{\text{$\mathcal S \subseteq \mathbb Z^+" /> is <Inline tex="\textcolor{#EF7C8E}{\text{cardinal}}" /> if <Inline tex="|\mathcal S| \in \mathcal S$}}" />
                </div>
                <div id="inmo-2024-4-div2">
                    What functions <Inline tex="\textcolor{#EF7C8E}f : \mathbb Z^+ \to \mathbb Z^+" /> map cardinal sets to cardinal sets?
                </div>
            </div >,
            title: "INMO 2024/4",
            fileName: "INMO2024-4.pdf",
            contest: ContestData.INMO,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 11, 22, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/4ZRngVZY7B8",
                title: "This function preserves a beatiful property of sets"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    ISL_2023_A4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 126,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb R_{> 0}" /> be the set of positive real numbers. Determine all functions <Inline tex=" f \colon \mathbb R_{> 0} \to \mathbb R_{> 0}" /> such that
                    <Block tex="x \cdot \left(f(x) + f(y)\right) \geq \left(f(f(x)) + y\right) \cdot f(y)" />
                    for every <Inline tex="x, y \in \mathbb R_{> 0}." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                Find all functions <Inline tex="f : \mathbb R_{> 0} \to \mathbb R_{> 0}" /> satisfying
                <div id="isl-2023-a4-block-1">
                    <Block tex="\textcolor{#189AB4}{x \cdot \left(f(x) + f(y)\right)}" />
                </div>
                <div id="isl-2023-a4-block-2">
                    <Block tex="\textcolor{#EF7C8E}\geq" />
                </div>
                <div id="isl-2023-a4-block-3">
                    <Block tex="\textcolor{#189AB4}{\left(f(f(x)) + y\right) \cdot f(y).}" />
                </div>
            </div >,
            title: "ISL 2023/A4",
            fileName: "ISL2023-A4.pdf",
            contest: ContestData.ISL,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 10, 11, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/aDr1Ai0uwgw",
                title: "Mastering Functional Equations: Key tools for positive numbers"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2024_1: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 127,
            problemStatement: (
                <>
                    Determine all real numbers <Inline tex="\alpha" /> such that, for every positive integer <Inline tex=" n," /> the integer
                    <Block tex="\lfloor\alpha\rfloor +\lfloor 2\alpha\rfloor +\cdots +\lfloor n\alpha\rfloor" /> is a multiple of <Inline tex=" n." /> (Note that <Inline tex="\lfloor z\rfloor" /> denotes the greatest integer less than or equal to <Inline tex=" z." /> For example, <Inline tex="\lfloor -\pi\rfloor =-4" /> and <Inline tex="\lfloor 2\rfloor= \lfloor 2.9\rfloor =2." />)
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="imo-2024-1-div">
                    For which <Inline tex="\alpha \in \mathbb R" /> is
                    <div id="imo-2024-1-block">
                        <Block tex="\lfloor\alpha\rfloor +\lfloor 2\alpha\rfloor +\cdots +\lfloor n\alpha\rfloor" />
                    </div>
                    a multiple of <Inline tex=" n?" />
                </div>
                <div className="imo-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-logo.png")}
                        className="imo-logo"
                    />
                </div>
                <div className="imo-2024-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-2024-logo.png")}
                        className="imo-2024-logo"
                    />
                </div>
                <div className="imo-2023-japan-div">
                    <img
                        src={require("../img/thumbnails/uk-flag.png")}
                        className="imo-2023-japan"
                    />
                </div>
            </div >,
            title: "IMO 2024/1",
            fileName: "IMO2024-1.pdf",
            contest: ContestData.IMO,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(5),
            wikiCitations: [],
            infoTags: [],
            problemCode: [],
            releaseTime: toDate(2024, 8, 23, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/ewvE5_kNw1I",
                title: "You can probably guess the solution to this INTERNATIONAL MATH OLYMPIAD 2024 problem"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    IMO_2024_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 128,
            problemStatement: (
                <>
                    Determine all pairs <Inline tex="(a,b)" /> of positive integers for which there exist positive integers <Inline tex=" g" /> and <Inline tex="N" /> such that
                    <Block tex="\gcd (a^n+b,b^n+a)=g" /> holds for all integers <Inline tex=" n\geqslant N." /> (Note that <Inline tex="\gcd(x, y)" /> denotes the greatest common divisor of integers <Inline tex=" x" /> and <Inline tex="y." />)
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="imo-2024-2-div">
                    For which numbers <Inline tex="a, b" /> is
                    <div id="imo-2024-2-block">
                        <Block tex="\gcd (a^n+b,b^n+a)" />
                    </div>
                    eventually constant?
                </div>
                <div className="imo-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-logo.png")}
                        className="imo-logo"
                    />
                </div>
                <div className="imo-2024-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-2024-logo.png")}
                        className="imo-2024-logo"
                    />
                </div>
                <div className="imo-2023-japan-div">
                    <img
                        src={require("../img/thumbnails/uk-flag.png")}
                        className="imo-2023-japan"
                    />
                </div>
            </div >,
            title: "IMO 2024/2",
            fileName: "IMO2024-2.pdf",
            contest: ContestData.IMO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(8),
            wikiCitations: [WikiData.EULERS_TOTIENT_FUNCTION],
            infoTags: [InfoTagData.DIVISIBILITY],
            problemCode: [],
            releaseTime: toDate(2024, 9, 6, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/daboPS8Dtyk",
                title: "The fastest solution to a problem 2 in INTERNATIONAL MATH OLYMPIAD"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
        };
    },
    PROBABILITY_INTRO1: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 129,
            problemStatement: undefined,
            thumbnailTitle: "",
            thumbnailStatement: <div className="thumbnail-text-div" id="probability-intro">
                <div id="title-div">
                    <div id="title">
                        Probability theory
                    </div>
                    <div id="subtitle">
                        for Math Olympians
                    </div>
                </div>
                <div id="example-isl">
                    <Inline tex="a_1 + a_2 + \dots + a_n = 1" />
                    <Block tex="\sum_{k=1}^n \frac{a_k}{1-a_k}(a_1 + a_2 + \dots + a_{k-1})^2 < \frac{1}{3}" />
                    <div id="implication">
                        <Inline tex="\implies" />
                    </div>
                </div>
                <div id="example-imo">
                    <img
                        src={require("../img/thumbnails/IMO2023-5-3.png")}
                        id="example-imo-img"
                    />
                </div>
                <div id="example-linearity">
                    <Block tex="\mathbb E[X_1 + \dots + X_n] = \mathbb E[X_1] + \dots + \mathbb E[X_n]" /><p />
                </div>
                <div id="example-markov">
                    <Block tex="\mathbb P\left[X \geq \lambda \right] \leq \frac{\mathbb E[X]}{\lambda}" />
                </div>
                <div id="example-chebyshev">
                    <Block tex="\mathbb P\left[X \geq \mathbb E[X] + \lambda\right] \leq \frac{\mathrm{Var}[X]}{\mathrm{Var}[X] + \lambda^2}" />
                </div>
            </div >,
            title: "Probability theory",
            fileName: "probability-theory",
            contest: ContestData.IMO,
            year: YearData(2024),
            branch: BranchData.C,
            difficulty: DifficultyData(6),
            wikiCitations: [],
            infoTags: [InfoTagData.PROBABILITIES],
            problemCode: [],
            releaseTime: toDate(2024, 11, 1, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/Iq-_snOD_uU",
                title: "Foundations of Probability Theory: Building a Rigorous Understanding of probability"
            }, {
                url: "https://youtu.be/uInTzLf-4KY",
                title: "Foundations of Probability Theory: Variance"
            }],
            buttonType: ProblemButtonData.SKILLPAGE_BUTTON,
            darkmode: true,
            showDifficulty: false,
        };
    },
    IMO_2024_6: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 130,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb{Q}" /> be the set of rational numbers. A function <Inline tex="f: \mathbb{Q} \to \mathbb{Q}" /> is called aquaesulian if the following property holds: for every <Inline tex="x,y \in \mathbb{Q}," />
                    <Block tex=" f(x+f(y)) = f(x) + y \quad \text{or} \quad f(f(x)+y) = x + f(y)." />
                    Show that there exists an integer <Inline tex="c" /> such that for any aquaesulian function <Inline tex="f" /> there are at most <Inline tex="c" /> different rational numbers of the form <Inline tex="f(r) + f(-r)" /> for some rational number <Inline tex="r," /> and find the smallest possible value of <Inline tex="c." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="imo-2024-6">
                <div id="text">
                    For all <Inline tex="\textcolor{#189AB4}x, \textcolor{#189AB4}y \in \mathbb Q," />
                </div>
                <div id="block">
                    <Block tex=" \textcolor{#EF7C8E}f(\textcolor{#189AB4}x+\textcolor{#EF7C8E}f(\textcolor{#189AB4}y)) = \textcolor{#EF7C8E}f(\textcolor{#189AB4}x) + \textcolor{#189AB4}y " />
                </div>
                <div id="text">
                    or
                </div>
                <div id="block">
                    <Block tex="\textcolor{#EF7C8E}f(\textcolor{#EF7C8E}f(\textcolor{#189AB4}x)+\textcolor{#189AB4}y) = \textcolor{#189AB4}x + \textcolor{#EF7C8E}f(\textcolor{#189AB4}y)." />
                </div>
                <div className="imo-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-logo.png")}
                        className="imo-logo"
                    />
                </div>
                <div className="imo-2024-logo-div">
                    <img
                        src={require("../img/thumbnails/imo-2024-logo.png")}
                        className="imo-2024-logo"
                    />
                </div>
                <div className="imo-2023-japan-div">
                    <img
                        src={require("../img/thumbnails/uk-flag.png")}
                        className="imo-2023-japan"
                    />
                </div>
            </div >,
            title: "IMO 2024/6",
            thumbnailWhite: true,
            fileName: "IMO2024-6.pdf",
            contest: ContestData.IMO,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(11),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 9, 20, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/ONiOc1w4LGg",
                title: "The hardest problem in this year's INTERNATIONAL MATH OLYMPIAD was a functional equation!"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    ISL_2023_N8: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 131,
            problemStatement: (
                <>
                    Determine all functions <Inline tex="f\colon\mathbb{Z}_{> 0}\to\mathbb{Z}_{> 0}" /> such that, for all positive integers <Inline tex="a" /> and <Inline tex="b," />
                    <Block tex="
                    f^{bf(a)}(a+1)=(a+1)f(b).
                   " />

                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="isl-2023-n8">
                <div id="text">
                    Find all <Inline tex="\textcolor{#EF7C8E}f : \mathbb Z_{>0} \to \mathbb Z_{>0}" /> such that
                </div>
                <div id="block">
                    <Block tex="
                    \textcolor{#EF7C8E}f^{b\textcolor{#EF7C8E}f(a)}(a+1)=(a+1)\textcolor{#EF7C8E}f(b).
                   " />
                </div>
            </div >,
            title: "ISL 2023/N8",
            fileName: "ISL2023-N8.pdf",
            contest: ContestData.ISL,
            year: YearData(2023),
            branch: BranchData.N,
            difficulty: DifficultyData(9),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: toDate(2024, 10, 25, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/Bl0ycdJLoT0",
                title: "Power towers in a functional equation?!"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    BMO_2024_4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 132,
            problemStatement: (
                <>
                    Let <Inline tex="\mathbb{R}^+ = (0, \infty)" /> be the set of all positive real numbers. Find all functions <Inline tex="f : \mathbb{R}^+ \to \mathbb{R}^+" /> and polynomials <Inline tex="P(x)" /> with non-negative real coefficients such that <Inline tex="P(0) = 0" /> which satisfy the equality <Inline tex="f(f(x) + P(y)) = f(x - y) + 2y" /> for all real numbers <Inline tex="x > y > 0." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="bmo-2024-4">
                <div id="title">
                    Balkan MO 2024 / 4
                </div>
                <div id="text">
                    Find all <Inline tex="\textcolor{#189AB4}f : \mathbb{R}^+ \to \mathbb{R}^+" /> and polynomials <Inline tex="\textcolor{#EF7C8E}P(x) = \sum_{i=1}^k a_i x^i" /> with <Inline tex="a_i \geq 0" /> such that
                </div>
                <div id="block">
                    <Block tex="\textcolor{#189AB4}f(\textcolor{#189AB4}f(x) + \textcolor{#EF7C8E}P(y)) = \textcolor{#189AB4}f(x - y) + 2y." />
                </div>
            </div >,
            title: "BMO 2024/4",
            thumbnailTitle: "",
            fileName: "BMO2024-4.pdf",
            contest: ContestData.BALKAN_MO,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(1),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: undefined,
            youtubeVideos: [],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    MEMO_2024_T6: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 133,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be an acute triangle. Let <Inline tex="M" /> be the midpoint of the segment <Inline tex="BC." /> Let <Inline tex="I, J, K" /> be the incenters of triangles <Inline tex="ABC," /> <Inline tex="ABM," /> <Inline tex="ACM," /> respectively. Let <Inline tex="P, Q" /> be points on the lines <Inline tex="MK," /> <Inline tex="MJ," /> respectively, such that <Inline tex="\angle AJP=\angle ABC" /> and <Inline tex="\angle AKQ=\angle BCA." /> Let <Inline tex="R" /> be the intersection of the lines <Inline tex="CP" /> and <Inline tex="BQ." /> Prove that the lines <Inline tex="IR" /> and <Inline tex="BC" /> are perpendicular.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="memo-2024-t6-img-div">
                    <img
                        src={require("../img/thumbnails/MEMO2024-T6-TN.png")}
                        id="usamo2008-6-img"
                    />
                </div>
                <div id="memo-2024-t6-div">
                    Prove that
                    <span className="block">
                        <Block tex="IR \perp BC." />
                    </span>
                </div>
            </div >,
            title: "MEMO 2024/T6",
            fileName: "MEMO2024-T6.pdf",
            contest: ContestData.MEMO,
            year: YearData(2024),
            branch: BranchData.G,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2024, 11, 29, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/vHCURoJoTHU",
                title: "This reflection trick is insane"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    MEMO_2024_T4: () => {
        return {
            type: "pdf",
            author: "Richard",
            id: 134,
            problemStatement: (
                <>
                    A finite sequence <Inline tex="x_1,\dots,x_r" /> of positive integers is a palindrome if <Inline tex="x_i=x_{r + 1 - i}" /> for all integers
                    <Inline tex="1 \le i \le r." />
                    Let <Inline tex="a_1,a_2,\dots" /> be an infinite sequence of positive integers. For a positive integer <Inline tex="j \ge 2," /> denote by
                    <Inline tex="a[j]" /> the finite subsequence <Inline tex="a_1,a_2,\dots,a_{j - 1}." /> Suppose that there exists a strictly increasing infinite
                    sequence <Inline tex="b_1,b_2,\dots" /> of positive integers such that for every positive integer <Inline tex="n," /> the subsequence
                    <Inline tex="a[b_n]" /> is a palindrome and <Inline tex="b_{n + 2} \le b_{n + 1}+b_n." /> Prove that there exists a positive integer <Inline tex="T" /> such
                    that <Inline tex="a_i=a_{i + T}" /> for every positive integer <Inline tex="i." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="memo-2024-t4">
                If <Inline tex="\textcolor{#189AB4}b_{i+1} < \textcolor{#189AB4}b_{i+2} \leq \textcolor{#189AB4}b_i + \textcolor{#189AB4}b_{i+1}" /> and the prefixes
                <div id="memo-2024-t4-block">
                    <Block tex="(\textcolor{#EF7C8E}a_1, \textcolor{#EF7C8E}a_2, \dots, \textcolor{#EF7C8E}a_{\textcolor{#189AB4}b_i-1})" />
                </div>
                are palindromes, then <Inline tex="\textcolor{#EF7C8E}a_1, \textcolor{#EF7C8E}a_2, \dots" /> must be periodic.
            </div >,
            title: "MEMO 2024/T4",
            fileName: "MEMO2024-T4.pdf",
            contest: ContestData.MEMO,
            year: YearData(2024),
            branch: BranchData.C,
            difficulty: DifficultyData(8),
            wikiCitations: [],
            infoTags: [InfoTagData.SEQUENCE],
            problemCode: [],
            releaseTime: undefined,
            youtubeVideos: [],
            buttonType: ProblemButtonData.NO_BUTTON,
            darkmode: true,
            videoStatement: <div id="memo-2024-t4-vt">
                Let <Inline tex="a_1,a_2,\dots, b_1, b_2, \dots \in \mathbb Z^+" />. Suppose <Inline tex="b_1, b_2, \dots" /> is strictly increasing and for <Inline tex="i \geq 1," /> we have <Inline tex="b_{i+2} \leq b_i + b_{i+1}" /> and <Inline tex="a[b_i]" /> is a palindrome.
                Prove that <Inline tex="a_1,a_2,\dots" /> is periodic.
            </div>
        };
    },
    MEMO_2024_T5: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 135,
            problemStatement: (
                <>
                    Let <Inline tex="ABC" /> be a triangle with <Inline tex="\angle BAC=60^\circ." /> Let <Inline tex="D" /> be a point on the line <Inline tex="AC" /> such that <Inline tex="AB = AD" /> and <Inline tex="A" /> lies between <Inline tex="C" /> and <Inline tex="D." /> Suppose that there are two points <Inline tex="E \ne F" /> on the circumcircle of the triangle <Inline tex="DBC" /> such that <Inline tex="AE = AF = BC." /> Prove that the line <Inline tex="EF" /> passes through the circumcenter of <Inline tex="ABC." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div">
                <div id="memo-2024-t5-img-div">
                    <img
                        src={require("../img/thumbnails/MEMO2024-T5-TN.png")}
                        id="usamo2008-6-img"
                    />
                </div>
                <div id="memo-2024-t5-div">
                    Prove that
                    <Block tex="U \in EF." />
                </div>
            </div >,
            title: "MEMO 2024/T5",
            fileName: "MEMO2024-T5.pdf",
            contest: ContestData.MEMO,
            year: YearData(2024),
            branch: BranchData.G,
            difficulty: DifficultyData(6),
            wikiCitations: [WikiData.INSCRIBED_ANGLE_THEOREM],
            infoTags: [InfoTagData.CLASSIC_GEOMETRY],
            problemCode: [],
            releaseTime: toDate(2024, 11, 15, 13, 0),
            youtubeVideos: [{
                title: "Why almost everything holds for 60-degree triangles",
                url: "https://youtu.be/boQGJcd9tQo"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    MEMO_2024_T8: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 136,
            problemStatement: (
                <>
                    Let <Inline tex="k" /> be a positive integer and <Inline tex="a_1,a_2,\dots" /> be an infinite sequence of positive integers such that
                    <Block tex="a_ia_{i + 1} \mid k-a_i^2" />
                    for all integers <Inline tex="i \ge 1." /> Prove that there exists a positive integer <Inline tex="M" /> such that <Inline tex="a_n=a_{n + 1}" /> for all
                    integers <Inline tex="n \ge M." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="memo-2024-t8">
                If a sequence <Inline tex="a_1, a_2, \dots" /> satisfies
                <div id="block">
                    <Block tex="a_ia_{i + 1} \textcolor{orange}\mid k-a_i^2," />
                </div>
                it is eventually constant.
            </div >,
            title: "MEMO 2024/T8",
            fileName: "MEMO2024-T8.pdf",
            contest: ContestData.MEMO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2024, 12, 6, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/IPdIoYsGRL4",
                title: "The ultimate guide for tackling divisibility conditions"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
    USEMO2024_2: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 137,
            problemStatement: (
                <>
                    Let <Inline tex="k" /> be a fixed positive integer. For each integer <Inline tex="1 \leq i \leq 4," /> let <Inline tex="x_i" /> and <Inline tex="y_i" /> be positive integers such that their least common multiple is <Inline tex="k." /> Suppose that the four points <Inline tex="(x_1, y_1)," /> <Inline tex="(x_2, y_2)," /> <Inline tex="(x_3, y_3)," /> <Inline tex="(x_4, y_4)" /> are the vertices of a non-degenerate rectangle in the Cartesian plane. Prove that <Inline tex="x_1x_2x_3x_4" /> is a perfect square.
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="usemo-2024-2">
                If <Inline tex="(\textcolor{#EF7C8E}x_i, \textcolor{#189AB4}y_i)_{i=1,2,3,4}" /> are the vertices of a rectangle and <Inline tex="\mathrm{lcm}(\textcolor{#EF7C8E}x_i, \textcolor{#189AB4}y_i)" /> is constant, then <Inline tex="\prod_{i=1}^4 \textcolor{#EF7C8E}x_i" /> is a square.
            </div >,
            title: "USEMO 2024/2",
            fileName: "USEMO2024-2.pdf",
            contest: ContestData.USEMO,
            year: YearData(2024),
            branch: BranchData.N,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.DIVISIBILITY, InfoTagData.PRIME_FACTORS],
            problemCode: [],
            releaseTime: toDate(2024, 12, 13, 13, 0),
            youtubeVideos: [{
                url: "https://youtu.be/RqmFjL30jyE",
                title: "The coordinates of this rectangle form a square"
            }],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
            videoStatement: <div id="taiwan-mo-2023-5-vt">
                <Inline tex="k \in \mathbb N^+, x_i, y_i \in \mathbb N^+" /> for <Inline tex="1 \leq i \leq 4" /> with <Inline tex="\mathrm{lcm}(x_i, y_i) = k" />. If <Inline tex="(x_1, y_1)" />, <Inline tex="(x_2, y_2)" />, <Inline tex="(x_3, y_3)" />, <Inline tex="(x_4, y_4)" /> form a non-degenerate rectangle, then <Inline tex="x_1x_2x_3x_4" /> is a perfect square.
            </div>
        };
    },
    HONGKONG_TST_2024_2_4: () => {
        return {
            type: "pdf",
            author: "Timo",
            id: 138,
            problemStatement: (
                <>
                    Find all functions <Inline tex="f:\mathbb{R}\rightarrow\mathbb{R}" /> satisfying the following condition: for any real numbers <Inline tex="x" /> and <Inline tex="y," /> the number <Inline tex="f(x + f(y))" /> is equal to <Inline tex="x + f(y)" /> or <Inline tex="f(f(x)) + y." />
                </>
            ),
            thumbnailStatement: <div className="thumbnail-text-div" id="hk-tst-2024-2-4">
                Find all functions <Inline tex="\textcolor{#EF7C8E}f : \mathbb R \to \mathbb R" /> with
                <div id="block1">
                    <Block tex="\textcolor{#EF7C8E}f(x + \textcolor{#EF7C8E}f(y))" />
                </div>
                <div id="block2">
                    <Block tex="\in" />
                </div>
                <div id="block3">
                    <Block tex="\big\{x + \textcolor{#EF7C8E}f(y), \textcolor{#EF7C8E}f(\textcolor{#EF7C8E}f(x)) + y\big\}." />
                </div>
            </div >,
            title: "Hong Kong TST 2024/2.4",
            fileName: "HongKongTST2024-2.4.pdf",
            contest: ContestData.HONGKONG_TST,
            year: YearData(2024),
            branch: BranchData.A,
            difficulty: DifficultyData(7),
            wikiCitations: [],
            infoTags: [InfoTagData.FUNCTIONAL_EQUATION],
            problemCode: [],
            releaseTime: undefined,
            youtubeVideos: [],
            buttonType: ProblemButtonData.PDF_BUTTON,
            darkmode: true,
        };
    },
};

export default PdfData;